import React from 'react'
import app from './appStore'
import login from './loginStore'
import tool from './toolStore'

class RootStore {
  appStore
  loginStore
  toolStore
  constructor() {
    this.appStore = app
    this.loginStore = login
    this.toolStore = tool
  }
}

const rootStore = new RootStore()
const context = React.createContext(rootStore)

const useStore = () => React.useContext(context)
const loginStore = () => React.useContext(context)
const toolStore = () => React.useContext(context)

export { useStore, loginStore, toolStore }