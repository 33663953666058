import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Drawer, IconButton, Grid2 as Grid, MenuItem, TextField, Slider, InputLabel, Select, List, ListItem, ListItemText, Button } from '@mui/material';

const AudioPlayer = ({ fileUrl, index=null }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  const progressBarOuterRef = useRef(null); // 用于整个进度条的引用
  const progressBarInnerRef = useRef(null); // 用于已播放部分的引用

  // 播放和暂停音频
  const togglePlay = () => {
    console.log('play file url', fileUrl)
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // 更新进度条
  const updateProgress = () => {
    if (audioRef.current && progressBarInnerRef.current) {
      const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
      progressBarInnerRef.current.style.width = `${progress}%`;
    }
  };

  // 设置音频文件的持续时间
  useEffect(() => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  }, []);

  // 监听时间更新事件以更新进度条
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.ontimeupdate = updateProgress;
      return () => {
        audio.ontimeupdate = null;
      };
    }
  }, [isPlaying]);

  // 点击进度条跳转到对应位置
  const handleProgressBarClick = (e) => {
    console.log('audioRef.current.duration', audioRef.current.duration)
    if (audioRef.current && progressBarOuterRef.current) {
      const rect = progressBarOuterRef.current.getBoundingClientRect();
      const width = rect.width;
      const clickX = e.clientX - rect.left; // 点击位置相对于进度条的X坐标
      
      const seekTime = (clickX / width) * audioRef.current.duration;
      console.log('audioRef.current.duration', audioRef.current.duration)
      console.log('seekTime', seekTime)
      if (!isNaN(seekTime)) {
        audioRef.current.currentTime = seekTime;
        
        if (!isPlaying) {
          audioRef.current.play();
          setIsPlaying(true);
        }
      }
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <audio
            ref={audioRef}
            src={fileUrl}
            type="audio/mpeg"
            style={{ display: 'none' }}
        >
            Your browser does not support the audio element.
        </audio>
        {/* <Button onClick={togglePlay} sx={{ m: 1 }}>
            {isPlaying ? 'Pause' : 'Play'}
        </Button> */}

        {
          index === 1?
          <Box mt={1} mb={1} >
              <Typography fontWeight={600} fontFamily={'Poppins'}>
                  instrument
              </Typography>
          </Box>
          : index === 0?
          <Box mt={2} mb={1} >
              <Typography fontWeight={600} fontFamily={'Poppins'}>
                  vocal
              </Typography>
          </Box>
          :
          <></>
        }

        <Box  sx={{ position: 'relative', width: '100%', display: 'flex', alignItems: 'center', backgroundColor: '#ECECEC', paddingTop: '10px', paddingBottom: '8px', paddingLeft: '10px', borderRadius: '40px' }} >

            <Box onClick={togglePlay} ml={'2%'} mr={'5%'} sx={{cursor: 'pointer', top: '5px'}} >
                <img src={isPlaying ? '/PlayButton.png' : '/PauseButton.png'} alt="" style={{width: '2vw'}} />
            </Box>

            <Box
                ref={progressBarOuterRef} // 引用整个进度条
                sx={{
                height: '10px',
                width: 'calc(100% - 5.5vw)', // 减去按钮的宽度和一些间距
                bgcolor: '#ddd',
                my: 1,
                borderRadius: '5px',
                position: 'relative',
                cursor: 'pointer', // 进度条可点击
                // flexGrow: 1, // 让进度条填充剩余空间
                }}
                onClick={handleProgressBarClick} // 添加点击事件
            >
                <Box
                ref={progressBarInnerRef} // 引用已播放部分
                sx={{
                    height: '100%',
                    width: '0%',
                    bgcolor: '#57A3F3',
                    transition: 'width 0.5s',
                    position: 'absolute',
                    borderRadius: '5px',
                }}
                >
                    
                </Box>
            </Box>

        </Box>
    </div>
  );
};

export default AudioPlayer;