import PricingModal from "@/components/pricing-modal/PricingModal";
import { useStore } from "@/store";
import { Avatar, Button, Flex, Popover } from "antd"
import { observer } from "mobx-react-lite"
import Back from '@/assets/images/back.svg'
import CrownWhite from '@/assets/images/crown-white.svg'
import { useNavigate } from "react-router-dom"
import { LeftCircleFilled } from "@ant-design/icons"


// const navigate = useNavigate()

const UserPanel = (props: { step?: number }) => {
  const { toolStore, appStore } = useStore();
  const navigate = useNavigate()
  const logout = () => {
    localStorage.clear()
    appStore.isLoggedIn = false
  }
  const content = (
    <ul className="user-info">
      <li onClick={()=>{window.open('/toolkit/my-creations', '_blank')}}>My creations</li>
      <li onClick={()=>{window.open('/toolkit/my-workflows', '_blank')}}>My workflows</li>
      {/* <li>Account setting</li> */}
      <li onClick={()=>{logout(); location.reload()}}>Log out</li>
    </ul>
  );
  
  return (
    <>
      <div className="user-panel">
        <Flex justify="space-between" align="center">
        {/* <div className="left" onClick={() => {
        if (toolStore.curState === 1) {
          navigate('/')
        } else {
          toolStore.curState-=1
        }
      }}>
        <LeftCircleFilled  />
        <span className="name">Back</span>
      </div> */}
{/* 
          <Button className="btn-back" type="primary" shape="round" onClick={() => {
        if (toolStore.curState === 1) {
          navigate('/')
        } else {
          toolStore.curState-=1
        }
      }}>
             <span><img src={Back} height={'25px'}/></span>Back
          </Button> */}

          <Button className="btn-upgrade" type="primary" shape="round" onClick={() => toolStore.pricingModalVisible = true}>
            Upgrade <span><img src={CrownWhite} height={'25px'}/></span>
          </Button>
          {/* <div className="user-info">
            <span className="user-name"></span>
            {appStore.isLoggedIn?<Popover placement="left" content={content} trigger="click">
              <Avatar>U</Avatar>
            </Popover>:<></>}
          </div> */}
        </Flex>
        <Flex vertical={true} justify="center" align="flex-start">
          <span className="userguide">
            <span>UserGuide</span>
          </span>
          <div className="steps">
            <div className={`steps-item steps-1 ${ toolStore.curState! == 1 && 'active' }`}>
              <span className="step-num">1</span>
              <span className="desc">Upload Audio</span>
              <i className="line-point bottom"></i>
            </div>
            <div className={`steps-item steps-2 ${ toolStore.curState! == 2 && 'active' }`}>
              <span className="step-num">2</span>
              <span className="desc">Adjust</span>
              <i className="line-point top"></i>
              <i className="line-point bottom"></i>
            </div>
            <div className={`steps-item steps-3 ${ toolStore.curState! == 3 && 'active' }`}>
              <span className="step-num">3</span>
              <span className="desc">Output Audio</span>
              <i className="line-point top"></i>
            </div>
          </div>
        </Flex>
      </div>
      <PricingModal />
    </>
  )
}

export default observer(UserPanel)