import { Button, Modal, Popover, Spin } from "antd"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import shareImg from '@/assets/images/share.svg'
import deleteImg from '@/assets/images/delete.svg'
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons"
import './my-creations.scss'
import { getCreations } from "@/api/infer"
import axios, { AxiosError } from 'axios';
import { useStore } from '@/store';

const MyCreations = () => {
  const { appStore } = useStore();
  const [list, setList] = useState<any[]>([
    // {
    //   name: 'Song name',
    //   desc: 'from function name',
    //   status: 'playing'
    // },
    // {
    //   name: 'Song name',
    //   desc: 'from function name',
    //   status: 'paused'
    // },
    // {
    //   name: 'Song name',
    //   desc: 'from function name',
    //   status: 'paused'
    // },
    // {
    //   name: 'Song name',
    //   desc: 'from function name',
    //   status: 'paused'
    // }
  ])

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const loadWorkflows = async () => {
      setLoading(true);
      try {
        const data = await getCreations();
        console.log(data)
        setList(data);
      } catch (err) {
        const axiosError = err as AxiosError;
        console.log("err!!!")
        console.log(axiosError.status)
        if (axiosError.status === 422) {
          console.log('422 is true')
          appStore.signUpModalVisible = true
        }
      } finally {
        setLoading(false);
      }
    };
    loadWorkflows();
  }, []);

  const [showDelModal, setShowDelModal] = useState(false)

  const downloadContent = (
    <ul className="user-info">
      <li>Standard version</li>
      <li>HD version</li>
    </ul>
  );

  return (
    <>
      <div className="my-creations-wrapper">
        <div className="title">My Creations</div>
        {loading?
        <div style={{ display: 'flex', justifyContent: 'center', margin: '70px auto',}}>
          <Spin size="large"/>
        </div>
        :
        list.length !== 0?
        <ul className="play-list">
          {
            list.map(e => {
              return (
                <li>
                  <div className="icon">
                    {
                      e.status == 'paused' ? <PlayCircleOutlined /> : <PauseCircleOutlined />
                    }
                  </div>
                  <div className="left">
                    <span className="name">{ e.operation }</span>
                    <span className="desc">{ e.operate_time }</span>
                  </div>
                  <div className="right">
                    <Popover placement="left" content={downloadContent} trigger="click">
                      <Button className="btn-download" onClick={()=>{window.open(e.s3_file_path, '_blank')}}>Download</Button>
                    </Popover>
                    <div className="del" onClick={() => setShowDelModal(true)}>
                      <img src={deleteImg} />
                    </div>
                    <div className="share">
                      <img src={shareImg} />
                    </div>
                  </div>
                </li>
              )
            })
          }
        </ul>
        :
        <div>
          <div style={{ margin: '70px auto', fontSize: '30px', textAlign: 'center', color: '#3D3D3D' }}>You don't have a creation yet. Go <a href="/">create</a> one now.</div>
        </div>
        }
      </div>
      <Modal open={showDelModal} footer={null} closable={false} wrapClassName="fullscreen">
        <div className="del-confirm">
          <div className="title" style={{paddingTop: 0, paddingBottom: '42px'}}>
            Are you sure you want to delete this work?  This action cannot be undone.
          </div>
          <div className="footer">
            <div className="btn-confirm" onClick={() => setShowDelModal(false)}>
              <span>Confirm</span>
            </div>
            <div className="btn-cancel" onClick={() => setShowDelModal(false)}>
              <span>Cancel</span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default observer(MyCreations)