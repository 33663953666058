import { useCallback, useEffect, useState } from 'react';
import { Handle, Position, useNodes, useNodesState, useReactFlow, useOnSelectionChange } from '@xyflow/react';
import { Box, Typography, Menu, MenuItem, Divider, Popover, IconButton } from '@mui/material';
import { nodeNameList } from '../consts';
import { generateNewPosition } from '../utils';
import { v4 as uuid4 } from 'uuid';
import { GifBoxOutlined, MemoryRounded, MusicNoteRounded } from '@mui/icons-material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {runWorkflow} from '../../../utils/api'
import axios, { AxiosError } from 'axios';
import { useStore } from '@/store';
// import { typography } from '@chakra-ui/react';



const connectorCircleStyle = {
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  background: '#007bff',
};

const getInputNode = ({ nodeName, inputNames, outputNames, params }) => {
  const MusicLinkInputNode = ({ data, id }) => {
    const { appStore } = useStore();
    const { updateNodeData } = useReactFlow();
    const [anchorEl, setAnchorEl] = useState(null);
    const [hintAnchor, setHintAnchor] = useState(null);
    const [hoverRunNode, setHoverRunNode] = useState(null);
    const [hoverDelete, setHoverDeleter] = useState(null);
    const wf = useReactFlow()
    const nodes = useNodes()
    const [addNodeHandle, setAddNodeHandle] = useState(null)  // 点击handle添加节点的时候那个handle的id
    const [runNodeAndDelete, setRunNodeAndDelete] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [complete, setComplete] = useState(false)


    const onChange = useCallback(({ nodes, edges }) => {
      console.log('paramNode select onchange')
      console.log(nodes[0])
      if (nodes.length === 1) {
        if (nodes[0].id === id) {
          setRunNodeAndDelete(true)
        }
        else {
          setRunNodeAndDelete(false)
        }
      }
      else {
        setRunNodeAndDelete(false)
      }
      
    }, []);


    useOnSelectionChange({ onChange })


    const isOutputConnected = (nodeId, handleId) => {
      // console.log('ffffffffffffffffffffffffffffff')
      const edges = wf.getEdges();
      // console.log(edges.some(edge => edge.source === nodeId && edge.sourceHandle === handleId))
      return edges.some(edge => edge.source === nodeId && edge.sourceHandle === handleId);
    };

    useEffect(() => {
      params.forEach((param) => {
        updateNodeData(id, { [param.name]: param.defaultValue });
      });
      updateNodeData(id, { nodeParams: params });
      
    }, [params, updateNodeData, id]);


    const [boxStyle, setBoxStyle] = useState({ borderRadius: '1rem', border: data?.completed ? '2px solid #0DBC0D' : '2px solid #C3E0FC', padding: '1rem 2rem', background: 'white', '.selected &': { border: '2px solid #70B6FF', boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.16)' }, minWidth: '12rem' })
    // 监听节点数据变化
    useEffect(() => {
      console.log('data change')
      console.log(data)
      console.log(data?.completed)
      if (data?.completed !== undefined) {
          if (data?.completed) {
            console.log('change node style')
            // 应用完成状态的样式
            setBoxStyle({
              ...boxStyle,
              border: `2px solid #0DBC0D`,
            });
            setComplete(true)

          } 
          else if (!data?.completed){
            console.log('change node style')
            // 移除完成状态的样式
            setBoxStyle({
              ...boxStyle,
              border: `2px solid #C3E0FC`,
            });
            setComplete(false)
          }
      }
      
    }, [data, nodes, wf]);


    const getUpdateParam = (paramName) => {
      return (evt) => {
        console.log(evt.target.value);
        updateNodeData(id, { [paramName]: evt.target.value });
      };
    };

    const handleAddNodeMenu = (event) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };

    const addNodeFromHandle = (handelId) => {
      return (e) => {
        console.log('handelId')
        console.log(handelId)
        setAddNodeHandle(handelId)
        handleAddNodeMenu(e)
      }
    }
    const handleAddNodeClose = () => {
      console.log('leave')
      setAnchorEl(null);
    };

    const handleHintPopover = (event) => {
      event.preventDefault();
      setHintAnchor(event.currentTarget);
    };
    const handleHintPopoverClose = (e) => {
      console.log('leave')
      console.log(e)
      e.preventDefault()
      // if (e.movementX === 0 && e.movementY === 0) return;
      // console.log('true leave')
      setTimeout(() => { setHintAnchor(null); }, 200)

    };

    const handleRunNodePopover = (event) => {
      event.preventDefault();
      setHoverRunNode(event.currentTarget);
    };
    const handleRunNodePopoverClose = (e) => {
      e.preventDefault()
      setTimeout(() => { setHoverRunNode(null); }, 200)
    }

    const handleDeletePopover = (event) => {
      event.preventDefault();
      setHoverDeleter(event.currentTarget);
    };
    const handleDeletePopoverClose = (e) => {
      e.preventDefault()
      setTimeout(() => { setHoverDeleter(null); }, 200)
    }



    const onMessage = (data) => {
      if (data === "complete") {
        console.log('parsedData', data)
        setProcessing(false)
        return 
      }


      // 解析从服务器接收到的数据
      const parsedData = data;
      console.log('parsedData', parsedData)
  
      const pre_str = "data:audio/mpeg;base64,"
  
      let isSeparate = false
      let file_name
      nodes.forEach(node => {
        console.log("node['type']", node['id'])
        if (node['id'] === parsedData['id'] && node['type'] === 'Separate') {
  
          isSeparate = true
        }
        if (node['type'] === 'Upload') {
          file_name = node['data']['fileName']
          console.log(file_name)
        }
      });
  
      let base64
      if (!isSeparate) {
        base64 = [pre_str + parsedData['result']['audio_b64']]
      }
      else {
        base64 = [pre_str + parsedData['result']['vocal'], pre_str + parsedData['result']['instrument']]
      }
      
      console.log(base64)
      // 更新node
      updateNodeData(parsedData['id'], {
        haveOutput: true,
        outputAudioFile: base64,
        fileName: file_name,
        completed: true
      });
  
      //  应该怎么直接修改ParamInputDrawer的output preview？

      // if (parsedData['id'] === id) {
      //   setChosenNode((prevNode) => {
      //     if (prevNode) {
      //       console.log('setChosenNode');
      //       return {
      //         ...prevNode,
      //         data: {
      //           ...prevNode.data,
      //           haveOutput: true,
      //           outputAudioFile: base64,
      //           fileName: chosenName
      //         },
      //       };
      //     }
      //     return prevNode;
      //   });
      // }
      
    };

    const runNode = () => {
      setProcessing(true)
      setComplete(false)
      setHoverRunNode(null)

      let workflow_obs = wf.toObject()
      workflow_obs['nodes'].forEach(node => {
        if (node['type'] === 'Upload' && node['data']['audioFile'] === null) {
          setProcessing(false)
          alert('Please upload a file!')
          return
        }
      });
  
      workflow_obs['nodes'].forEach(node => {
        updateNodeData(node['id'], {
          completed: false
        });
        console.log(node)
        // 如果node['data']['audioFile']存在，则设为None
        if (node['data'] && node['data']['outputAudioFile'] && node['type'] != 'Upload') {
          node['data']['outputAudioFile'] = [];
        }
      });
  
      console.log('Workflow data:', workflow_obs);
  
      // 调用 runWorkflow 并传递参数
      runWorkflow(setProcessing, appStore, workflow_obs, id, onMessage, (message) => {
        console.log('Received message:', message); // 处理并打印返回的消息
        if (message['id']) {
          onMessage(message)
        }
        if (message === "complete") {
          console.log('message', message)
          setProcessing(false)
          setComplete(true)
          return 
        }
      }, (error) => {
        setProcessing(false)
        console.error('Error:', error); // 处理错误
      });

    };


    const deleteThisNode = () => {
      wf.setNodes((nds) => nds.filter((node) => node.id !== id))
    }

    return (
      <Box>
            {/* processing */}
            {
                processing?
                (
                  <Box sx={{position: 'absolute', top: '-26px', right: '0px'}}>
                    <img style={{height: '20px'}} src="/processing.svg" alt="" />
                  </Box>
                )
                :
                (
                  <></>
                )
            }

            {
                complete?
                (
                  <Box sx={{position: 'absolute', top: '-26px', right: '198px'}}>
                    <img style={{height: '20px'}} src="/done.svg" alt="" />
                  </Box>
                )
                :
                (
                  <></>
                )
            }

            {/* topright run and delete node */}
            {
              runNodeAndDelete && !processing ?
              (
                <Box sx={{ float: 'right', position: 'relative', top: '-30px', marginBottom: '3px', backgroundColor: 'white', borderRadius: '5pt', display: 'flex', padding: '3px', paddingLeft: '6px', paddingRight: '6px', width: '50px', justifyContent: 'center', alignItems: 'center' }}>
                  <IconButton 
                      onMouseEnter={handleRunNodePopover}
                      onMouseLeave={handleRunNodePopoverClose}
                      onClick={runNode}
                      sx={{ marginRight: '5px', width: '20px', height: '20px', borderRadius: '2pt', padding: "0", "&:hover, &.Mui-focusVisible": { backgroundColor: "#C3E0FC", border: '1.5px solid #BEBEBE' } }}
                  >
                      <PlayArrowIcon fontSize="small" sx={{ color: '#BEBEBE' }} />
                  </IconButton>
                    
                  <IconButton 
                      onMouseEnter={handleDeletePopover}
                      onMouseLeave={handleDeletePopoverClose}
                      onClick={deleteThisNode}
                      sx={{ width: '20px', height: '20px', borderRadius: '2pt', padding: "0", "&:hover, &.Mui-focusVisible": { backgroundColor: "#C3E0FC", border: '1.5px solid #BEBEBE' } }}
                  >
                      <DeleteForeverIcon fontSize="small" sx={{ color: '#BEBEBE' }} />
                  </IconButton>
              </Box>
              )
              :
              (
                <></>
              )
            }

            <Box
              sx={boxStyle}
            // onContextMenu={handleContextMenu}
            >
              {inputNames.map((input, index) => (
                <Handle
                  key={input}
                  type="target"
                  position={Position.Left}
                  id={input}
                  style={{ top: `${100 / (inputNames.length + 1) * (index + 1)}%`, ...connectorCircleStyle }}
                />
              ))}
              <Typography variant="h6" component="div" color='#666666' fontFamily={'Poppins'} fontWeight={600}>
                <Box sx={{ backgroundColor: '#57A3F3', color: 'white', borderRadius: '20%', width: '1.5rem', height: '1.5rem', display: 'inline-flex', verticalAlign: 'middle', justifyContent: 'center', alignItems: 'center', marginRight: '0.5rem' }} component={'span'}>
                  <MemoryRounded sx={{ width: '90%', height: '90%' }} />
                </Box>
                {nodeName}
              </Typography>
              {/* {params.length === 0 ? null : <Divider sx={{ my: 1 }} />} */}
              {params.length === 0 ? null : <Box sx={{ my: 1 }} />}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%', flexDirection: 'column', }}>
                {params.map((param) => (
                  <Box
                    key={param.name}
                    mb={1}
                    sx={{ width: '100%', background: '#EEEEEE', borderRadius: '9999rem', padding: '0.5rem', color: '#5B5B5B', fontFamily: 'Poppins', fontWeight: 'medium', fontSize: '0.9rem' }}
                  >
                    <Box sx={{ backgroundColor: '#C3E0FC', color: 'white', borderRadius: '50%', width: '1.5rem', height: '1.5rem', display: 'inline-flex', verticalAlign: 'middle', justifyContent: 'center', alignItems: 'center', marginRight: '0.5rem' }} component={'span'}>
                      <MusicNoteRounded sx={{ width: '90%', height: '90%' }} />
                    </Box>
                    {param.name}
                  </Box>
                ))}
              </Box>
              {outputNames.map((output, index) => (
                <Box>
                  {
                    outputNames.length === 2 ?
                      (index === 0 ?
                        (
                          <Box sx={{position: 'absolute', right: '12px', top: '16px', backgroundColor: '#EEEEEE', borderRadius: '8pt', paddingLeft: '6px', paddingRight: '6px'}}>
                                <Typography sx={{ fontFamily: 'Poppins', color: '#666666', fontSize: '10px' }}>vocal</Typography> 
                          </Box>
                        )
                        :
                        (index === 1 ?
                          (
                            <Box sx={{position: 'absolute', right: '12px', top: '36.5px', backgroundColor: '#EEEEEE', borderRadius: '8pt', paddingLeft: '6px', paddingRight: '6px'}}>
                                <Typography sx={{ fontFamily: 'Poppins', color: '#666666', fontSize: '10px'}}>inst</Typography>
                            </Box>
                          )
                           :
                          null
                        )
                      ) :
                      null
                  }
                  <Handle
                    key={output}
                    type="source"
                    position={Position.Right}
                    id={output}
                    style={{
                      top: `${100 / (outputNames.length + 1) * (index + 1)}%`,
                      ...connectorCircleStyle,
                      background: isOutputConnected(id, output) ? '#007bff' : '#70B6FF'
                    }}
                    onClick={addNodeFromHandle(output)}
                    onMouseEnter={handleHintPopover}
                    onMouseLeave={handleHintPopoverClose}
                  />
                </Box>
              ))}

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleAddNodeClose}
                onMouseLeave={handleAddNodeClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {nodeNameList.map((nodeName, idx) => {
                  const newNodeId = uuid4();
                  const newEdgeId = uuid4();
                  // 此处需要注意，如果增加mix功能节点需要单独判断，他的输入handle有多个，所以targetHandle需要为music.1，此时还没有这个功能，之后加
                  return (
                    <MenuItem 
                      sx = {{
                        borderRadius: '7px',
                        marginLeft: '10px',
                        marginRight: '10px',
                        marginBottom: '6px',
                        '&:hover, &.Mui-selected, &.Mui-focusVisible': { // 覆盖默认的悬停样式
                          backgroundColor: 'transparent',
                          border: '2px solid #70B6FF',
                        },
                        fontFamily: 'Poppins',
                        fontWeight: '600'
                      }}
                      key={idx} onClick={(event) => { handleAddNodeClose(); event.stopPropagation(); wf.addNodes([{ id: newNodeId, type: nodeName, position: generateNewPosition(wf.getNodes()), data: {} }]); wf.addEdges([{ id: newEdgeId, source: id, target: newNodeId, sourceHandle: addNodeHandle, targetHandle: 'music' }]) }} > {/* 默认输入的handle只有一个且id为music */}
                      {nodeName}
                    </MenuItem>
                  );
                })}
                {/* <MenuItem onClick={(event) => {handleClose(); event.stopPropagation(); }}>Edit Node</MenuItem> */}
                {/* Add more menu items as needed */}
              </Menu>
              <Popover
                id={id}
                open={Boolean(hintAnchor)}
                anchorEl={hintAnchor}
                onClose={handleHintPopoverClose}
                sx={{ pointerEvents: 'none', borderRadius: '16pt',  }}    // 很重要，否则会一enter就leave
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                disableRestoreFocus
              >
                <Typography 
                  sx = {{
                    p: 2,
                    marginLeft: '10px',
                    marginRight: '10px',
                    // marginBottom: '6px',
                    fontFamily: 'Poppins',
                    fontWeight: '600'
                  }}
                >
                  Click to add nodes <br/>
                  Drag to connect nodes
                </Typography>
              </Popover>

              <Popover
                id={id}
                open={Boolean(hoverRunNode) && !processing}
                anchorEl={hoverRunNode}
                onClose={handleRunNodePopoverClose}
                sx={{ pointerEvents: 'none', borderRadius: '16pt',  }}    // 很重要，否则会一enter就leave
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                disableRestoreFocus
              >
                <Typography 
                  sx = {{
                    p: 2,
                    marginLeft: '10px',
                    marginRight: '10px',
                    // marginBottom: '6px',
                    fontFamily: 'Poppins',
                    fontWeight: '600'
                  }}
                >
                  Run to the current node
                </Typography>
              </Popover>

              <Popover
                id={id}
                open={Boolean(hoverDelete)}
                anchorEl={hoverDelete}
                onClose={handleDeletePopoverClose}
                sx={{ pointerEvents: 'none', borderRadius: '16pt',  }}    // 很重要，否则会一enter就leave
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                disableRestoreFocus
              >
                <Typography 
                  sx = {{
                    p: 2,
                    marginLeft: '10px',
                    marginRight: '10px',
                    // marginBottom: '6px',
                    fontFamily: 'Poppins',
                    fontWeight: '600'
                  }}
                >
                      Delete
                </Typography>
              </Popover>


            </Box>
      
      </Box>
      
    );
  };

  return MusicLinkInputNode;
};

export default getInputNode;