import { useStore } from "@/store"
import { Modal } from "antd"
import { useState } from "react"
import './news-modal.scss'
import { observer } from "mobx-react-lite"

const NewsModal = () => {
  const { appStore } = useStore()
  const [list, setList] = useState([
    {
      title: 'Remixly Beta Version Launched for Testing',
      tag: 'Version Update',
      ts: '11/05/24, 5:00 PM',
      msg: "The Remixly Beta version is now live for testing. During this period, works cannot be saved to your account, we ask for your understanding.\nWe welcome everyone to use it and provide feedback via email."
    },
    // {
    //   title: 'Title 2',
    //   tag: 'New feature',
    //   ts: '10/28/24, 5:00 PM',
    //   msg: 'Text text  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
    // },
    // {
    //   title: 'Title 3',
    //   tag: 'New feature',
    //   ts: '10/28/24, 5:00 PM',
    //   msg: 'Text text  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
    // }
  ])

  return (
    <Modal open={appStore.newsModalVisible} footer={null} onCancel={() => appStore.newsModalVisible = false} wrapClassName="news">
      <div className="header">What's New ?</div>
      <ul className="news-list">
        {
          list.map(e => {
            return (
              <li className="news-item">
                <div className="title">{ e.title }</div>
                <div className="mid">
                  <div className="tag">{ e.tag }</div>
                  <span className="ts">{ e.ts }</span>
                </div>
                <div className="msg">{ e.msg }</div>
              </li>
            )
          })
        }
      </ul>
    </Modal>
  )
}

export default observer(NewsModal)