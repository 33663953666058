import { PlayArrowOutlined } from "@mui/icons-material"
import { Button, Typography, Box } from "@mui/material"
import { useReactFlow, useNodes, } from "@xyflow/react"
import {runWorkflow} from '../../utils/api'
import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useStore } from '@/store';
const RunBtn = () => {
  const { appStore } = useStore();
  const nodes = useNodes();
  const { updateNodeData } = useReactFlow()
  const [processing, setProcessing] = useState(false)
  const [leftNodeNum, setleftNodeNum] = useState(0)
  const wf = useReactFlow()

  useEffect(() => {
    console.log(leftNodeNum)
    if (leftNodeNum === 0) {
      setProcessing(false)
    }
  }, [leftNodeNum])

  
  const onMessage = (data) => {
      if (data === "complete") {
        console.log('parsedData', data)
        setProcessing(false)
        return 
      }

      setleftNodeNum(leftNodeNum - 1)

      // 解析从服务器接收到的数据
      const parsedData = data;
      console.log('parsedData', parsedData)
  
      const pre_str = "data:audio/mpeg;base64,"
  
      let isSeparate = false
      let file_name
      nodes.forEach(node => {
        console.log("node['type']", node['id'])
        if (node['id'] === parsedData['id'] && node['type'] === 'Separate') {
  
          isSeparate = true
        }
        if (node['type'] === 'Upload') {
          file_name = node['data']['fileName']
          console.log(file_name)
        }
      });
  
      let base64
      if (!isSeparate) {
        base64 = [pre_str + parsedData['result']['audio_b64']]
      }
      else {
        base64 = [pre_str + parsedData['result']['vocal'], pre_str + parsedData['result']['instrument']]
      }
      
      console.log(base64)
      // 更新node
      updateNodeData(parsedData['id'], {
        haveOutput: true,
        outputAudioFile: base64,
        fileName: file_name,
        completed: true
      });
  
      //  应该怎么直接修改ParamInputDrawer的output preview？

      // if (parsedData['id'] === id) {
      //   setChosenNode((prevNode) => {
      //     if (prevNode) {
      //       console.log('setChosenNode');
      //       return {
      //         ...prevNode,
      //         data: {
      //           ...prevNode.data,
      //           haveOutput: true,
      //           outputAudioFile: base64,
      //           fileName: chosenName
      //         },
      //       };
      //     }
      //     return prevNode;
      //   });
      // }
      
    };

    const onClick = () => {
      if (processing === true)
        {
          return 
        }
      setProcessing(true)

      let workflow_obs = wf.toObject()
      console.log('workflow_obs', workflow_obs)
      workflow_obs['nodes'].forEach(node => {
        if (node['type'] === 'Upload' && node['data']['audioFile'] === null) {
          alert('Please upload a file!')
          return
        }
      });
  
      workflow_obs['nodes'].forEach(node => {
        updateNodeData(node['id'], {
          completed: false
        });
        console.log(node)
        // 如果node['data']['audioFile']存在，则设为None
        if (node['data'] && node['data']['outputAudioFile'] && node['type'] != 'Upload') {
          node['data']['outputAudioFile'] = [];
        }
      });
  
      console.log('Workflow data:', workflow_obs);

      // 设置剩余node
      console.log('nodes.length', nodes.length)
      setleftNodeNum(nodes.length)
  
      // 调用 runWorkflow 并传递参数
      runWorkflow(setProcessing, appStore, workflow_obs, "", onMessage, (message) => {
        console.log('Received message:', message); // 处理并打印返回的消息
        if (message['id']) {
          onMessage(message)
        }
        if (message === "complete") {
          console.log('message', message)
          setProcessing(false)
          return 
        }
      }, (err) => {
        setProcessing(false)
        const axiosError = err;
        console.log("errxzxzxzxzxxzxzxzxzxzxz")
        console.log(axiosError.status)
        if (axiosError.status === 422) {
          console.log('422 is true')
          appStore.signUpModalVisible = true
        }

      });

    };


  return (
    // <Button disableElevation onClick={onClick} startIcon={<PlayArrowOutlined/>} variant="contained" sx={{color: 'white', borderRadius: '0.5rem',  height: '3rem', marginRight: '20px', fontFamily: 'Poppins-Bold'}}><Typography fontFamily={'Poppins-Bold'}>Run</Typography></Button>
    <Box sx={{ flexDirection: 'row', alignItems: 'center', gap: 2, display: 'inline-block' }}>
          {
            processing?
            (
              <Box sx={{position: 'absolute', right: '235px', top: '10px'}}>
                <img style={{height: '30px'}} src="/processing.png" alt="" />
              </Box>
            )
            :
            (
              <></>
            )
        }

        <Box 
          onClick={onClick}
          sx={{cursor: 'pointer', display: 'inline-block' }} 
          component={'span'}
          mr={2.5}
        >
            <img style={{height: '50px'}} src="/runIcon.png" alt="" />
        </Box>
    </Box>
    
  )
}

export default RunBtn