import React, { useCallback, useEffect, useMemo, useState, createContext } from 'react';
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  BackgroundVariant,
  useReactFlow,
  Panel,
  useOnSelectionChange,
  getOutgoers,
  ReactFlowProvider,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { Box, Typography, Button, Drawer, Input, Menu, MenuItem, IconButton, Grid2, Accordion, AccordionSummary, AccordionDetails, Divider, TextField, Modal, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuid4 } from 'uuid';
import RunBtn from './RunBtn';
import getInputNode from './nodes/ParamNode';
// import LoadAudioNode from './nodes/LoadAudioNode';
// import useAppBarHeight from '@/hooks/appbarHeight';
import { nodeNameList } from './consts';
import { generateNewPosition } from './utils';
import './index.css';
import ParamInputDrawer from '@/components/ParamInputDrawer';
import AudioPlayer from '@/components/AudioPlayer';
import { SaveOutlined, MemoryRounded, MusicNoteRounded } from '@mui/icons-material';

import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createWorkflow, getWorkflow, updateWorkflow } from '../../utils/api'

// import { useSearchParams } from 'next/navigation'
import conversionWorkflow from './template_workflow/conversion';
// import separateWorkflow from './template_workflow/separate';
import Header from '@/components/headerForWorkflow/Header';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import axios, { AxiosError } from 'axios';
import { useStore } from '@/store';


const initialNodes = [];
const initialEdges = [];

const Workflow = () => {
  const { appStore } = useStore();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [paramDrawerOpen, setParamDrawerOpen] = useState(false);
  const [nodeDrawerOpen, setNodeDrawerOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const [addNodeMenuOpen, setAddNodeMenuOpen] = useState(false);
  const [flowName, setFlowName] = useState('Remixly Workflow')
  const [flowId, setflowId] = useState('')
  const [loading, setLoading] = useState(false)
  const [savable, setSavable] = useState(false)

  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const template = searchParams.get('template');
  const flow_id = searchParams.get('flow_id');


  useEffect(() => {
    // 创建工作流的逻辑
    const createAndNavigate = async () => {
      let flowData = {
        nodes: nodes,
        edges: edges,
      };
      let token = `Bearer ${localStorage.getItem('access_token')}`;
      try {
        const flow_id = await createWorkflow(flowData, 'new remixly flow', token);
        console.log('resdwqdqwd', flow_id);
        // 更新当前页面的URL，而不是打开新标签页
        navigate(`/workflow?flow_id=${flow_id}`);
      } catch (err) {
        console.log('ffffffffffffffffffff')
        const axiosError = err;
        console.log("err!!!")
        console.log(axiosError.status)
        if (axiosError.status === 422) {
          console.log('422 is true')
          appStore.signUpModalVisible = true
        }
      }
    };

    // 没有template和flowid才执行
    if (!searchParams.has('template') && !searchParams.has('flow_id')) {
      createAndNavigate();
    }
  }, []);



  useEffect(() => {
    console.log('template', template)
    // console.log('params', params)
    console.log('loading', loading)
    if (template) {
      setSavable(false)
      setLoading(true)
      setStartHereVisible(false); // 如果有模板参数，则隐藏 "Start here"
      console.log(conversionWorkflow)
      setFlowName(template)
      setNodes(conversionWorkflow.nodes);
      setEdges(conversionWorkflow.edges);
      setLoading(false)
    }
    if (flow_id) {
      setSavable(true)
      setLoading(true)
      // setFlowName(flow_id)
      setflowId(flow_id)
      console.log('flowid', flow_id)
      // 请求
      getWorkflow(flow_id).then((res) => {
        console.log('workflow', res)
        setFlowName(res.flow_name)
        fetch(res.flow_path, { method: 'GET', mode: "cors" }).then((res) => {
          return res.json()
        }).then((flow) => {
          console.log('flow', flow)
          setNodes(flow.nodes)
          setEdges(flow.edges)
          if (flow.nodes.length !== 0) {
            setStartHereVisible(false);
          }
          setLoading(false)
        }).catch((err) => {
          const axiosError = err;
          console.log("err!!!")
          console.log(axiosError.status)
          if (axiosError.status === 422) {
            console.log('422 is true')
            appStore.signUpModalVisible = true
          }
        }).finally(() => {
          setLoading(false)
        })
      })
    }
  }, [template, flow_id]);





  const [WorkflowRun, setWorkflowRun] = useState(false)
  useEffect(() => {
    console.log('WfRun changed')
    console.log('WfRun', WorkflowRun)
  }, [WorkflowRun])


  const { addNodes, addEdges, getNodes, getEdges } = useReactFlow();

  const toggleNodeDrawer = (drawerState) => {
    return () => setNodeDrawerOpen(drawerState);
  };

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  const modelNames = ['Aaliyah Davis', 'Ariana Granda', 'Azi', 'Carl Johnson', 'Chloe Jing', 'Cyrus Lennox', 'Daisy Gui', 'Donald Trump', 'Elijah Bennett', 'Emily Thompson', 'Homer Simpson', 'Ivy Roberts', 'Jack Harper', 'Jasmine Carter', 'Joe Biden', 'Lily Morgan', 'Marcus Johnson', 'Sonic', 'Spongebob', 'Taylor Swift', 'Twilight Sparkle', 'suiji', 'zy_odd_mix_0.7', 'zy_odd_mix_0.8', 'zy_odd_mix_0.9']
  const formats = ['wav', 'mp3', 'flac']
  const voiceNames = ['en-US-Wavenet-A','en-US-Wavenet-B','en-US-Wavenet-C','en-US-Wavenet-D','en-US-Wavenet-E','en-US-Wavenet-F','en-US-Wavenet-G','en-US-Wavenet-H','en-US-Wavenet-I','en-US-Wavenet-J']

  const VConversionNode = getInputNode({ nodeName: 'Conversion', inputNames: ['music'], outputNames: ['music'], params: [{ name: 'model', type: 'enum', defaultValue: 'Azi', values: modelNames }], haveOutput: false, outputAudioFile: [] })
  const TTSNode = getInputNode({ nodeName: 'TTS', inputNames: [], outputNames: ['music'], params: [{ name: 'voice', type: 'enum', defaultValue: 'en-US-Wavenet-A', values: voiceNames }, {name: 'text', type: 'string', defaultValue: '' }], haveOutput: false, outputAudioFile: [] })
  const SeparateNode = getInputNode({ nodeName: 'Separate', inputNames: ['music'], outputNames: ['music.1', 'music.2'], params: [], haveOutput: false, outputAudioFile: [] })
  const DeechoNode = getInputNode({ nodeName: 'DeEcho', inputNames: ['music'], outputNames: ['music'], params: [], haveOutput: false, outputAudioFile: [] })
  const DereverbNode = getInputNode({ nodeName: 'Dereverb', inputNames: ['music'], outputNames: ['music'], params: [], haveOutput: false, outputAudioFile: [] })
  const PitchShiftNode = getInputNode({ nodeName: 'Pitch Shifter', inputNames: ['music'], outputNames: ['music'], params: [{ name: 'shift', type: 'number', defaultValue: 0, range: { min: -12, max: 12, step: 1 } }], haveOutput: false, outputAudioFile: [] })
  const SpeedChangeNode = getInputNode({ nodeName: 'Speed Changer', inputNames: ['music'], outputNames: ['music'], params: [{ name: 'speed', type: 'number', defaultValue: 1.1, range: { min: 0.1, max: 10, step: 0.1 } }], haveOutput: false, outputAudioFile: [] })
  // const LinkInputNode = getInputNode({ nodeName: 'Link', inputNames: [], outputNames: ['music'], params: [{ name: 'link', type: 'string', defaultValue: "https://example.com/default.mp3" }], haveOutput: false, outputAudioFile: [] })
  const LoadAudioNode = getInputNode({ nodeName: 'Upload', inputNames: [], outputNames: ['music'], params: [{ name: 'audioFile', type: 'file', defaultValue: null }], haveOutput: false, outputAudioFile: [] })
  // const Mixing = getInputNode({ nodeName:'Mixing', inputNames: ['music.1', 'music.2'], outputNames:['music'] , params:[{name: 'Track1', type: 'number', defaultValue: 50, range: {min: 0, max: 100, step: 1}}, {name: 'Track2', type: 'number', defaultValue: 50, range: {min: 0, max: 100, step: 2}}], haveOutput: false, outputAudioFile: [] })
  const Mixing = getInputNode({ nodeName: 'Mixing', inputNames: ['music.1', 'music.2'], outputNames: ['music'], params: [], haveOutput: false, outputAudioFile: [] })
  const Output = getInputNode({ nodeName: 'Output', inputNames: ['music'], outputNames: [], params: [{ name: 'Format', type: 'enum', defaultValue: 'wav', values: formats }], haveOutput: false, outputAudioFile: [] })



  const nodeTypes = useMemo(() => ({ Conversion: VConversionNode, Separate: SeparateNode, Deecho: DeechoNode, Dereverb: DereverbNode, PitchShifter: PitchShiftNode, SpeedChanger: SpeedChangeNode, Upload: LoadAudioNode, Mixing: Mixing, Output: Output, TTS: TTSNode }), []);



  const [startHereVisible, setStartHereVisible] = useState(true); // 控制“Start here”框的显示
  const [inputOptionsVisible, setInputOptionsVisible] = useState(false); // 控制输入选项菜单的显示
  const handleStartHereClick = () => {
    setInputOptionsVisible(true); // 显示输入选项菜单
    setStartHereVisible(false); // 隐藏“Start here”框
  };

  const handleUploadFileClick = () => {
    const newNodeId = uuid4();
    setNodes([...nodes, { id: newNodeId, type: 'Upload', position: generateNewPosition(nodes), data: {} }]);
    setInputOptionsVisible(false); // 隐藏输入选项菜单
    // setStartHereVisible(false); // 隐藏“Start here”框
  };

  const handleFetchFromLinkClick = () => {
    const newNodeId = uuid4();
    setNodes([...nodes, { id: newNodeId, type: 'Link', position: generateNewPosition(nodes), data: {} }]);
    setInputOptionsVisible(false); // 隐藏输入选项菜单
    setStartHereVisible(false); // 隐藏“Start here”框
  };

  const handleFromTTSClick = () => {
    const newNodeId = uuid4();
    setNodes([...nodes, { id: newNodeId, type: 'TTS', position: generateNewPosition(nodes), data: {} }]);
    setInputOptionsVisible(false); // 隐藏输入选项菜单
    setStartHereVisible(false); // 隐藏“Start here”框
  };

  const handleFlowNameChange = (event) => {
    setFlowName(event.target.value)
  }


  const isValidConnection = useCallback(
    (connection) => {
      // 检查类型是否匹配
      if (connection.source === connection.target) return false;

      // 检查是否成环
      const nodes = getNodes();
      const edges = getEdges();
      const target = nodes.find((node) => node.id === connection.target);
      const hasCycle = (node, visited = new Set()) => {
        if (visited.has(node.id)) return true;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      // 检查目标桩是否已经有一个输入连接
      const targetNode = nodes.find(node => node.id === connection.target);
      const incomingEdges = edges.filter(edge => edge.target === connection.target && edge.targetHandle === connection.targetHandle);
      // console.log('connectionconnectionconnectionconnectionconnection')
      // console.log(connection)
      // console.log('edgesedgesedgesedgesedgesedgesedgesedgesedgesedges')
      // console.log(edges)
      if (incomingEdges.length > 0) {
        return false; // 目标桩已经有输入连接，不允许新的连接
      }

      return !hasCycle(target);
    },
    [getNodes, getEdges],
  )

  const handleCanvasClick = (event) => {
    // 获取事件的目标元素
    const target = event.target;

    // 检查事件目标是否是 ParamInputDrawer 或其子元素
    const isParamInputDrawerOrChild = target.classList.contains('param-input-drawer') || target.closest('.param-input-drawer');

    // 检查事件目标是否是节点或其子元素
    const isNodeOrChild = target.classList.contains('react-flow__node') || target.closest('.react-flow__node');

    // 如果点击的既不是 ParamInputDrawer 也不是节点或其子元素，关闭右侧菜单栏
    if (!isParamInputDrawerOrChild && !isNodeOrChild) {
      setParamDrawerOpen(false);
    }
  };

  const saveWorkflow = () => {
    let flowData = {
      nodes: nodes,
      edges: edges,
    }
    updateWorkflow(flowId, flowData, flowName).then((res) => {
      console.log('update workflow res', res)
      alert('save successfully!')
    })
  }
  


  const handleContextMenuClose = () => {
    setContextMenu(null);
    setContextMenuPosition(null);
    setAddNodeMenuOpen(false);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenu(true);
    setContextMenuPosition({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
    });
    setAddNodeMenuOpen(false); // Close the add node menu if it was open
  };

  const handleAddNodeMenu = (event) => {
    event.preventDefault();
    setAddNodeMenuOpen(true); // Open the add node menu
    setContextMenu(null); // Close the context menu
  };

  // 为第一个 Accordion 定义一个状态
  const [expanded1, setExpanded1] = useState(true);
  // 为第二个 Accordion 定义一个状态
  const [expanded2, setExpanded2] = useState(true);

  // 定义第一个 Accordion 的展开和折叠处理函数
  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpanded1(isExpanded ? panel : false);
  };

  // 定义第二个 Accordion 的展开和折叠处理函数
  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };


  // 创建一个新的组件WorkflowItem
  const WorkflowItem = ({ src, text, onClick }) => (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      sx={{
        width: '100%',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.03)', // hover 时的背景色
        },
        gap: '0.5rem',
        p: 0.3, // 添加一些内边距
        '& img': {
          width: '20px',
          height: '20px',
        },
      }}
      onClick={onClick}
    >
      <img src={src} alt="description" />
      <Typography component="div" disableTypography fontFamily='Poppins' fontSize='16px' fontWeight={700}>
        {text}
      </Typography>
    </Box>
  );

  // const StyledBox = styled(Box)(({ theme }) => ({
  //   display: 'flex',
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   width: '100%',
  //   cursor: 'pointer',
  //   gap: '0.5rem',
  //   padding: '0.3rem',
  //   borderRadius: '8px', // 圆角
  //   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // 阴影
  //   '&:hover': {
  //     backgroundColor: 'rgba(0, 0, 0, 0.03)', // hover 时的背景色
  //   },
  //   '& img': {
  //     width: '20px',
  //     height: '20px',
  //     objectFit: 'cover', // 保证图片不失真
  //   },
  // }));
  
  // const StyledTypography = styled(Typography)(({ theme }) => ({
  //   fontFamily: 'Poppins',
  //   fontSize: '16px',
  //   fontWeight: 700,
  //   color: theme.palette.text.primary, // 颜色自定义，或者使用主题颜色
  // }));
  
  // const WorkflowItem = ({ src, text, onClick }) => (
  //   <StyledBox onClick={onClick}>
  //     <img src={src} alt="description" />
  //     <StyledTypography>
  //       {text}
  //     </StyledTypography>
  //   </StyledBox>
  // );




  const handleItemClick = (item) => {
    console.log(`Item clicked: ${item}`);

    // 这里可以添加更多的逻辑
    // 例如，根据点击的项执行不同的操作
    switch (item) {
      case "Create new":
        // 执行创建新工作流的操作
        let flowData = {
          nodes: nodes,
          edges: edges,
        }
        // console.log('flowdata', flowData)

        let token = `Bearer ${localStorage.getItem('access_token')}`
        // console.log('token', token)
        // createWorkflow(flowData)
        try {
          createWorkflow(flowData, 'new remixly flow', token).then((flow_id) => {
            console.log('resdwqdqwd', flow_id)
            window.open(`https://remixly.ai/workflow?flow_id=${flow_id}`, '_blank');
          });

        } catch (error) {
          console.error('Failed to create workflow:', error);
          // 这里可以添加错误处理逻辑
          // 例如，显示错误消息给用户
        }


        break;

      case "History":
        // 执行查看历史记录的操作
        break;
      case "My workflows":
        // 执行查看我的工作流的操作
        navigate('/toolkit/my-workflows');
        break;
      case "Templates":
        // 执行查看模板的操作
        navigate('/toolkit/templates');
        break;
      default:
        // 默认操作，如果需要的话
        break;
    }
  }


  // const Menu = () => {
  //   const [activeItem, setActiveItem] = useState(null);
  
  //   const handleItemClick = (item) => {
  //     console.log(`Item clicked: ${item}`);
  //     setActiveItem(item); // 设置当前活动项
  
  //     switch (item) {
  //       case "Create new":
  //         // 执行创建新工作流的操作
  //         break;
  //       case "History":
  //         // 执行查看历史记录的操作
  //         break;
  //       case "My workflows":
  //         // 执行查看我的工作流的操作
  //         break;
  //       case "Templates":
  //         // 执行查看模板的操作
  //         break;
  //       default:
  //         break;
  //     }
  //   }



  const NodeLibraryItem = ({ name, onClick }) => {

    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{
          width: '100%',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)', // hover 时的背景色
          },
          gap: '0.5rem',
          p: 0.3, // 添加一些内边距
          '& img': {
            width: '20px',
            height: '20px',
          },
        }}
        onClick={onClick}
      >
        <img src={'/Node.png'} alt="description" />
        <Typography component="div" disableTypography fontFamily='Poppins' fontSize='16px' fontWeight={700}>
          {name}
        </Typography>
      </Box>
    );
  };

  const handleNodeClick = (name) => {
    const newNodeId = uuid4();
    setNodes([...nodes, { id: newNodeId, type: name, position: generateNewPosition(nodes), data: {} }]);
  };

  const barHeight = 100;







  return (
    <>
      <Header showBreadCrumb={true} />
      {/* <div style={{ 
        height: `calc(100vh - ${barHeight}px - 40px)`, 
        border: '20px solid white', 
        borderTop: '0px', 
        backgroundColor: 'white' 
        }}> */}

        {/* height: `calc(90vh)`, 
        border: '20px solid white', 
        borderTop: '0px', 
        backgroundColor: 'white' 
        }}> */}



        {/* reactworkspace */}
        <div style={{ 
        position: 'absolute', // 需要用绝对定位
        top: '8%',            // 距离顶部 5%
        left: '10px',         // 距离左边 20px
        right: '10px',        // 距离右边 20px
        bottom: '10px',       // 距离底部 20px
        border: '10px solid white', 
        borderTop: '0px', 
        backgroundColor: 'white' 
        }}>



        <Modal open={loading} style={{ outline: 0 }}>
          <Box sx={{
            position: 'absolute',
            top: '10%',
            left: '50%',
            transform: 'translate(-50%, -50%)', outline: 0
          }}>
            <CircularProgress />
          </Box>
        </Modal>


        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={(e, node) => {
            if (e.target.classList.contains('react-flow__handle')) return;
            setParamDrawerOpen(true);
          }}
          fitView
          isValidConnection={isValidConnection}
          className="validationflow"
          // onClick={handleCanvasClick}
          onContextMenu={handleContextMenu}
          style={{ '--xy-edge-stroke-selected-default': '#57A3F3', borderRadius: '16pt' }}
        >

          {startHereVisible && (
            <div
              className="start-here-box"
              onClick={handleStartHereClick}
              style={{
                borderRadius: '1rem',
                border: '2px solid #C3E0FC',
                padding: '1rem 2rem',
                background: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                cursor: 'pointer',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10,
              }}
            >
              <Typography 
                variant="h6" 
                component="div" 
                color='#666666' 
                fontFamily={'Poppins'} 
                fontWeight={600}
                >
                <Box sx={{ 
                  backgroundColor: '#57A3F3', 
                  color: 'white', 
                  borderRadius: '20%', 
                  width: '1.5rem', 
                  height: '1.5rem', 
                  display: 'inline-flex', 
                  verticalAlign: 'middle', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  marginRight: '0.5rem' 
                  }} component={'span'}>
                  <MemoryRounded sx={{ width: '90%', height: '90%' }} />
                </Box>
                Start here!
              </Typography>
            </div>
          )}


          {/* start input框 */}
          {inputOptionsVisible && (
            <div className="input-options-menu" style={{
              borderRadius: '1rem',
              border: '2px solid #C3E0FC',
              padding: '1rem 2rem',
              background: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start', // 改为左对齐
              justifyContent: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%', // 根据需要调整位置
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // 添加阴影效果
            }}>
              <Typography variant="h6" component="div" color='#666666' fontFamily={'Poppins'} fontWeight={600} mb={1}>
                <Box sx={{ backgroundColor: '#57A3F3', color: 'white', borderRadius: '20%', width: '1.5rem', height: '1.5rem', display: 'inline-flex', verticalAlign: 'middle', justifyContent: 'center', alignItems: 'center', marginRight: '0.5rem' }} component={'span'}>
                  <MemoryRounded sx={{ width: '90%', height: '90%' }} />
                </Box>
                Input
              </Typography>
              {/* 添加分割线 */}
              <Divider sx={{ borderColor: '#C6C6C6', borderWidth: '0.6px', width: '100%', marginBottom: '1vh' }} />
              <Box
                onClick={handleUploadFileClick}
                sx={{
                  width: '100%',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.5rem 0.5rem',
                  marginBottom: '0.2rem',
                  borderRadius: '0.5rem',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: '#E0F2FF', // 鼠标悬浮时的背景色
                  },
                  color: '#57A3F3',
                  fontFamily: 'Poppins',
                }}
              >
                <img src="/Node.png" alt="Upload File" style={{ marginRight: '0.5rem' }} />
                <Typography sx={{ fontFamily: 'Poppins', color: '#3F3F3F', fontWeight: '600' }}>
                  Upload File
                </Typography>
              </Box>
              {/* <Box
                onClick={handleFetchFromLinkClick}
                sx={{
                  width: '100%',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.5rem 0.5rem',
                  borderRadius: '0.5rem',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: '#E0F2FF', // 鼠标悬浮时的背景色
                  },
                  color: '#57A3F3',
                  fontFamily: 'Poppins',
                }}
              >
                <img src="/Node.png" alt="Fetch From Link" style={{ marginRight: '0.5rem' }} />
                <Typography sx={{ fontFamily: 'Poppins', color: '#3F3F3F', fontWeight: '600' }}>
                  Fetch From Link
                </Typography>
              </Box>*/}
              <Box
                onClick={handleFromTTSClick}
                sx={{
                  width: '100%',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.5rem 0.5rem',
                  borderRadius: '0.5rem',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: '#E0F2FF', // 鼠标悬浮时的背景色
                  },
                  color: '#57A3F3',
                  fontFamily: 'Poppins',
                }}
              >
                <img src="/Node.png" alt="From TTS" style={{ marginRight: '0.5rem' }} />
                <Typography sx={{ fontFamily: 'Poppins', color: '#3F3F3F', fontWeight: '600' }}>
                  From TTS
                </Typography>
              </Box>
            </div>
          )}

          <Panel position="top-left">
            <Button sx={{ position: 'relative' }} onClick={toggleNodeDrawer(true)}>
              <MenuIcon sx={{ color: 'black' }} />
            </Button>

            {/* TextField组件，绑定value和onChange事件处理函数 */}
            <TextField
              // label="WorkflowName"
              variant="outlined"
              value={flowName}
              onChange={handleFlowNameChange}
              size="small"
              style={{ width: '180px', fontSize: '8px', fontFamily: 'Poppins' }}
              inputProps={{ style: { textAlign: 'center', fontSize: '15px', fontFamily: 'Poppins' } }}
            // InputLabelProps={{style: {fontSize: '10px'}}} 
            />

            
            {/* <Drawer
              elevation={0}
              anchor="left"
              open={nodeDrawerOpen}
              onClose={toggleNodeDrawer(false)}
              PaperProps={{ 
                    style: { 
                    height: `calc(88vh - ${barHeight}px - 20px)`, 
                    top: `calc(${barHeight}px + 6vh + 20px)`, 
                    width: '370px', 
                    borderRadius: '16pt', 
                    backgroundColor: '#F5F5F5', 
                    overflowY: 'auto', 
                    scrollbarGutter: 'always', 
                    marginLeft: '40px', 
                    background: 'white' 
                  } 
                }}
              className='leftDrawer'
              BackdropProps={{
                style: {
                  backgroundColor: 'transparent', // 设置 backdrop 背景为透明
                }
              }}

            ></Drawer> */}


            {/* 左边导航栏 */}
            <Drawer
              elevation={0}
              anchor="left"
              open={nodeDrawerOpen}
              onClose={toggleNodeDrawer(false)}
              PaperProps={{ 
                    style: { 
                    height: `calc(84vh - 40px)`, 
                    top: '16%', 
                    left: '40px',
                    width: '320px', 
                    borderRadius: '16pt', 
                    backgroundColor: '#F5F5F5', 
                    overflowY: 'auto', 
                    scrollbarGutter: 'always', 
                    // marginLeft: '40px',
                    background: 'white' 
                  } 
                }}
              className='leftDrawer'
              BackdropProps={{
                style: {
                  backgroundColor: 'transparent', // 设置 backdrop 背景为透明
                }
              }}

            >

              <Box ml={2} mr={2} mt={1}>
                <Accordion
                  expanded={expanded1}
                  onChange={handleChange1('panel1')}
                  sx={{ background: 'transparent', boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ display: 'flex', alignItems: 'center', }}
                  >
                    <img
                      src='/workflowLogo.png'
                      alt="description"
                      style={{ width: '25px', height: '25px', marginLeft: '5px', marginRight: '10px' }}
                    />
                    <Box sx={{ overflow: 'hidden' }}>
                      <Typography
                        disableTypography
                        fontFamily='Poppins'
                        fontSize='18px'
                        fontWeight={700}
                        noWrap
                        textOverflow="ellipsis"
                        sx={{ display: 'inline-block', width: '100%' }}
                      >
                        {flowName}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      paddingLeft={2}
                      sx={{ gap: '0.8rem' }}
                    >
                      <WorkflowItem src="/Frame1.png" text="Create new" onClick={() => handleItemClick("Create new")} />
                      {/* <WorkflowItem src="/Frame2.png" text="History" onClick={() => handleItemClick("History")} /> */}
                      <WorkflowItem src="/Frame3.png" text="My workflows" onClick={() => handleItemClick("My workflows")} />
                      <WorkflowItem src="/Frame4.png" text="Templates" onClick={() => handleItemClick("Templates")} />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>

              {/* <Box sx={{ height: '2rem' }} /> */}
              <Divider variant='middle' />

              <Box ml={2} mr={2} mt={1} mb={3} sx={{ alignItems: 'center' }} >
                <Accordion
                  expanded={expanded2}
                  onChange={handleChange2('panel2')}
                  sx={{ background: 'transparent', boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    sx={{ display: 'flex', alignItems: 'center', }}
                  >
                    <img
                      src='nodeLogo.png'
                      alt="description"
                      style={{ width: '25px', height: '25px', marginLeft: '5px', marginRight: '10px' }}
                    />
                    <Box sx={{ overflow: 'hidden' }}>
                      <Typography
                        disableTypography
                        fontFamily='Poppins'
                        fontSize='18px'
                        fontWeight={700}
                        noWrap
                        textOverflow="ellipsis"
                        sx={{ display: 'inline-block', width: '100%' }}
                      >
                        Node Library
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      paddingLeft={2}
                      sx={{ gap: '0.8rem' }}
                    >
                      {nodeNameList.map((name) => (
                        <NodeLibraryItem
                          key={name}
                          name={name}
                          onClick={() => handleNodeClick(name)}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>


            </Drawer>


          </Panel>

          <Panel position="top-right" sx={{ display: 'flex', alignItems: 'center' }}>

            {/* save */}
            { savable?
              (
                <Box sx={{ cursor: 'pointer', display: 'inline-block' }} component={'span'} onClick={saveWorkflow} >
                  <img style={{ width: '50px' }} src="/saveIcon.png" alt="" />
                </Box>
              )
              :
              (
                <></>
              )
            }

            <Box sx={{ background: 'transparent', width: '2rem', display: 'inline-block' }} component={'span'}></Box>

            <RunBtn />

          </Panel>
          <ParamInputDrawer open={paramDrawerOpen} setOpen={setParamDrawerOpen} WfRun={WorkflowRun} setWfRun={setWorkflowRun} />

          {/* <Controls /> */}


          {/* <MiniMap /> */}
          {/* <Background variant={BackgroundVariant.Dots} gap={12} size={1} /> */}
          <Background
            variant={BackgroundVariant.Lines}
            color="#ECECEC"
            gap={0} // 没有间隙
            size={0} // 没有线条
          />
          <Menu
            open={Boolean(contextMenu)}
            onClose={handleContextMenuClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenuPosition !== null
                ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
                : undefined
            }
          >
            <MenuItem
              onClick={handleAddNodeMenu}
              sx={{
                borderRadius: '7px',
                marginLeft: '10px',
                marginRight: '10px',
                marginBottom: '6px',
                '&:hover, &.Mui-selected, &.Mui-focusVisible': { // 覆盖默认的悬停样式
                  backgroundColor: 'transparent',
                  border: '2px solid #70B6FF',
                },
                fontFamily: 'Poppins',
                fontWeight: '600'
              }}
            >
              Add Node
            </MenuItem>
            <MenuItem
              onClick={(event) => { event.stopPropagation(); }}
              sx={{
                borderRadius: '7px',
                marginLeft: '10px',
                marginRight: '10px',
                // marginBottom: '6px',
                '&:hover, &.Mui-selected, &.Mui-focusVisible': { // 覆盖默认的悬停样式
                  backgroundColor: 'transparent',
                  border: '2px solid #70B6FF',
                },
                fontFamily: 'Poppins',
                fontWeight: '600'
              }}
            >
              Run Flow
            </MenuItem>
          </Menu>

          {addNodeMenuOpen && (
            <Menu
              open={addNodeMenuOpen}
              onClose={handleContextMenuClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenuPosition !== null
                  ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
                  : undefined
              }
            >
              {nodeNameList.map((nodeName, idx) => {
                const newNodeId = uuid4();
                return (
                  <MenuItem
                    key={idx}
                    sx={{
                      borderRadius: '7px',
                      marginLeft: '10px',
                      marginRight: '10px',
                      marginBottom: '6px',
                      '&:hover, &.Mui-selected, &.Mui-focusVisible': { // 覆盖默认的悬停样式
                        backgroundColor: 'transparent',
                        border: '2px solid #70B6FF',
                      },
                      fontFamily: 'Poppins',
                      fontWeight: '600'
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setAddNodeMenuOpen(false);
                      // setNodes([...nodes, { id: newNodeId, type: nodeName, position: generateNewPosition(nodes), data: {} }]);
                      addNodes([{ id: newNodeId, type: nodeName, position: generateNewPosition(getNodes()), data: {} }]);
                      // addEdges([{ id: newEdgeId, source: id, target: newNodeId, sourceHandle: addNodeHandle, targetHandle: 'music' }])
                    }}>
                    {nodeName}
                  </MenuItem>
                );
              })}
            </Menu>
          )}
        </ReactFlow>
        <div style={{ height: '10px', width: '100%' }}></div>
      </div>
    </>

  );
};

export default () => (
  <ReactFlowProvider>
    <Workflow />
  </ReactFlowProvider>
)