import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  useReactFlow,
  useOnSelectionChange,
  useNodes,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { Box, Typography, Drawer, IconButton, Grid2 as Grid, MenuItem, TextField, Slider, InputLabel, Select, List, ListItem, ListItemText, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import useAppBarHeight from '@/hooks/appbarHeight';
import {nodeDescription} from '../pages/workflow/consts'
import AudioPlayer from '@/components/AudioPlayer';

import {runWorkflow} from '../utils/api'

const SliderInput = ({ arg, onChange, value }) => {
  console.log(arg)
  console.log(onChange)
  console.log(value)
  return (
    <Grid container spacing={2} flexDirection={'row'} justifyContent={'space-around'} alignItems={'center'} paddingBottom={'1rem'} flex={1}>
      <Grid size={4}>
        <Typography style={{ color: '#A7A7A7', fontFamily: 'Poppins', fontWeight: '700', fontSize: '0.8rem' }}>{arg.name} :</Typography>
      </Grid>
      <Grid size={8}>
        <Slider min={arg.range.min} max={arg.range.max} step={arg.range.step} onChange={onChange} aria-label="Default" valueLabelDisplay="auto" value={value} color='blue' sx={{
          '& .MuiSlider-thumb': {
            backgroundColor: '#FFFFFF', // 自定义滑块颜色
            width: '0.6vw',
            height: '0.6vw',
          },
          '& .MuiSlider-track': {
            backgroundColor: '#A7A7A7', // 自定义轨道颜色
          },
          '& .MuiSlider-rail': {
            backgroundColor: '#D9D9D9', // 自定义轨道背景色
          },
          '&:hover .MuiSlider-thumb': {
            boxShadow: '0px 0px 8px #57A3F3', // 鼠标悬浮时的阴影
          },
          position: 'relative',
          left: '-0.8vw',
          top: '0.15vh'
        }} />
      </Grid>
    </Grid>
  )
}


const menuProps = {
  // color: '#A7A7A7',
  // background: 'white',
  PaperProps: {
    style: {
      backgroundColor: '#FFFFFF',
      color: '#A7A7A7',
      border: '1px solid #A7A7A7',
      borderRadius: '0.5rem',
      boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)', // 添加阴影效果
      '& .MuiMenuList-root.Mui-selected': {
        backgroundColor: 'white'
      }
    },
  },
  variant: 'menu',
  MenuListProps: {
    variant: 'menu',
    style: {
      '&>li.MuiListItem-root.Mui-selected': {
        backgroundColor: 'white'
      },
      '& .MuiListItem-root': {
        padding: '0.5rem 1rem',
        borderRadius: '0.5rem',
        '&:hover': {
          backgroundColor: '#57A3F3',
          color: 'white'
        }
      },
      '& .MuiListItemText-root': {
        margin: 0
      }
    }
  }

}

const ArgInput = ({ arg, onChange, value, updateNodeData, chosenId, setHaveOutput, setParamRecord, setChosenNode, audioRef, chosenNode }) => {
  /**
   * arg: {
   * name: xxx,
   * type: xxx,(number, string)
   * range?: {
   * min:0,
   * max:1,
   * step:1
   * }，
   * default?:xxx
   * }
  */
  // const [inputArgs, setInputArgs] = useState({})
  console.log('arg')
  console.log(arg)
  // 新增状态，用于跟踪文件上传状态
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileUpload = (event) => {
    console.log('handleFileUploadhandleFileUploadhandleFileUploadhandleFileUploadhandleFileUpload')
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileUrl = reader.result;
        console.log('fileUrlfileUrlfileUrlfileUrlfileUrlfileUrlfileUrlfileUrlfileUrlfileUrlfileUrlfileUrlfileUrl')
        console.log(fileUrl)

        updateNodeData(chosenId, {
          haveOutput: true,
          outputAudioFile: [fileUrl],
          fileName: file.name
        });

        setChosenNode((prevNode) => {
          if (prevNode) {
            console.log('setChosenNode');
            return {
              ...prevNode,
              data: {
                ...prevNode.data,
                haveOutput: true,
                outputAudioFile: [fileUrl],
                fileName: file.name
              },
            };
          }
          return prevNode;
        });

        
        setHaveOutput(true); // 设置有输出的状态
        setParamRecord((prevRecord) => ({ ...prevRecord, audioFile: [fileUrl] })); // 更新 paramRecord 中的 audioFile
        onChange({ target: { value: [fileUrl] } }); // 更新参数值
        setUploadedFile(file.name); // 更新已上传文件名
      };
      reader.readAsDataURL(file); // 读取文件内容
    }
  };

  // useEffect(() => {
  //   console.log(inputArgs)
  // }, [inputArgs])
  return (
    arg.type === 'const' ?
      <></>
      : arg.range ?
        // 滑条
        <SliderInput arg={arg} onChange={onChange} value={value ? value : arg.defaultValue} />
        : arg.type === 'enum' ?
          // 选模型
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '1rem' }}>

            <InputLabel
              style={{ color: '#A7A7A7', fontFamily: 'Poppins', fontSize: '0.8rem', fontWeight: '600', minWidth: '3vw' }}
            >
              {arg.name}
            </InputLabel>

            <Select
              label={""}
              // label={arg.name}
              onChange={onChange}
              value={value ? value : arg.defaultValue}
              sx={{
                '& legend': { color: 'black' }, 'label + &': { color: '#A7A7A7' }, height: '1.8rem', border: '2px solid #A7A7A7', borderRadius: '9999px', '&:focus': {
                  '&:hover': { borderColor: '#A7A7A7' },
                },
                boxShadow: '0px',
                width: '75%'
              }}
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              color='#A7A7A7'
              variant="outlined"
              MenuProps={menuProps}
            >
              {arg.values.map((item, idx) => {
                return <MenuItem value={item} key={item}>{item}</MenuItem>
              })}
            </Select>
          </Box>
        
        : arg.type === 'file' ?
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
          <label htmlFor="audio-input" sx={{ ml: 2 }}>
          <Button 
            variant="outlined" 
            component="span"
            style={{ color: '#fff', borderColor: '#007bff', backgroundColor: '#57A3F3', fontWeight: 'Bold', fontFamily: 'Poppins', borderRadius: '6pt', textTransform: 'none' }}
          >
            Upload
          </Button>
        </label>
          <img src="/fileIcon.png" alt="" style={{marginLeft: 10, marginRight: '15px', border: 'none', }} />
          <Box sx={{ minWidth: '3vw', maxWidth: '50%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', display: 'flex', marginRight: '5px'  }}>
            {chosenNode?.data?.fileName ? 
            <Typography style={{ 
              color: '#A7A7A7', 
              fontFamily: 'Poppins', 
              fontSize: '0.8rem' 
            }}>
              {chosenNode?.data?.fileName}
            </Typography> : <Typography style={{ color: '#A7A7A7', fontFamily: 'Poppins', fontSize: '0.8rem' }}>No file uploaded</Typography>}
          </Box>
        <input
          accept="audio/*"
          style={{ display: 'none' }}
          id="audio-input"
          type="file"
          onChange={handleFileUpload}
        />
        {/* <label htmlFor="audio-input" sx={{ ml: 2 }}>
          <Button 
            variant="outlined" 
            component="span"
            style={{ color: '#fff', borderColor: '#007bff', backgroundColor: '#57A3F3', fontWeight: 'Bold', fontFamily: 'Poppins', borderRadius: '6pt', textTransform: 'none' }}
            
          >
          
            Upload
          </Button>
        </label> */}
      </Box>
      : arg.type === 'string' && arg.name === 'link'?
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
          <TextField
            sx={{ flexBasis: '75%', '& input': { color: '#666666' }, marginRight: '1rem' }}
            color='blue'
            type={arg.type}
            label={arg.name}
            variant="outlined"
            fullWidth
            onChange={onChange}
            value={value ? value : arg.defaultValue}
          />
          <Button sx={{ backgroundColor: '#57A3F3', color: 'white', textTransform: 'capitalize', padding: '0.5rem 1rem', height: '55px' }}>
              <Typography fontFamily='Poppins' fontSize='15px' fontWeight={400} > Fetch </Typography>
          </Button>
      </Box>

      :
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <InputLabel
          style={{ color: '#A7A7A7', fontFamily: 'Poppins', fontSize: '0.8rem', fontWeight: '600', minWidth: '3vw' }}
        >
          {arg.name}
        </InputLabel>
        <TextField sx={{ '& input': { color: '#666666' } }} color='blue' type={arg.type} label={arg.name} variant="outlined" fullWidth onChange={onChange} value={value ? value : arg.defaultValue} multiline rows={8}></TextField>
      </Box> 
  )
}


// 右侧菜单栏
const ParamInputDrawer = ({ open, setOpen, WfRun, setWfRun }) => {
  const [paramInput, setParamInput] = useState(false)
  const [paramInfo, setParamInfo] = useState([]);
  const [chosenId, setChosenId] = useState()
  const [chosenNode, setChosenNode] = useState(null)
  const [chosenName, setChosenName] = useState('Node Name')
  const [chosenNameDescription, setChosenNameDescription] = useState('');

  useEffect(() => {
    console.log('WfRun changed inner')
    console.log('WfRun', WfRun)
  }, [WfRun])
  

  useEffect(() => {
    setChosenNameDescription(nodeDescription[chosenName] || 'Description not available');
  }, [chosenName]);

  const [paramRecord, setParamRecord] = useState({})
  const { updateNodeData } = useReactFlow()
  const wf = useReactFlow()

  const [haveOutput, setHaveOutput] = useState(false); // 初始状态为false
  const audioRef = useRef(null);

  // 获取当前node的输入node
  const nodes = useNodes();
  const [connectedNodesNames, setConnectedNodesNames] = useState([]);
  useEffect(() => {
    if (!chosenId) return;

    // 获取所有边
    const edges = wf.getEdges();
    console.log('edgesedgesedgesedgesedgesedgesedgesedgesedgesedgesedgesedgesedges')
    console.log(edges)
    console.log('chosenIdchosenIdchosenIdchosenIdchosenIdchosenIdchosenIdchosenId')
    console.log(chosenId)
    // 筛选出所有连接到选中节点输入桩的边
    const incomingEdges = edges.filter(edge => edge.target === chosenId);
    console.log('incomingEdges')
    console.log(incomingEdges)

    // 根据边的信息筛选出源节点
    const connectedNodes = incomingEdges.map(edge => nodes.find(node => node.id === edge.source));
    console.log('connectedNodes')
    console.log(connectedNodes)

    console.log('nodesnodesnodesnodesnodesnodesnodesnodesnodesnodesnodes')
    console.log(nodes)

    // 提取节点名称
    // const names = connectedNodes.map(node => node.data.name);

    // // 设置连接节点的名称
    // console.log('qaqaqaqaqa')
    // console.log(names)
    setConnectedNodesNames(connectedNodes);
  }, [nodes, chosenId, wf]);

  useEffect(() => {
    if (chosenNode) {
      console.log('chosenNode chosenNode chosenNode chosenNode chosenNode ')
      console.log(chosenNode)
      console.log(chosenNode?.data?.haveOutput)
      console.log(chosenNode?.data?.outputAudioFile)

      if (audioRef.current) {
        // 清空所有 source 元素
        const sources = audioRef.current.querySelectorAll('source');
        sources.forEach(source => source.remove());
        
        // 添加新的 source 元素
        const newSource = document.createElement('source');
        newSource.src = chosenNode?.data?.outputAudioFile;
        newSource.type = 'audio/mpeg';
        audioRef.current.appendChild(newSource);
        
        // 重新加载音频元素
        audioRef.current.load();
        audioRef.current.play();
      }
    }
  }, [chosenNode, chosenNode?.data?.outputAudioFile]);

  useEffect(() => {
    if (!open) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    }
  }, [open]);


  const onChange = useCallback(({ nodes, edges }) => {
    console.log(nodes)
    console.log(open)
    if (nodes.length === 1) {
      setParamInput(true)
      // const paramList = nodes[0].data.nodeParams?.map((p) => {
      //   return { [p.name]: nodes[0].data[p.name] }
      // })
      console.log('nodenodenodenodenode')
      console.log(nodes[0])
      setChosenName(nodes[0].type)
      const paramList = nodes[0].data.nodeParams
      console.log('ffffffffffffffffffffffffffffffffffffffffff')
      console.log(paramList)
      setParamInfo(paramList ? paramList : [])
      setChosenId(nodes[0].id)
      setChosenNode(nodes[0])
      let defaultParamRecord = {}
      // console.log("select paramInfo: ")
      // console.log(paramList)
      paramList && paramList.forEach(param => {
        defaultParamRecord[param.name] = nodes[0].data[param.name];
      });
      setParamRecord(defaultParamRecord);
    }
    else {
      setParamInput(false)
      setOpen(false)
    }
  }, []);

  useEffect(() => {
    setParamInput(open)
    console.log('should open')
  }, [open])

  useEffect(() => {
    console.log(paramInput)
  }, [paramInput])

  useOnSelectionChange({ onChange })

  const getUpdateParam = (paramName) => {
    return (evt) => {
      updateNodeData(chosenId, { [paramName]: evt.target.value })
      setParamRecord({ ...paramRecord, [paramName]: evt.target.value })
    }
  }


  // 定义 onMessage 函数
  const onMessage = (data) => {
    // 解析从服务器接收到的数据
    const parsedData = data;
    console.log('parsedData', parsedData)

    const pre_str = "data:audio/mpeg;base64,"

    let isSeparate = false
    let file_name
    nodes.forEach(node => {
      console.log("node['type']", node['id'])
      if (node['id'] === parsedData['id'] && node['type'] === 'Separate') {

        isSeparate = true
      }
      if (node['type'] === 'Upload') {
        file_name = node['data']['fileName']
        console.log(file_name)
      }
    });

    let base64
    if (!isSeparate) {
      base64 = [pre_str + parsedData['result']['audio_b64']]
    }
    else {
      base64 = [pre_str + parsedData['result']['vocal'], pre_str + parsedData['result']['instrument']]
    }
    
    console.log(base64)
    // 更新node
    updateNodeData(parsedData['id'], {
      haveOutput: true,
      outputAudioFile: base64,
      fileName: file_name,
      completed: true
    });

    if (parsedData['id'] === chosenId) {
      setChosenNode((prevNode) => {
        if (prevNode) {
          console.log('setChosenNode');
          return {
            ...prevNode,
            data: {
              ...prevNode.data,
              haveOutput: true,
              outputAudioFile: base64,
              fileName: chosenName
            },
          };
        }
        return prevNode;
      });
    }
    
  };

  const setWorkflowRunTrue = () => {
    console.log('setWorkflowRunTrue')
    setWfRun(true)
  }

  const setWorkflowRunFalse = () => {
    console.log('setWorkflowRunFalse')
    setWfRun(false)
  }

  // 定义运行节点的函数
  const runNode = () => {
    if (!chosenId) return; // 如果没有选择节点，则不执行任何操作
    
    console.log('gonna set wfrun')
    setWorkflowRunTrue()

    // let workflow_obs = JSON.parse(JSON.stringify(wf.toObject()))
    let workflow_obs = wf.toObject()


    workflow_obs['nodes'].forEach(node => {
      if (node['type'] === 'Upload' && node['data']['audioFile'] === null) {
        alert('Please upload a file!')
        return
      }
    });

    workflow_obs['nodes'].forEach(node => {
      updateNodeData(node['id'], {
        completed: false
      });
      console.log(node)
      // 如果node['data']['audioFile']存在，则设为None
      if (node['data'] && node['data']['outputAudioFile'] && node['type'] != 'Upload') {
        node['data']['outputAudioFile'] = [];
      }
    });

    console.log('Running node:', chosenId);
    console.log('Workflow data:', workflow_obs); // 获取工作流信息

    
    // 调用 runWorkflow 并传递参数
    runWorkflow(workflow_obs, chosenId, onMessage, (message) => {
      console.log('Received message:', message); // 处理并打印返回的消息
      if (message['id']) {
        onMessage(message)
      }
    }, (error) => {
      console.error('Error:', error); // 处理错误
    });

    setWorkflowRunFalse()
  };

  const getNodeDetail = () => {
    console.log('getNodeDetail')
  }



  const barHeight = 125
  return (
    // <div>
    //   <Drawer
    //     open={open}
    //     // onClose={() => { setParamInput(false); setOpen(false) }}
    //     anchor="right"
    //     variant="persistent"
    //     hideBackdrop
    //     classes={{ paper: 'param-input-drawer' }}
    //     PaperProps={{ style: { 
    //       height: `calc(91vh - ${barHeight}px - 40px)`, 
    //       top: `calc(${barHeight}px + 3vh + 40px)`, 
    //       width: '20%', 
    //       borderRadius: '16pt', 
    //       backgroundColor: 'white', 
    //       border: '2px dashed #A7A7A7', 
    //       marginRight: '50px' 
    //         } 
    //       }}
    //   >


      <div>
        <Drawer
          open={open}
          // onClose={() => { setParamInput(false); setOpen(false) }}
          anchor="right"
          variant="persistent"
          hideBackdrop
          classes={{ paper: 'param-input-drawer' }}
          PaperProps={{ style: { 
            height: `calc(82vh - 40px)`, 
            top: '18%', 
            width: '350px', 
            borderRadius: '16pt', 
            backgroundColor: 'white', 
            border: '2px dashed #A7A7A7', 
            marginRight: '40px' 
              } 
            }}
      >
        {/* <Box sx={{ height: barHeight }} /> */}
        {/* <Button onClick={() => setOpen(false)}>Close</Button> */}


        <Box
          sx={{
            position: 'absolute',
            top: -6,
            right: 1,
          }}
          mr={3}
          mt={2}
        >
          {/* 增加运行按钮 */}
          {/* <Button onClick={runNode} sx={{ ml: 1, color: 'white', backgroundColor: '#57A3F3', marginTop: '30px', marginRight: '30px' }}>
            Run
          </Button> */}
          <Box
            sx={{
              position: 'absolute',
              top: 25,
              right: 40, // 调整这个值以确保两个按钮组合在关闭按钮的左边
              display: 'flex', // 使用flex布局
              alignItems: 'center', // 垂直居中
              justifyContent: 'center', // 水平居中
            }}
          >

            {/* <Box
              onClick={runNode}
              sx={{
                cursor: 'pointer', // 添加手型指针样式，表示可点击
                display: 'flex', // 使用flex布局
                alignItems: 'center', // 垂直居中
                justifyContent: 'center', // 水平居中
                width: '1.5vw', // 宽度
                height: '1.5vw', // 高度
                marginRight: '15px', // 添加右边距，使两个Box分开
              }}
            >
              <img src="/RunNode.png" alt="" />
            </Box>


            <Box
              onClick={getNodeDetail} 
              sx={{
                cursor: 'pointer', // 添加手型指针样式，表示可点击
                display: 'flex', // 使用flex布局
                alignItems: 'center', // 垂直居中
                justifyContent: 'center', // 水平居中
                width: '1.5vw', // 宽度
                height: '1.5vw', // 高度
              }}
            >
              <img src="/GetNodeDetail.png" alt=""  />
            </Box> */}

            
          </Box>

          <IconButton 
            onClick={() => setOpen(false)}
            sx={{position: 'absolute', left: '-1vw', top: '-10px'}}
          >
            <CloseIcon sx={{ color: '#A6A6A6', fontSize: '1.4vw' }} />
          </IconButton>
        </Box>

        

        <Box p={2} ml={3} mr={3} mt={2} borderBottom={1} borderColor='#e0e0e0' >
          <Typography
            variant="h6"
            color='#57A3F3'
            fontFamily='Poppins-Bold'
            fontSize='1.4rem'
            fontWeight='1000'
          >
            {chosenName}
          </Typography>

          <Typography
            color="#666666"
            fontSize='0.8rem'
            fontWeight='600'
            fontFamily='Poppins'
          >
            {chosenNameDescription}
          </Typography>


          {/* 其他内容 */}
        </Box>

        <Box p={2} ml={3} mr={3} mt={1}>
          <Typography
            color="#666666"
            fontSize='1.1rem'
            fontWeight='800'
            fontFamily='Poppins-Bold'
            mb={2}
          >
            Input
          </Typography>

          {connectedNodesNames.length > 0 ? (
            <List>
              {connectedNodesNames.map((node, index) => (
                <ListItem
                  key={index}
                  style={{ padding: '3px', border: '2px solid #A7A7A7', borderRadius: '16pt', marginBottom: '10px' }}
                  mb={3}
                >
                  <img src='/nodePic.png' alt="description" style={{ width: '24px', height: '24px', marginLeft: '0.5vw', marginRight: '0.7vw' }} />
                  <ListItemText disableTypography sx={{ color: "#666666", fontFamily: 'Poppins', fontSize: '0.8rem', fontWeight: '600' }} primary={node.type} />
                </ListItem>
              ))}
            </List>
          ) 
          : 
          (
            <Box p={2} mt={3} sx={{borderColor: "#A6A6A6", borderStyle:"dashed", display:"flex", alignItems:"center", borderRadius: "40px"}}>
              <img src='/noOutput.png' alt="description" style={{ width: '1.4vw', height: '1.4vw', marginRight: '0.7vw' }} />
              {
                chosenName === 'Upload' ?
                (
                  <Typography color="#A6A6A6" fontSize='0.7rem' fontFamily='Poppins'>
                      No need to input node for Upload.
                  </Typography>
                )
                :
                (
                  <Typography color="#A6A6A6" fontSize='0.7rem' fontFamily='Poppins'>
                      Not connected.
                </Typography>
                )
              }
            </Box>
          )}

        </Box>

        <Box p={2} ml={3} mr={3} mt={1}>
          <Typography
            color="#666666"
            fontSize='1.1rem'
            fontWeight='800'
            fontFamily='Poppins-Bold'
            mb={2}
          >
            Parameter
          </Typography>
          {paramInfo ?
            paramInfo.map(param =>
              <ArgInput key={param.name}
                onChange={getUpdateParam(param.name)}
                value={paramRecord[param.name]} arg={param}
                updateNodeData={updateNodeData} // 传递函数
                chosenId={chosenId} // 传递 chosenId
                setHaveOutput={setHaveOutput}
                setParamRecord={setParamRecord}
                setChosenNode={setChosenNode}
                audioRef={audioRef}
                chosenNode={chosenNode}
              />)
            :
            <div>No node selected</div>}
        </Box>

        <Box p={2} ml={3} mr={3} mt={1}>
          <Typography
            color="#666666"
            fontSize='1.1rem'
            fontWeight='800'
            fontFamily='Poppins-Bold'
            mb='1.5vh'
          >
            Output preview
          </Typography>
          {chosenNode?.data?.haveOutput ? 
          // (
            // chosenNode.data.outputAudioFile.map((fileUrl, index) => (
            //   <audio key={index} controls>
            //     <source src={fileUrl} type="audio/mpeg" />
            //     Your browser does not support the audio element.
            //   </audio>
            // ))
          // ) 
          (
            chosenName === 'Separate'?
            chosenNode.data.outputAudioFile?.map((fileUrl, index) => (
              <AudioPlayer fileUrl={fileUrl} index={index} />
            ))
            :
            chosenNode.data.outputAudioFile?.map((fileUrl, index) => (
              <AudioPlayer fileUrl={fileUrl} />
            ))
          )
          
          : 
          
          (
            <Box p={2} mt={3} sx={{borderColor: "#A6A6A6", borderStyle:"dashed", display:"flex", alignItems:"center", borderRadius: "40px"}}>
              <img src='/noOutput.png' alt="description" style={{ width: '1.4vw', height: '1.4vw', marginRight: '0.7vw' }} />
              <Typography color="#A6A6A6" fontSize='0.7rem' fontFamily='Poppins'>
                Run the node to get an preview.
              </Typography>
            </Box>
          )}
        </Box>



      </Drawer>
      {/* <Button onClick={() => setParamInput(true)}>Param</Button> */}
    </div>
  )
}

export default ParamInputDrawer