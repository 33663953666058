import { apiBaseURL } from "@/enums";
import { createHttp } from "@/utils/http";

const http = createHttp(process.env.NODE_ENV === 'production' ? apiBaseURL.PROD : apiBaseURL.DEFAULT)

export interface RegisterReq {
  email: string;
  password: string;
  verificationCode: string;
}

export const verificationMail = (email: string) => {
  return http.post<any>({
    url: '/back/register/api/send-verification-email',
    data: {
      email
    }
  })
}

export const register = (data: RegisterReq) => {
  return http.post<any>({
    url: '/back/register/api/register',
    data
  })
}