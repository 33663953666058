import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '@/pages/home/index';
import Toolkit from '@/pages/toolkit/index'
import Pricing from '@/pages/pricing';
import Privacy from '@/pages/privacy';
import Cookies from '@/pages/cookies';
import Faqs from '@/pages/faq/FAQ';
import VocalRemoval from '@/pages/toolkit/Splitter';
import Tool1 from '@/pages/toolkit/Tool1';
import Tool2 from '@/pages/toolkit/Tool2';
import Tool3 from '@/pages/toolkit/Tool3';
import Templates from '@/pages/templates';
import MyCreations from '@/pages/my-creations';
import MyWorkflows from '@/pages/my-workflows';
import SongCover from '@/pages/toolkit/SongCover';
import SpeedChange from '@/pages/toolkit/SpeedChange';
import Deecho from '@/pages/toolkit/Deecho';
import Dereverb from '@/pages/toolkit/Dereverb';
import PitchShift from '@/pages/toolkit/PitchShift';
import Conversion from '@/pages/toolkit/Conversion';
import Workflow from '@/pages/workflow/index'


const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/home" element={<Home/>}/>
      <Route path="/pricing" element={<Pricing/>}/>
      <Route path="/privacy" element={<Privacy/>}/>
      <Route path="/cookies" element={<Cookies/>}/>
      <Route path="/faq" element={<Faqs/>}/>
      <Route path="/toolkit" element={<Toolkit/>}>
        <Route path="/toolkit/vocalRemoval" element={<VocalRemoval/>}/>
        <Route path="/toolkit/speedChange" element={<SpeedChange/>}/>
        <Route path="/toolkit/deecho" element={<Deecho/>}/>
        <Route path="/toolkit/dereverb" element={<Dereverb/>}/>
        <Route path="/toolkit/pitchShift" element={<PitchShift/>}/>
        <Route path="/toolkit/song-cover" element={<Conversion/>}/>
        <Route path="/toolkit/templates" element={<Templates/>}/>
        <Route path="/toolkit/my-creations" element={<MyCreations/>}/>
        <Route path="/toolkit/my-workflows" element={<MyWorkflows/>}/>
      </Route>
      <Route path="/workflow" element={<Workflow/>}/>
      <Route path="*" element={<div>page not found</div>}/>
    </Routes>
  )
}

export default App;