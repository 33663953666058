import { apiBaseURL } from "@/enums";
import { createHttp } from "@/utils/http";

const http = createHttp(process.env.NODE_ENV === 'production' ? apiBaseURL.PROD : apiBaseURL.DEFAULT)

export const inference2 = (data: any, onMessage: (obj: any) => void = (obj) => { }) => {
  http.post<any>({
    url: '/infer/submit-task',
    data
  }).then(res => {
    if (res.message === 'Received') {
      const task_id = res.task_id
      const eventSource = new EventSource(`https://remixly.ai/back/infer/task/${task_id}`);
      let result = {}
      eventSource.onmessage = (e) => {
        if (e.data === 'complete') {
          eventSource.close()
          return
        }
        if (e.data === 'start') {
          console.log('start sse')
          return
        }
        result = JSON.parse(e.data)
        onMessage(result)
      }
      eventSource.onerror = () => { console.log('sse error'); eventSource.close() }
      return result
    }
  })
}

export const inference = (data: any) => {
  return http.post<any>({
    url: '/infer/submit-task',
    data
  })
}

export const cacheLink = (link: string) => {
  return http.post<any>({
    url: '/infer/cache-link',
    data: {
      link: link
    }
  })
}

export const getCreations = async () => {
  try {
    // Step 1: Fetch all workflow IDs
    const workflowsResponse = await http.get< any[] >({
      url: '/user-data/creations',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    return workflowsResponse
  } catch (error) {
    console.error('Failed to fetch workflows:', error);
    throw error;
  }
}