import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useStore } from './store';
import { isLogined } from './api/login';
import './App.scss';
import Routes from '@/router/index';
import NewsModal from './components/news-modal/NewsModal';
import LoginModal from './components/login-modal/LoginModal';
import SignModal from './components/sign-modal/SignModal';
import UAModal from './components/ua-modal/UAModal';
import UpgradeModal from './components/upgrade-modal/UpgradeModal';
// import Footer from './components/footer/Footer';
// import { motion } from 'framer-motion';
import './App.scss';
// import styles from './App.module.scss';
// import NavigationRail from './components/sidebar/Sidebar';





function App() {
  const {appStore} = useStore()
  

  // const [isNavExpanded, setIsNavExpanded] = useState(false); // 控制左边栏展开/收起的状态

  useEffect(()=>{
    // if (navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1) {
    //   document.body.classList.add("is-safari");
    // }

    if (localStorage.getItem('access_token')) {
      isLogined().then(res=>{
        console.log(res)
        console.log(res.logged_in)
        if (res.logged_in){
          appStore.isLoggedIn = true
        }
      }).catch(e=>{
        console.log(e)
        appStore.isLoggedIn = false

      })
    }
    else {
      appStore.isLoggedIn = false
    }
    // isLogined().then(res=>{
    //   if (res.code === 200){
    //     appStore.isLoggedIn = true
    //   }
    // })
  }, [])


  return (
<GoogleOAuthProvider clientId="205971285604-7bvsbea8pa0ibcpqilo8ba39veh2ld9p.apps.googleusercontent.com">
<BrowserRouter>
{/* <div className={styles.container}> */}
    {/* 添加左边栏 */}
    {/* <NavigationRail onExpandChange= {setIsNavExpanded} /> */}
    {/* { console.log('Sidebar expanded:', isNavExpanded); // 打印 expanded 状态，测试通过 */}
    {/* <NavigationRail
          onExpandChange={(expanded) => {
            console.log('App - Sidebar expanded:', expanded); // 验证 expanded 是否打印
            setIsNavExpanded(expanded); // 更新状态
          }}
        /> */}


<div className="App">
        {/* <motion.div
          className={styles.content}
          // animate={{
          //   marginLeft: isNavExpanded ? '250px' : '0', // 动态调整左边距
          // }}
          // transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          // onAnimationStart={() => console.log('isNavExpanded at start:', isNavExpanded)}
          // onAnimationComplete={() => console.log('isNavExpanded after animation:', isNavExpanded)}
        > */}
          <Routes />
          <NewsModal />
          <LoginModal />
          <SignModal />
          <UAModal />
          <UpgradeModal />

        {/* </motion.div> */}
  </div>
</BrowserRouter>
</GoogleOAuthProvider>
);
}

export default App;
