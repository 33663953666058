import { DownOutlined, RightCircleFilled, RightOutlined } from "@ant-design/icons"
import { Button } from "antd"
import faqImg from '@/assets/images/faq.svg'
import './question.scss'
import { useState } from "react"

interface CollapseItem {
  title: string;
  content: string;
  collapsed: boolean;
}

const QuestionWrapper = () => {
  const [list, setList] = useState<CollapseItem[]>([
    { 
      title: 'How do I upload a song ?',
      content: 'You can upload an audio file from your local device or paste a YouTube link. Our tool supports various formats, including MP3, WAV, and more, ensuring compatibility.',
      collapsed: true
    },
    { 
      title: 'How long does the vocal removal process take?',
      content: 'It typically takes less than a minute. Our AI technology is optimized for fast processing without compromising output quality.',
      collapsed: false
    }
  ])

  const handleCollapsed = (index: number) => {
    setList(list.map((item, _index) => _index == index ? {...item, collapsed: !list[index].collapsed} : item));
  }

  return (
    <div className="questions-wrapper">
      <div className="title">
        <span>Frequently Asked Questions</span>
        <img src={faqImg}/>
      </div>
      {
        list.map((e, i) => {
          return (
            <div className={`collapse ${ e.collapsed ? 'collapsed' : '' }`}>
              <div className="top">{ e.title }</div>
              <div className="content">{ e.content }</div>
              {
                e.collapsed ? <DownOutlined className="collapse-icon" onClick={() => handleCollapsed(i)} /> : <RightOutlined className="collapse-icon" onClick={() => handleCollapsed(i)} />
              }
            </div>
          )
        })
      }
      <div className="footer">
        {/* <Button className="btn-more-questions" shape="round" icon={<RightCircleFilled style={{ color: '#fff' }} />}>
          More questions
        </Button> */}
      </div>
    </div>
  )
}

export default QuestionWrapper