import logoImg from '../../assets/images/footer-logo.svg'
import youtubeLogo from '@/assets/images/youtube-logo-1.svg'
import xLogo from '@/assets/images/x logo.svg'

import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';


const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.grid}>
          {/* Left Column */}
          <div>
            <div className={styles.logo}>
              <img src={logoImg} />
            </div>
            
            <div className={styles.contactSection}>
              <h3 className={styles.heading1}>Contact Us</h3>
              <p className={styles.description}>
                We'd love to hear from you! Contact us using the information below. 
                We'll get back to you as soon as possible.
              </p>
              <p className={styles.email}>
                <span className={styles.label}>E-mail:</span>
                <a href="mailto:support@remixly.ai" className={styles.emailLink}>
                  support@remixly.ai
                </a>
              </p>
            </div>
          </div>

          {/* Right Column */}
          <div>
            <h3 className={styles.heading2}>Quick Links</h3>
            <div className={styles.links}>
              <Link to="/" className={styles.link}>Home</Link>
              <Link to="/pricing" className={styles.link}>Pricing</Link>
              <Link to="/faq" className={styles.link}>FAQs</Link>
            </div>

            <div className={styles.social} style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
  <h3 className={styles.heading3} style={{ margin: 0 }}>Follow Us:</h3>
  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '20px' }}>
    <div className="item icon" onClick={() => { window.open('https://x.com/Peteryin0120', '_blank') }}>
      <img src={xLogo} width="25px" />
      {/* <XOutlined/> */}
    </div>
    <div className="item icon" onClick={() => { window.open('https://www.youtube.com/@Remixly_ai', '_blank') }}>
      <img src={youtubeLogo} width="25px" />
      {/* <YoutubeFilled/> */}
    </div>
  </div>
</div>

          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.bottomContent}>
            <p className={styles.copyright}>
              © {new Date().getFullYear()} Remixly AI. All rights reserved.
            </p>
            <div className={styles.bottomLinks}>
              <Link to="/privacy" className={styles.bottomLink}>Privacy</Link>
              <Link to="/cookies" className={styles.bottomLink}>Cookies</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;