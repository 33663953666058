import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { message } from "antd";
; import { apiBaseURL } from "@/enums/index";
import { HttpResponseCode } from "@/enums/httpCode";
import { Nullable } from "@/models";

export type HttpResponseData<T = any> = {
  code: HttpResponseCode;
  data: T,
  err: Nullable<string>;
  msg: string;
}

export const createHttp = (baseURL: apiBaseURL) => {
  const service = axios.create({
    baseURL,
    withCredentials: true,
    timeout: 10*60*1000,
  });

  service.interceptors.response.use(
    // @ts-expect-error
    (response: AxiosResponse<HttpResponseData>) => {
      const res = response;
      if ([HttpResponseCode.SUCCESS].includes(res.data.code)) {
        return res.data;
      } else if ([HttpResponseCode.NOT_AUTH].includes(res.data.code)) {
        localStorage.removeItem('hasLogin');
        message.warning('登陆已过期，请重新登陆!');
        if (window.location.hash !== '') {
          window.location.replace(`#/login/1`);
        } else {
          history.replaceState(null, '', '/login/1');
        }
        return res;
      } else {
        return res.data;
      }
    },
    error => {
      if (error && error.response && error.response.status) {
        switch (error.response.status) {
          case 500:
            message.error('服务器出错');
            break;
          case 404:
            message.error(`接口"${error.response.data.path}"不存在`);
            break;
          default:
            localStorage.removeItem('hasLogin');
            break;
        }
      } else if (error.message.includes('timeout')) {
        message.error('网络超时');
      } else {
        localStorage.removeItem('hasLogin');
      }
      return Promise.reject(error);
    },
  );
  return {
    get<T>(config: AxiosRequestConfig): Promise<T> {
      return service({
        ...config,
        method: 'GET',
        headers:{
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      });
    },
    post<T>(config: AxiosRequestConfig): Promise<T> {
      return service({
        ...config,
        method: 'POST',
        headers:{
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      });
    },
    put<T>(config: AxiosRequestConfig): Promise<T> {
      return service({
        ...config,
        method: 'PUT',
        headers:{
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      });
    },
    delete<T>(config: AxiosRequestConfig): Promise<T> {
      return service({
        ...config,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      });
    },
  };
}