// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookies_container__LnSaS {
  min-height: 100vh;
  background-color: #ECECEC;
  display: flex;
}

.cookies_content__PkCWa {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/pages/cookies/cookies.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,yBAAA;EACA,aAAA;AACJ;;AAEE;EACE,SAAA;EACA,aAAA;EACA,sBAAA;AACJ","sourcesContent":[".container {\n    min-height: 100vh;\n    background-color: #ECECEC;\n    display: flex;\n  }\n  \n  .content {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `cookies_container__LnSaS`,
	"content": `cookies_content__PkCWa`
};
export default ___CSS_LOADER_EXPORT___;
