// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_container__Q\\+zPO {
  min-height: 100vh;
  background-color: #ECECEC;
  display: flex;
}

.home_content__pu8Ee {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.home_mainContent__p6blG {
  flex: 1 1;
  padding: 1.5rem;
  overflow-y: auto;
}

.home_wrapper__zBK0N {
  width: 100%;
  max-width: 56rem;
  margin: 0 auto;
}

.home_header__Lyiw0 {
  text-align: center;
  margin-bottom: 2rem;
}

.home_iconWrapper__ZF1dA {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.home_icon__63us2 {
  width: 3rem;
  height: 3rem;
  color: #5DACFF;
}

.home_title__L-qc9 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #333333;
  font-size: 2.25rem;
  margin: 0 0 0.5rem;
}

.home_subtitle__uReZH {
  color: #A7A7A7;
  margin: 0;
}

.home_grid__nLuEO {
  display: grid;
  gap: 2rem;
}

.home_card__OSq2V {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
}
.home_cardTitle__9D-Bu {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #333333;
  font-size: 1.5rem;
  margin: 0 0 1rem;
}
.home_cardText__inRuQ {
  color: #A7A7A7;
  line-height: 1.625;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/index.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,yBAAA;EACA,aAAA;AACJ;;AAEE;EACE,SAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AAEE;EACE,SAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEE;EACE,WAAA;EACA,gBAAA;EACA,cAAA;AACJ;;AAEE;EACE,kBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,WAAA;EACA,YAAA;EACA,cAAA;AACJ;;AAEE;EACE,kCAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,cAAA;EACA,SAAA;AACJ;;AAEE;EACE,aAAA;EACA,SAAA;AACJ;;AAEE;EACE,uBAAA;EACA,eAAA;EACA,sBAAA;AACJ;AACI;EACE,kCAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AACN;AAEI;EACE,cAAA;EACA,kBAAA;EACA,SAAA;AAAN","sourcesContent":[".container {\n    min-height: 100vh;\n    background-color: #ECECEC;\n    display: flex;\n  }\n  \n  .content {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .mainContent {\n    flex: 1;\n    padding: 1.5rem;\n    overflow-y: auto;\n  }\n  \n  .wrapper {\n    width: 100%;\n    max-width: 56rem;\n    margin: 0 auto;\n  }\n  \n  .header {\n    text-align: center;\n    margin-bottom: 2rem;\n  }\n  \n  .iconWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 1rem;\n  }\n  \n  .icon {\n    width: 3rem;\n    height: 3rem;\n    color: #5DACFF;\n  }\n  \n  .title {\n    font-family: 'Poppins', sans-serif;\n    font-weight: 700;\n    color: #333333;\n    font-size: 2.25rem;\n    margin: 0 0 0.5rem;\n  }\n  \n  .subtitle {\n    color: #A7A7A7;\n    margin: 0;\n  }\n  \n  .grid {\n    display: grid;\n    gap: 2rem;\n  }\n  \n  .card {\n    background-color: white;\n    padding: 1.5rem;\n    border-radius: 0.75rem;\n  \n    &Title {\n      font-family: 'Poppins', sans-serif;\n      font-weight: 600;\n      color: #333333;\n      font-size: 1.5rem;\n      margin: 0 0 1rem;\n    }\n  \n    &Text {\n      color: #A7A7A7;\n      line-height: 1.625;\n      margin: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `home_container__Q+zPO`,
	"content": `home_content__pu8Ee`,
	"mainContent": `home_mainContent__p6blG`,
	"wrapper": `home_wrapper__zBK0N`,
	"header": `home_header__Lyiw0`,
	"iconWrapper": `home_iconWrapper__ZF1dA`,
	"icon": `home_icon__63us2`,
	"title": `home_title__L-qc9`,
	"subtitle": `home_subtitle__uReZH`,
	"grid": `home_grid__nLuEO`,
	"card": `home_card__OSq2V`,
	"cardTitle": `home_cardTitle__9D-Bu`,
	"cardText": `home_cardText__inRuQ`
};
export default ___CSS_LOADER_EXPORT___;
