import Header from "@/components/headerForWorkflow/Header"
import { observer } from "mobx-react-lite"
import './pricing.scss'
import { Flex } from "antd"
import { useState } from "react"
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from "@ant-design/icons"
import dunpaiImg from '@/assets/images/anquandunpai.svg'
import erjiImg from '@/assets/images/erji.svg'
import yingwenfuhaoImg from '@/assets/images/yingwenfuhao.svg'
import QuestionWrapper from "@/components/questions/Question"
import Footer from "@/components/footer/Footer"
import { useStore } from "@/store"
import { useNavigate } from "react-router-dom"

const TitleWrapper = () => {
  return (
    <div className="title-wrapper">
      <div className="title">Find Your Perfect Remixly Plan!</div>
      <div className="sub-title">
        Choose the plan that fits your needs! Whether you're a casual user or a professional,<br />
        we offer flexible pricing options to help you make the most of our AI-powered audio tools.
      </div>
    </div>
  )
}

const PricingOptionsWrapper = observer(() => {
  const { toolStore, appStore } = useStore();
  const navigate = useNavigate();
  
  // Ensure the default price model is 'Monthly'
  if (!toolStore.priceModel) {
    toolStore.priceModel = 'Monthly';
  }
  
  const [pricingOptions, setPricingOptions] = useState([
    { 
      name: 'Starter',
      desc: 'Limited use with essential features.',
      priceMonthly: 0,
      priceYearly: 0,
      percent: 'Monthly',
      funcList: [
        'Access to basic features',
        'Limited transformations per month',
        'Join our community on Discord',
        'Buy me a cup of coffee to get a little surprise'
      ],
      isPopular: false,
      btnName: 'Get Started',
      btnFunc: () => {
        if (appStore.isLoggedIn) {
          navigate('/');
        } else {
          appStore.loginModalVisible = true;
        }
      }
    },
    { 
      name: 'Professional',
      desc: 'Unlimited use and access to advanced features.',
      priceMonthly: 19.99,
      priceYearly: 179.99,
      percent: 'Monthly',
      funcList: [
        'Unlimited transformations',
        'Access to all AI tools',
        'Priority support',
        'Exclusive community access'
      ],
      isPopular: true,
      btnName: 'Choose Plan',
      stripeLinkMonthly: 'https://stripe.com/monthly-plan-professional', // Placeholder
      stripeLinkYearly: 'https://stripe.com/yearly-plan-professional', // Placeholder
      btnFunc: () => {
        const stripeLink = toolStore.priceModel === 'Monthly' ? 
          'https://stripe.com/monthly-plan-professional' : 
          'https://stripe.com/yearly-plan-professional';

        window.location.href = stripeLink;
      }
    },
    { 
      name: 'Studio',
      desc: 'Unlimited use with a discounted annual rate.',
      priceMonthly: 39.99,
      priceYearly: 289.99,
      percent: 'Monthly',
      funcList: [
        'Unlimited transformations',
        'Access to all AI tools',
        'Priority support',
        'Exclusive community access'
      ],
      isPopular: false,
      btnName: 'Choose Plan',
      stripeLinkMonthly: 'https://stripe.com/monthly-plan-studio', // Placeholder
      stripeLinkYearly: 'https://stripe.com/yearly-plan-studio', // Placeholder
      btnFunc: () => {
        const stripeLink = toolStore.priceModel === 'Monthly' ? 
          'https://stripe.com/monthly-plan-studio' : 
          'https://stripe.com/yearly-plan-studio';

        window.location.href = stripeLink;
      }
    }
  ]);

  const handleTogglePriceModel = (model: 'Monthly' | 'Yearly') => {
    toolStore.priceModel = model;
  };

  return (
    <Flex className="pricing-options-wrapper" vertical={true}>
      <Flex justify="space-between" align="center">
        <div className="left">
          Pricing Table Section
        </div>
        <div className="right">
          <div className="btn-group">
            <div
              className={`item ${toolStore.priceModel === 'Monthly' ? 'active' : ''}`}
              onClick={() => handleTogglePriceModel('Monthly')}
            >
              Monthly
            </div>
            <div
              className={`item ${toolStore.priceModel === 'Yearly' ? 'active' : ''}`}
              onClick={() => handleTogglePriceModel('Yearly')}
            >
              Yearly
            </div>
          </div>
        </div>
      </Flex>
      <div className="options">
        {
          pricingOptions.map((option, i) => (
            <div key={i} className={`option-item ${option.isPopular ? 'popular' : ''}`}>
              <div className="name">{ option.name }</div>
              <div className="desc">{ option.desc }</div>
              <div className="price-value">
                <span className="unit">$</span>
                <span className="value">{ toolStore.priceModel === 'Monthly' ? option.priceMonthly : option.priceYearly }</span>
                <span className="percent">/{toolStore.priceModel}</span>
              </div>
              <ul className="funcs">
                {
                  option.funcList.map(func => <li><CheckOutlined /><span className="comments">{ func }</span></li>)
                }
              </ul>
              <div className="btn" onClick={option.btnFunc}>{ option.btnName }</div>
            </div>
          ))
        }
      </div>
    </Flex>
  )
})

const BillingWrapper = () => {
  return (
    <div className="billing-options">
      <div className="item">
        <img src={dunpaiImg} />
        <span className="title">Secure</span>
        <span className="desc">We protect your privacy with<br />advanced encryption measures.</span>
      </div>
      <div className="item">
        <img src={erjiImg} />
        <span className="title">Support</span>
        <span className="desc">Get support from our advisors via live<br />chat or email within 24 hours.</span>
      </div>
      <div className="item">
        <img src={yingwenfuhaoImg} />
        <span className="title">Money-back Guarantee</span>
        <span className="desc">We offer a no-hassle 30-day money<br />back guarantee in all our products.</span>
      </div>
    </div>
  )
}

const ReadyWrapper = () => {
  const { appStore } = useStore();
  const navigate = useNavigate();
  return (
    <div className="ready-wrapper">
      <span className="title">Ready to Get Started ?</span>
      <span className="desc">
        Choose a plan today and start transforming your audio with our powerful AI tools.<br />
        Not sure which plan is right for you? Try our free plan and upgrade anytime as your needs grow.
      </span>
      <div className="btn" onClick={() => {
        if (appStore.isLoggedIn) {
          navigate('/');
        } else {
          appStore.loginModalVisible = true;
        }
      }}>Get Started</div>
    </div>
  )
}

const Pricing = () => {
  return (
    <div className="pricing-wrapper">
      <Header showBreadCrumb={true} />
      <TitleWrapper />
      <PricingOptionsWrapper />
      <span className="billing">Billing Information</span>
      <BillingWrapper />
      <ReadyWrapper />
      <QuestionWrapper />
      <Footer />
    </div>
  )
}

export default observer(Pricing)
