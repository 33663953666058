
export const validateLink = (link:string)=>{
    const youtube_pattern = new RegExp("(https?://)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)/")
    const suno_pattern = new RegExp('(https?://)?(www\.)?suno\.com/')
    if (youtube_pattern.test(link)) {
        return true
    }
    if (suno_pattern.test(link)) {
        return true
    }
    return false
}