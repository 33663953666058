import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import Footer from "@/components/footer/Footer"
import styles from './FAQ.module.scss';
import NavigationRail from "../../components/sidebar/Sidebar";

const FAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const faqs = [
    {
      question: "What is Remixly AI?",
      answer: "Remixly AI is a cutting-edge platform that helps creators and developers streamline their workflow using artificial intelligence."
    },
    {
      question: "How do I get started?",
      answer: "Simply sign up for an account, choose your subscription plan, and start exploring our tools and templates. Our intuitive interface makes it easy to begin creating right away."
    },
    {
      question: "What subscription plans do you offer?",
      answer: "We offer flexible plans tailored to different needs: Free (basic features), Pro (advanced features), and Enterprise (custom solutions). Visit our Pricing page for detailed information."
    },
    {
      question: "Can I try before subscribing?",
      answer: "Yes! We offer a free trial period that gives you access to all Pro features. No credit card required to start your trial."
    },
    {
      question: "How secure is my data?",
      answer: "We implement industry-standard security measures and encryption to protect your data. Your privacy and security are our top priorities."
    }
  ];

  const toggleFaq = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div className={styles.container}>
      <NavigationRail onExpandChange={setIsNavExpanded} />

      <motion.div 
        
        className={styles.content}
        animate={{
          marginLeft: isNavExpanded ? '10%' : '0',
        }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <h1 className={styles.title}>Frequently Asked Questions</h1>
        <div className={styles.faqList}>
          {faqs.map((faq, index) => (
            <motion.div 
              key={index}
              className={styles.faqItem}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              onClick={() => toggleFaq(index)}
            >
              <div className={styles.questionRow}>
                <h2 className={styles.question}>{faq.question}</h2>
                <motion.div 
                  className={styles.icon}
                  animate={{ rotate: openIndex === index ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ChevronDown size={20} />
                </motion.div>
              </div>
              <AnimatePresence>
                {openIndex === index && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className={styles.answerWrapper}
                  >
                    <p className={styles.answer}>{faq.answer}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
          
        </div>
        <Footer />
      </motion.div>
       
    </div>
    
  );
};

export default FAQ;