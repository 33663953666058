import React, { useEffect } from 'react';
import { Flex, Image, Button, Avatar, Popover } from 'antd';
import { MenuOutlined, RightCircleFilled } from '@ant-design/icons';
import './header.scss'
import logoImg from '@/assets/images/footer-logo.svg'
import { useNavigate } from 'react-router-dom';
import { useStore } from '@/store';
import { isLogined } from '@/api/login';
import { observer } from 'mobx-react-lite';
import Back from '@/assets/images/back.svg'





const menuIconStyle = {
  width: '40px',
  height: '36px',
  fontSize: '30px',
  color: '#848484',
  cursor: 'pointer'
}





const Header = (props: { showBreadCrumb?: boolean }) => {
  const navigate = useNavigate();
  const { toolStore } = useStore();
  const { appStore } = useStore();
  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      isLogined().then(res => {
        console.log(res)
        console.log(res.logged_in)
        if (res.logged_in) {
          appStore.isLoggedIn = true
          console.log(appStore.isLoggedIn)
        }
      })
    }
  }, [])
  useEffect(() => {
    console.log('login state changed')
    console.log(appStore.isLoggedIn)
  }, [appStore.isLoggedIn])

  const logout = () => {
    localStorage.clear()
    appStore.isLoggedIn = false
  }
  const content = (
    <ul className="user-info">
      <li onClick={()=>{window.open('/toolkit/my-creations', '_blank')}}>My creations</li>
      <li onClick={()=>{window.open('/toolkit/my-workflows', '_blank')}}>My workflows</li>
      {/* <li>Account setting</li> */}
      <li onClick={()=>{logout(); location.reload()}}>Log out</li>
    </ul>
  );

  return (
   
    <Flex className="layout-header" justify={props.showBreadCrumb ? 'space-between' : 'end'} align="center">
      {
        // props.showBreadCrumb
        //   ?
        //   <div className="left">
        //     {/* <MenuOutlined style={menuIconStyle} /> */}
        //     <img src={logoImg} onClick={() => { navigate('/') }} />
        //   </div>
        //   :
        props.showBreadCrumb
          ?
          <div className="left">
            <Button className="btn-back" type="primary" shape="round" onClick={() => {
        if (toolStore.curState === 1) {
          navigate('/')
        } else {
          toolStore.curState-=1
        }
      }}>
             <span><img src={Back} height={'25px'}/></span>Back
          </Button>
          </div>
          :
          <></>
      }
      {appStore.isLoggedIn ? <div className="right">
        <Popover placement="left" content={content} trigger="click">
          <Avatar>U</Avatar>
        </Popover>
      </div>
        :
        <div className="right">
          <Button className="btn-login" color="default" variant="link" onClick={() => appStore.loginModalVisible = true}>
            LOG IN
          </Button>
          <Button className="btn-signup" shape="round" icon={<RightCircleFilled style={{ color: '#57A3F3' }} />} onClick={() => appStore.signUpModalVisible = true}>
            SIGN UP
          </Button>
          <div>
            {appStore.isLoggedIn}
          </div>
        </div>}
    </Flex>
  

  )
}

export default observer(Header)