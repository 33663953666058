export const generateNewPosition = (nodes) => {
    if (nodes.length === 0) {
      return { x: 250, y: 250 }
    }
    let mostLeftNode = {
      position: {
        x: 0,
        y: 0
      },
      measured: {
        width: 0,
        height: 0
      }
    }
    nodes.forEach(node => {
      if (node.position.x > mostLeftNode.position?.x || !mostLeftNode.position) {
        mostLeftNode = node
      }
    })
    // console.log(nodes)
    // console.log(mostLeftNode)
    return { x: mostLeftNode.position.x + mostLeftNode.measured.width + 50, y: mostLeftNode.position.y }
  }