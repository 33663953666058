// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
}
body * {
  font-family: "Montserrat";
}

.ant-modal-root:has(.fullscreen) .ant-modal {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100vh !important;
  transform-origin: unset !important;
  margin: 0;
  padding: 0;
  top: 0;
}
.ant-modal-root:has(.fullscreen) .ant-modal > div {
  height: 100%;
}
.ant-modal-root:has(.fullscreen) .ant-modal > div > .ant-modal-content {
  height: 100%;
  border-radius: 0;
  background: linear-gradient(180deg, rgba(179, 178, 178, 0.93) 0%, rgba(142, 157, 175, 0.93) 139.26%);
}
.ant-modal-root:has(.fullscreen) .ant-modal-wrap {
  overflow-x: hidden;
}
.ant-modal-root:has(.voice-library) .ant-modal-content {
  height: max-content !important;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAOA;EACE,SAAA;EACA,UAAA;AANF;AAQE;EACE,yBAAA;AANJ;;AAcI;EACE,uBAAA;EACA,2BAAA;EACA,wBAAA;EACA,kCAAA;EACA,SAAA;EACA,UAAA;EACA,MAAA;AAXN;AAaM;EACE,YAAA;AAXR;AAaQ;EACE,YAAA;EACA,gBAAA;EACA,oGAAA;AAXV;AAgBI;EACE,kBAAA;AAdN;AAmBI;EACE,8BAAA;AAjBN","sourcesContent":["// @font-face {\n//   font-family: 'Montserrat';\n//   src: url('../src/assets/fonts/Montserrat/Montserrat-Regular.otf');\n// }\n\n\n\nbody {\n  margin: 0;\n  padding: 0;\n\n  * {\n    font-family: 'Montserrat';\n  }\n}\n\n\n\n.ant-modal-root {\n  &:has(.fullscreen) {\n    .ant-modal {\n      width: 100vw !important;\n      max-width: 100vw !important;\n      height: 100vh !important;\n      transform-origin: unset !important;\n      margin: 0;\n      padding: 0;\n      top: 0;\n\n      & > div {\n        height: 100%;\n\n        & > .ant-modal-content {\n          height: 100%;\n          border-radius: 0;\n          background: linear-gradient(180deg, rgba(179, 178, 178, 0.93) 0%, rgba(142, 157, 175, 0.93) 139.26%);\n        }\n      }\n    }\n\n    .ant-modal-wrap {\n      overflow-x: hidden;\n    }\n  }\n\n  &:has(.voice-library) {\n    .ant-modal-content {\n      height: max-content !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
