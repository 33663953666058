import logoImg from '../../assets/images/footer-logo.svg'
import { useNavigate } from 'react-router-dom';
import { useStore } from "@/store"

import React, { useState, useEffect } from 'react';
import { Menu, Layout } from 'antd';
import type { MenuProps } from 'antd';

import {
  ToolOutlined,
  AppstoreOutlined,
  ApartmentOutlined,
  ApiOutlined,
  FolderOutlined,
  FileOutlined,
  QuestionCircleOutlined,
  BulbOutlined,
  DollarOutlined,
  ProfileOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './sidebar.module.scss';

const { Sider } = Layout;

interface NavigationRailProps {
  onExpandChange?: (expanded: boolean) => void;
}

const NavigationRail: React.FC<NavigationRailProps> = ({ onExpandChange }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { toolStore, appStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log('NavigationRail - onExpandChange prop:', onExpandChange);
    // console.log('Collapsed state:', collapsed); // 打印 collapsed 状态, 确认通过
    // console.log('NavigationRail - onExpandChange called with:', !collapsed);
    onExpandChange?.(!collapsed);
  }, [collapsed, onExpandChange]);

  const menuItems: MenuProps['items'] = [
    {
      type: 'divider',
    },
    {
      key: 'toolkit',
      icon: <ToolOutlined />,
      label: 'Toolkit',
      onClick: () => navigate('/'),
    },
    {
      type: 'divider',
    },
    {
      key: 'workflow',
      icon: <ApartmentOutlined />,
      label: 'Workflow',
      children: [
        {
          key: 'create',
          icon: <AppstoreOutlined />,
          label: 'Create',
          onClick: () => navigate('/workflow'),
        },
        {
          key: 'templates',
          icon: <ProfileOutlined />,
          label: 'Templates',
          onClick: () => navigate('/toolkit/templates'),
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      key: 'mystuff',
      icon: <FolderOutlined />,
      label: 'My Stuff',
      children: [
        {
          key: 'creations',
          icon: <FileOutlined />,
          label: 'Creations',
          onClick: () => navigate('/toolkit/my-creations'),
        },
        {
          key: 'workflows',
          icon: <ApiOutlined />,
          label: 'Workflows',
          onClick: () => navigate('/toolkit/my-workflows'),
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      key: 'helpcenter',
      icon: <QuestionCircleOutlined />,
      label: 'Help Center',
      children: [
        {
          key: 'whatsnew',
          icon: <BulbOutlined />,
          label: "What's New",
          // onClick: () => navigate('news'),
          onClick: () => appStore.newsModalVisible = true,
          
        },
        {
          key: 'pricing',
          icon: <DollarOutlined />,
          label: 'Pricing',
          onClick: () => navigate('/pricing'),
        },
        {
          key: 'faq',
          icon: <QuestionCircleOutlined />,
          label: 'Faq',
          onClick: () => navigate('/Faq'),
        },
      ],
    },
  ];

  return (
    <AnimatePresence>
      {collapsed ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.collapsedNav}
        >
          <button 
            onClick={() => setCollapsed(false)}
            className={styles.menuButton}
          >
            <MenuUnfoldOutlined style={{ fontSize: '20px', color: '#5DACFF' }} />
          </button>

          <div className={styles.logoPlaceholder}>
            <div className={styles.logoContainer}>
              {collapsed ? (
                <img
              style={{ marginRight: '30px' }} src={logoImg}  width="150px" onClick={() => {  navigate('/'); }} />  ) : null}
            </div>
          </div>

          
        </motion.div>
      ) : (
        <motion.div
          initial={{ x: -280 }}
          animate={{ x: 0 }}
          exit={{ x: -280 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          className={styles.navContainer}
        >
          <Sider
            width={250}
            className={styles.sider}
          >
            <button
              onClick={() => setCollapsed(true)}
              className={styles.collapseButton}
            >
              <MenuFoldOutlined style={{ fontSize: '16px', color: '#5DACFF' }} />
            </button>

              {/* <button
                onClick={() => {
                  console.log('NavigationRail - Toggle button clicked');
                  setCollapsed((prev) => !prev);
                }}
                className={styles.collapseButton}
              >
                <MenuFoldOutlined style={{ fontSize: '16px', color: '#5DACFF' }} />
              </button> */}
            
            
            <div className={styles.logoContainer}>
            { !collapsed ? <img style={{marginRight: '30px'}} src={logoImg} width="150px" onClick={()=>{navigate('/')}} /> : null }
            </div>
          
            <div className={styles.menuContainer}>
              <Menu
                mode="inline"
                defaultSelectedKeys={['toolkit']}
                defaultOpenKeys={['toolkit']}
                items={menuItems}
                className={styles.menu}
              />
            </div>
          </Sider>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NavigationRail;