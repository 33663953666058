import { Avatar, Modal, Spin } from "antd"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import './my-workflows.scss'
import { fetchAllWorkflows } from '@/api/workflow'
import {deleteWorkflow} from '@/utils/api'
import { DeleteFilled } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import axios, { AxiosError } from 'axios';
import { useStore } from '@/store';

const MyWorkflows = () => {
  const [list, setList] = useState<any[]>([
    // { name: 'Project name', desc: 'Edited ~ ago' },
    // { name: 'Project name', desc: 'Edited ~ ago' },
    // { name: 'Project name', desc: 'Edited ~ ago' },
    // { name: 'Project name', desc: 'Edited ~ ago' },
    // { name: 'Project name', desc: 'Edited ~ ago' },
    // { name: 'Project name', desc: 'Edited ~ ago' }
  ])
  const { appStore } = useStore();
  const [loading, setLoading] = useState(true)
  const [delId, setDelId] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    const loadWorkflows = async () => {
      setLoading(true);
      try {
        const data = await fetchAllWorkflows();
        console.log(data)
        setList(data);
      } catch (err) {
        const axiosError = err as AxiosError;
        console.log("err!!!")
        console.log(axiosError.status)
        if (axiosError.status === 422) {
          console.log('422 is true')
          appStore.signUpModalVisible = true
        }
      } finally {
        setLoading(false);
      }
    };
    loadWorkflows();
  }, []);

  const [showDelModal, setShowDelModal] = useState(false)

  useEffect(() => {
    console.log(list.length)
  }, [list])

  const delFlow = () => {
    deleteWorkflow(delId)
  }


  return (
    <>
      <div className="my-workflows">
        <div className="title">My Workflows</div>
        {
          loading ?
            <div style={{ display: 'flex', justifyContent: 'center', margin: '70px auto',}}>
              <Spin size="large"/>
            </div>
            :
            list.length !== 0 ?
              <div className="list">
                {list.map(e => {
                  return (
                    <div className="item" >
                      <div className="poster" onClick={()=>{navigate(`/workflow?flow_id=${e.flow_id}`)}}></div>
                      <div className="footer">
                        <div className="left" onClick={()=>{navigate(`/workflow?flow_id=${e.flow_id}`)}}>
                          <span className="name">{e.flow_name}</span>
                          <span className="desc">{e.updated_time}</span>
                        </div>
                        <div className="right">
                          <DeleteFilled onClick={() => {setShowDelModal(true); setDelId(e.flow_id)}} style={{fontSize: '32px', color: 'red'}} />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              :
              <div>
                <div style={{ margin: '70px auto', fontSize: '30px', textAlign: 'center', color: '#3D3D3D' }}>You don't have a workflow yet. Go <a href="/workflow">create</a> one now.</div>
              </div>
        }
      </div>
      <Modal open={showDelModal} footer={null} closable={false} wrapClassName="fullscreen">
        <div className="del-confirm">
          <div className="title" style={{ paddingTop: 0, paddingBottom: '42px' }}>
            Are you sure you want to delete this work?  This action cannot be undone.
          </div>
          <div className="footer">
            <div className="btn-confirm" onClick={() => {setShowDelModal(false);delFlow()}}>
              <span>Confirm</span>
            </div>
            <div className="btn-cancel" onClick={() => setShowDelModal(false)}>
              <span>Cancel</span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default observer(MyWorkflows)