import { action, makeAutoObservable } from 'mobx'

class ToolStore {
  priceModelList = ['Monthly', 'Yearly']
  priceModel = 'Monthly'
  pricingModalVisible = false
  fileList: any[] = []
  fileUrlList: any[] = []
  curSongName = ''
  outputUrl:string | Array<string> = ''
  separatorParams = {
    trackList : ['Accompaniment', 'Vocal'],
    track : 'Accompaniment',
    outputFormatList : ['mp3', 'wav', 'flac'],
    outputFormat : 'mp3'
  }
  deechoParams = {
    outputFormatList : ['mp3', 'wav', 'flac'],
    outputFormat : 'mp3'
  }
  dereverbParams = {
    outputFormatList : ['mp3', 'wav', 'flac'],
    outputFormat : 'mp3'
  }
  speedChangeParams = {
    outputFormatList : ['mp3', 'wav', 'flac'],
    outputFormat : 'mp3',
    targetSpeed: 1.5,
    speedStep: 0.1,
    minSpeed: 0.1,
    maxSpeed: 3
  }
  pitchShiftParams = {
    outputFormatList : ['mp3', 'wav', 'flac'],
    outputFormat : 'mp3',
    targetPitch: 1,
    pitchStep: 1,
    minPitch: -12,
    maxPitch: 12
  }
  songCoverParams = {
    outputFormatList : ['mp3', 'wav', 'flac'],
    outputFormat : 'mp3',
    modelId: ''
  }
  downloadBlob = ''
  pageId = ''
  curState = 1    // 1: upload, 2: processing, 3: download
  constructor() {
    makeAutoObservable(this)
  }
  reset() {
    this.priceModel = 'Monthly'
    this.pricingModalVisible = false
    this.fileList = []
    this.fileUrlList = []
    this.curSongName = ''
    this.outputUrl = ''
    this.separatorParams = {
      trackList : ['Accompaniment', 'Vocal'],
      track : 'Accompaniment',
      outputFormatList : ['mp3', 'wav', 'flac'],
      outputFormat : 'mp3'
    }
    this.pageId = ''
    this.curState = 1
    this.separatorParams = {
      trackList : ['Accompaniment', 'Vocal'],
      track : 'Accompaniment',
      outputFormatList : ['mp3', 'wav', 'flac'],
      outputFormat : 'mp3'
    }
    this.deechoParams = {
      outputFormatList : ['mp3', 'wav', 'flac'],
      outputFormat : 'mp3'
    }
    this.dereverbParams = {
      outputFormatList : ['mp3', 'wav', 'flac'],
      outputFormat : 'mp3'
    }
    this.speedChangeParams = {
      outputFormatList : ['mp3', 'wav', 'flac'],
      outputFormat : 'mp3',
      targetSpeed: 1.5,
      speedStep: 0.1,
      minSpeed: 0.1,
      maxSpeed: 3
    }
    this.pitchShiftParams = {
      outputFormatList : ['mp3', 'wav', 'flac'],
      outputFormat : 'mp3',
      targetPitch: 1,
      pitchStep: 1,
      minPitch: -12,
      maxPitch: 12
    }
    this.songCoverParams = {
      outputFormatList : ['mp3', 'wav', 'flac'],
      outputFormat : 'mp3',
      modelId: ''
    }
    this.downloadBlob = ''
  }
}

const app = new ToolStore()
export default app