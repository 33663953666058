import { apiBaseURL } from "@/enums";
import { createHttp } from "@/utils/http";

const http = createHttp(process.env.NODE_ENV === 'production' ? apiBaseURL.PROD : apiBaseURL.DEFAULT)

export interface CheckoutReq {
  email: string;
  password: string;
}

export const checkout = (data: CheckoutReq) => {
  return http.post<any>({
    url: '/back/login/api/checkout',
    data
  })
}

export const nextAuth = (email: string) => {
  return http.post<any>({
    url: '/back/register/google',
    data: {
      email
    }
  })
}

export const googleLogin = (googleToken: string) => {
  return http.post<any>({
    url: '/register/google',
    data: {
      googleToken
    }
  })
}

export const isLogined = () => {
  return http.get<any>({
    url: '/login/api/is_login',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    }
  })
}