import { apiBaseURL } from "@/enums";
import { createHttp } from "@/utils/http";

const http = createHttp(process.env.NODE_ENV === 'production' ? apiBaseURL.PROD : apiBaseURL.DEFAULT)

export async function fetchAllWorkflows() {
    try {
        // Step 1: Fetch all workflow IDs
        const workflowsResponse = await http.get<{ workflow_ids: string[] }>({
            url: '/user-data/workflows',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
        });
        console.log("wf res")
        console.log(workflowsResponse)
        const workflowIds = workflowsResponse.workflow_ids;
        console.log(workflowIds)
        if (workflowIds.length === 0) {
            return []
        }

        // Step 2: Fetch details for each workflow by ID
        const workflowDetailsPromises = workflowIds.map(id =>
            http.get<{ update_time: string }>({
                url: `/flow/${id}`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`
                }
            })
        );

        // Wait for all requests to complete
        const workflowsDetailsResponses = await Promise.all(workflowDetailsPromises);
        const workflowsDetails = workflowsDetailsResponses

        // Step 3: Sort workflows by `update_time` in descending order
        workflowsDetails.sort((a, b) => new Date(b.update_time).getTime() - new Date(a.update_time).getTime());

        return workflowsDetails;
    } catch (error) {
        console.error('Failed to fetch workflows:', error);
        throw error;
    }
}


export const deleteWorkflow = async (flow_id: string) => {
    const res = await http.delete({
        url: `/flow/${flow_id}`,
        headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }})
    return res
}