import axios from 'axios'

let baseURL
baseURL = 'https://remixly.ai/back'
// baseURL = 'http://127.0.0.1:5000/back'
// if( process.env.NODE_ENV === 'production' ) {
//     baseURL = 'https://remixly.ai/back'
// } else {
//     baseURL = 'https://remixly.ai/api'
// }

// 拦截器
axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    return Promise.reject(error)
})
axios.interceptors.request.use((config) => {
    console.log(config)
    config.headers['Accept'] = 'application/json'
    config.baseURL = baseURL
    config.timeout = 1000000
    return config;
}, (error) => {
    return Promise.reject(error)
})

// axios的get请求
export function getAxios({
    url,
    params={},
    headers={}
}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params,
            headers:{...headers, Authorization: `Bearer ${localStorage.getItem('access_token')}`}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            // console.log(err, '1')
            // reject(err)
            return null

        })
    })
}

// axios的post请求
export function postAxios({
    url,
    data,
    headers
}) {
    console.log(headers)
    return new Promise((resolve, reject) => {
        axios({
            url,
            method: 'post',
            data,
            headers:{...headers, Authorization: `Bearer ${localStorage.getItem('access_token')}`}
        }).then(res => {
            console.log(res)
            resolve(res.data)
        }).catch(err => {
            console.log(err)
            // reject(err)
            reject(err)
        })
    })
}

export function putAxios({
    url,
    data,
    headers
}) {
    console.log(headers)
    return new Promise((resolve, reject) => {
        axios({
            url,
            method: 'put',
            data,
            headers:{...headers, Authorization: `Bearer ${localStorage.getItem('access_token')}`}

        }).then(res => {
            console.log(res)
            resolve(res.data)
        }).catch(err => {
            console.log(err)
            // reject(err)
            reject(err)
        })
    })
}

export function deleteAxios({
    url,
    data,
    headers
}) {
    console.log(headers)
    return new Promise((resolve, reject) => {
        axios({
            url,
            method: 'delete',
            data,
            headers:{...headers, Authorization: `Bearer ${localStorage.getItem('access_token')}`}
        }).then(res => {
            console.log(res)
            resolve(res.data)
        }).catch(err => {
            console.log(err)
            // reject(err)
            reject(err)
        })
    })
}


export default axios