import { useStore } from "@/store"
import { Avatar, Button, Checkbox, Form, Input, Modal } from "antd"
import './ua-modal.scss'
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"

const UAModal = () => {
  const { appStore, loginStore } = useStore()
  const [showUA, setShowUA] = useState(false)
  useEffect(()=>{
    // 使用示例
    if (isMobileDevice()) {
      setShowUA(true)
    } else {
      setShowUA(false)
      
    }
  }, [])
  function isMobileDevice() {
    const userAgent = navigator.userAgent
    // 检测常见的移动端关键字
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }

  return (
    <Modal open={showUA} footer={null} closable={false} wrapClassName="fullscreen">
      <div className="check-valid">
        <div className="title">
          To Ensure The Best Experience,<br/>
          We Recommend Accessing <br/>
          Via The Web Version.
        </div>
        <div className="footer">
          <div className="btn-upgrade">
            <span onClick={()=>{setShowUA(false)}}>Close</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(UAModal)