// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.validationflow .react-flow__handle.connectingto {
    background: #ff6060;
}
  
.drawer_div_1 {
    margin-left: 10\\%;
    margin-right: 10\\%;
    width: 80\\%;
}

.drawer_div_1_span_1 {
    color: #57A3F3;
    
}

/* 全局滚动条样式 */
::-webkit-scrollbar {
    width: 0.41667vw; /* 滚动条宽度 */
    margin-right: 0.15625vw;
  }
  
::-webkit-scrollbar-thumb {
    background-color: #DBDBDB; /* 滚动条颜色 */
    border-radius: 0.20833vw; /* 滚动条圆角 */
    box-shadow: 0.41667vw 0 0 #A5ADB7 inset; /* 使用内阴影模拟间隙 */

}


::-webkit-scrollbar-track {
    background-color: transparent; /* 滚动条轨道颜色 */
}

.leftDrawer::-webkit-scrollbar-corner {
    background-color: transparent; /* 确保角落是透明的 */
}

::-webkit-scrollbar {
    display: none;
}

.react-flow__edge path{
    stroke: #C3E0FC;
    stroke-width: 4;
}

.react-flow__edge.selected path{
    --xy-edge-stroke-selected-default: #57A3F3;
    --xy-edge-stroke-selected: #57A3F3;
    stroke: #57A3F3 !important;
}

.MuiPopover-paper {
    /* box-shadow: none !important; */
    border-radius: 8pt !important;
    /* border: 2px solid #57A3F3 !important; */
}


`, "",{"version":3,"sources":["webpack://./src/pages/workflow/index.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,cAAc;;AAElB;;AAEA,YAAY;AACZ;IACI,gBAAU,EAAE,UAAU;IACtB,uBAAiB;EACnB;;AAEF;IACI,yBAAyB,EAAE,UAAU;IACrC,wBAAkB,EAAE,UAAU;IAC9B,uCAAiC,EAAE,cAAc;;AAErD;;;AAGA;IACI,6BAA6B,EAAE,YAAY;AAC/C;;AAEA;IACI,6BAA6B,EAAE,aAAa;AAChD;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,0CAA0C;IAC1C,kCAAkC;IAClC,0BAA0B;AAC9B;;AAEA;IACI,iCAAiC;IACjC,6BAA6B;IAC7B,0CAA0C;AAC9C","sourcesContent":[".validationflow .react-flow__handle.connectingto {\n    background: #ff6060;\n}\n  \n.drawer_div_1 {\n    margin-left: 10\\%;\n    margin-right: 10\\%;\n    width: 80\\%;\n}\n\n.drawer_div_1_span_1 {\n    color: #57A3F3;\n    \n}\n\n/* 全局滚动条样式 */\n::-webkit-scrollbar {\n    width: 8px; /* 滚动条宽度 */\n    margin-right: 3px;\n  }\n  \n::-webkit-scrollbar-thumb {\n    background-color: #DBDBDB; /* 滚动条颜色 */\n    border-radius: 4px; /* 滚动条圆角 */\n    box-shadow: 8px 0 0 #A5ADB7 inset; /* 使用内阴影模拟间隙 */\n\n}\n\n\n::-webkit-scrollbar-track {\n    background-color: transparent; /* 滚动条轨道颜色 */\n}\n\n.leftDrawer::-webkit-scrollbar-corner {\n    background-color: transparent; /* 确保角落是透明的 */\n}\n\n::-webkit-scrollbar {\n    display: none;\n}\n\n.react-flow__edge path{\n    stroke: #C3E0FC;\n    stroke-width: 4;\n}\n\n.react-flow__edge.selected path{\n    --xy-edge-stroke-selected-default: #57A3F3;\n    --xy-edge-stroke-selected: #57A3F3;\n    stroke: #57A3F3 !important;\n}\n\n.MuiPopover-paper {\n    /* box-shadow: none !important; */\n    border-radius: 8pt !important;\n    /* border: 2px solid #57A3F3 !important; */\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
