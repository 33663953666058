// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolkit_container__HN1wF {
  min-height: 100vh;
  background-color: #ECECEC;
  display: flex;
}

.toolkit_content__T7KbA {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.toolkit_mainContent__769-y {
  flex: 1 1;
  padding: 1.5rem;
  overflow-y: auto;
}

.toolkit_wrapper__EX0wD {
  width: 100%;
  max-width: 56rem;
  margin: 0 auto;
}

.toolkit_header__ucXkW {
  text-align: center;
  margin-bottom: 2rem;
}

.toolkit_iconWrapper__N5yaP {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.toolkit_icon__hciUS {
  width: 3rem;
  height: 3rem;
  color: #5DACFF;
}

.toolkit_title__GM\\+tu {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #333333;
  font-size: 2.25rem;
  margin: 0 0 0.5rem;
}

.toolkit_subtitle__JoI8Z {
  color: #A7A7A7;
  margin: 0;
}

.toolkit_grid__v3je8 {
  display: grid;
  gap: 2rem;
}

.toolkit_card__eP2A2 {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
}
.toolkit_cardTitle__S0wh7 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #333333;
  font-size: 1.5rem;
  margin: 0 0 1rem;
}
.toolkit_cardText__XP5JC {
  color: #A7A7A7;
  line-height: 1.625;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/toolkit/index.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,yBAAA;EACA,aAAA;AACJ;;AAEE;EACE,SAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AAEE;EACE,SAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEE;EACE,WAAA;EACA,gBAAA;EACA,cAAA;AACJ;;AAEE;EACE,kBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,WAAA;EACA,YAAA;EACA,cAAA;AACJ;;AAEE;EACE,kCAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,cAAA;EACA,SAAA;AACJ;;AAEE;EACE,aAAA;EACA,SAAA;AACJ;;AAEE;EACE,uBAAA;EACA,eAAA;EACA,sBAAA;AACJ;AACI;EACE,kCAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AACN;AAEI;EACE,cAAA;EACA,kBAAA;EACA,SAAA;AAAN","sourcesContent":[".container {\n    min-height: 100vh;\n    background-color: #ECECEC;\n    display: flex;\n  }\n  \n  .content {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .mainContent {\n    flex: 1;\n    padding: 1.5rem;\n    overflow-y: auto;\n  }\n  \n  .wrapper {\n    width: 100%;\n    max-width: 56rem;\n    margin: 0 auto;\n  }\n  \n  .header {\n    text-align: center;\n    margin-bottom: 2rem;\n  }\n  \n  .iconWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 1rem;\n  }\n  \n  .icon {\n    width: 3rem;\n    height: 3rem;\n    color: #5DACFF;\n  }\n  \n  .title {\n    font-family: 'Poppins', sans-serif;\n    font-weight: 700;\n    color: #333333;\n    font-size: 2.25rem;\n    margin: 0 0 0.5rem;\n  }\n  \n  .subtitle {\n    color: #A7A7A7;\n    margin: 0;\n  }\n  \n  .grid {\n    display: grid;\n    gap: 2rem;\n  }\n  \n  .card {\n    background-color: white;\n    padding: 1.5rem;\n    border-radius: 0.75rem;\n  \n    &Title {\n      font-family: 'Poppins', sans-serif;\n      font-weight: 600;\n      color: #333333;\n      font-size: 1.5rem;\n      margin: 0 0 1rem;\n    }\n  \n    &Text {\n      color: #A7A7A7;\n      line-height: 1.625;\n      margin: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `toolkit_container__HN1wF`,
	"content": `toolkit_content__T7KbA`,
	"mainContent": `toolkit_mainContent__769-y`,
	"wrapper": `toolkit_wrapper__EX0wD`,
	"header": `toolkit_header__ucXkW`,
	"iconWrapper": `toolkit_iconWrapper__N5yaP`,
	"icon": `toolkit_icon__hciUS`,
	"title": `toolkit_title__GM+tu`,
	"subtitle": `toolkit_subtitle__JoI8Z`,
	"grid": `toolkit_grid__v3je8`,
	"card": `toolkit_card__eP2A2`,
	"cardTitle": `toolkit_cardTitle__S0wh7`,
	"cardText": `toolkit_cardText__XP5JC`
};
export default ___CSS_LOADER_EXPORT___;
