import Footer from "@/components/footer/Footer"
import { Article } from "@/models"
import { useState } from "react"
import './privacy.scss'
import { motion } from 'framer-motion';
import NavigationRail from "../../components/sidebar/Sidebar";
import styles from './privacy.module.scss';

const Privacy = () => {
  const [contents, setContents] = useState<Article[]>([
    { 
      title: 'Information We Collect',
      content: 'During your use of Remixly.ai, we may collect the following categories of information:',
      list: [
        { 
          title: 'Personal Information',
          content: 'Information you provide when registering an account, such as your name, email address, and username. We collect this information to create and manage your account.'
        },
        {
          title: 'Audio Files and Generated Content',
          content: 'When you upload audio files or use our AI music features, we process the audio data you provide. This data is only used as necessary to provide the services, and we will not store it long-term unless explicitly authorized by you.'
        },
        {
          title: 'Usage Data',
          content: 'To enhance user experience, we collect technical data related to your use of Remixly.ai, including but not limited to your IP address, browser type, operating system, and device information. This data is primarily used to analyze user interactions and improve platform performance.'
        },
        {
          title: 'Feedback and Communications',
          content: 'When you communicate with us via email or our online support system, we may retain the content of your communication to assist in resolving issues and improving our services.'
        }
      ]
    },
    {
      title: 'How We Use Your Information',
      content: 'The information we collect is primarily used for the following purposes:',
      list: [
        {
          title: 'Providing Services',
          content: 'Processing your requests and providing personalized music and audio services, including audio uploads and AI-generated music.',
        },
        {
          title: 'Improving Platform Experience',
          content: 'We use technical data to analyze platform usage and optimize the services and performance of the platform.',
        },
        {
          title: 'Customer Support',
          content: 'We use your contact information and feedback to address your inquiries or support needs when you require technical assistance.',
        },
        {
          title: 'Legal Compliance',
          content: 'We may use or share your information to comply with legal requirements or respond to legitimate requests from law enforcement authorities.',
        }
      ]
    },
    {
      title: 'Sharing Your Information',
      content: 'We do not sell your personal information to third parties, but we may share your data in the following situations:',
      list: [
        {
          title: 'Service Providers',
          content: 'We may share information with trusted third-party service providers (such as cloud storage providers, AI technology partners) to help us provide, maintain, and optimize Remixly.ai services.',
        },
        {
          title: 'Legal Requirements',
          content: 'When necessary, we may share data with law enforcement or other government agencies in response to legal obligations or lawful requests.',
        },
        {
          title: 'Business Transfers',
          content: 'In the event of a merger, acquisition, or other business transfer involving Remixly.ai, your information may be transferred to the new entity as part of the transaction.',
        }
      ]
    },
    {
      title: 'Data Storage and Security',
      content: 'We implement strict technical and organizational measures to ensure the security of your personal information. We use encryption technologies to protect your data during transmission and storage.',
      list: [
        {
          title: 'Data Encryption',
          content: 'All of your personal information and audio data are processed using industry-standard encryption technologies to prevent unauthorized access.',
        },
        {
          title: 'Data Retention',
          content: 'We retain your data only for as long as necessary to fulfill legitimate business purposes. Audio data will be stored or deleted according to your preferences.',
        },
        {
          title: 'Security Incident Response',
          content: 'While we have implemented numerous security measures, we cannot guarantee absolute security. In the event of a security breach, we will promptly notify affected users and take necessary remedial actions.',
        }
      ]
    },
    {
      title: 'Your Rights',
      content: (
        <div>
          <span>You have the right to access, modify, or delete your personal information. If you wish to exercise these rights or have any questions about our privacy policy, please contact us at:</span>
          <a>support@remixly.ai</a>
          <span>You can also opt out of certain data collection or service usage (such as unsubscribing from marketing emails). Please note that in some cases, refusing to provide necessary information may impact your ability to use certain features of Remixly.ai.</span>
        </div>
      ) as JSX.Element
    },
    {
      title: 'Children\'s Privacy',
      content: 'Remixly.ai services are not intended for users under the age of 18. We do not knowingly collect personal information from minors. If we discover that a user under 18 has submitted personal information, we will take reasonable steps to delete such information promptly. If you are a parent or guardian and believe your child has provided personal information, please contact us immediately.'
    },
    {
      title: 'Policy Updates',
      content: 'We may update this privacy policy from time to time. All changes will be posted on this page, along with the latest update date. We encourage you to review this page regularly to stay informed about the latest updates to our privacy practices.'
    },
    {
      title: 'Contact Us',
      content: (
        <div>
          <span>If you have any questions regarding this privacy policy or the privacy measures implemented by Remixly.ai, feel free to contact us via the following:</span>
          <span>Email: <a>support@remixly.ai</a></span>
        </div>
      )
    },
    {
      title: 'Cookies and Tracking Technologies',
      content: 'We use cookies and similar tracking technologies to enhance user experience, analyze usage trends, and provide personalized services. Here are the details:',
      list: [
        {
          title: 'What are Cookies?',
          content: 'Cookies are small text files stored on your device to save preferences, login status, and session data. We use these cookies to ensure that you can use Remixly.ai smoothly.',
        },
        {
          title: 'Types of Cookies We Use',
          content: (
            <div>
              <span>Essential Cookies: These cookies are necessary for you to access and use the core features of Remixly.ai, such as account login and navigation.</span><br />
              <span>Performance Cookies: These cookies collect information on how users interact with our website, helping us improve platform performance.</span><br />
              <span>Functional Cookies: These cookies remember your preferences, such as language selection, to help provide a personalized experience.</span><br />
            </div>
          ),
        },
        {
          title: 'How to Manage Cookies?',
          content: 'You can control and manage cookies through your browser settings. Most browsers allow you to reject or delete cookies, but please note that doing so may affect your experience on Remixly.ai.',
        }
      ]
    },
    {
      title: 'International Data Transfers',
      content: 'Remixly.ai may process your data across different countries/regions worldwide. In such cases, we will ensure that your personal information is always afforded protections equivalent to those in your home country. These measures include, but are not limited to, the following:',
      list: [
        {
          title: 'Necessity of International Transfers',
          content: 'To provide services to users globally, we may need to transfer data to servers or partner networks located outside your home country.',
        },
        {
          title: 'Data Protection Agreements',
          content: 'We implement appropriate data protection measures, such as signing Data Protection Agreements (DPAs), to ensure that data transfers comply with applicable privacy regulations, such as the General Data Protection Regulation (GDPR).',
        }
      ]
    },
    {
      title: 'Third-Party Services and Links',
      content: 'Remixly.ai may contain links to third-party websites or services. The privacy policies and data protection measures of these third-party services are beyond the control of Remixly.ai, and you should review their policies separately.',
      list: [
        {
          title: 'Third-Party Services',
          content: 'When you access third-party services through Remixly.ai (e.g., services for payment, data storage, or social media sharing), we are not responsible for the privacy practices of those services.',
        },
        {
          title: 'External Links',
          content: 'Our website may include links to external websites. Please note that the privacy policies of these websites may differ from ours, and we encourage you to carefully review their privacy policies before use.',
        }
      ]
    },
    {
      title: 'Legal Basis for Data Processing',
      content: 'Depending on the applicable law, Remixly.ai may process your personal information based on the following legal grounds:',
      list: [
        {
          title: 'Performance of a Contract',
          content: 'We process your data to fulfill our contractual obligations to you, such as providing personalized music and audio services.',
        },
        {
          title: 'Legal Obligation',
          content: 'In situations where we are required by law, we process and share your personal information to comply with applicable laws and regulations.',
        },
        {
          title: 'Legitimate Interests',
          content: 'In some cases, we process your data to pursue our legitimate business interests, such as improving services, marketing communications, etc., but we will ensure these interests do not override your privacy rights.',
        },
        {
          title: 'Consent',
          content: 'In certain circumstances, we will seek your explicit consent before processing your data, and you may withdraw your consent at any time.',
        }
      ]
    },
  ])

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div className={styles.container}>
      <NavigationRail onExpandChange={setIsNavExpanded} />
    
      <motion.div 
        className={styles.content}
        animate={{
          marginLeft: isNavExpanded ? '15%' : '0',
        }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >


    <div className="privacy-wrapper">
      <div className="title">Privacy Policy</div>
      <div className="last-updated">Last Updated: 9/10/2024</div>
      <div className="msg">We value your privacy and are committed to protecting the information you provide while using Remixly.ai. This privacy policy explains how we collect, use, share, and protect your information, and describes your privacy rights and how you can exercise them.</div>
      <div className="contents">
        {
          contents.map((e, i) => {
            return (
              <div className="part">
                <h3>{ i + 1 }.{ e.title }</h3>
                <span className="part-desc">{ e.content }</span>
                {
                    e.list && e.list.length > 0 
                  ?
                    <ul className="part-list">
                      {
                        e.list.map((item, index) => {
                          return (
                            <li className="part-item">
                              <span className="no">{ i + 1 }.{ index + 1 } { item.title }</span>
                              <span className="msg">{ item.content }</span>
                            </li>
                          )
                        })
                      }
                    </ul>
                  : 
                    <></>
                }
              </div>
            )
          })
        }
      </div>
      <Footer />
    </div>
    </motion.div>
    </div>
  )
}

export default Privacy