import { useStore } from "@/store";
import { CheckOutlined, CloseOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Modal } from "antd";
import { observer } from "mobx-react-lite";
import './pricing-modal.scss';
import { useNavigate } from 'react-router-dom';

// 定义 PricingOption 类型，描述每个定价选项的结构
interface PricingOption {
  name: string;
  desc: string;
  price: number;
  percent: string;
  funcList: string[];
  isPopular: boolean;
  btnName: string;
  stripeLinkMonthly?: string;
  stripeLinkYearly?: string;
}

const PricingModal = () => {
  const { toolStore } = useStore();
  const navigate = useNavigate();

  const onDetails = () => {
    navigate('/pricing');
  };

  const onPlanClick = (plan: PricingOption) => {
    if (plan.name === 'Starter') {
      // Starter plan always redirects to the homepage
      navigate('/');
    } else if (plan.name === 'Professional' || plan.name === 'Studio') {
      // Based on the price model, redirect to the respective Stripe link
      const stripeLink = toolStore.priceModel === 'Monthly'
        ? plan.stripeLinkMonthly
        : plan.stripeLinkYearly;
      
      // 添加类型检查，确保 stripeLink 不是 undefined
      if (!stripeLink) {
        console.error('Stripe link is missing for the selected plan.');
        return; // 或者你可以提供一个默认的链接
      }
  
      window.location.href = stripeLink; // 跳转到 Stripe 结算页面
    }
  };
  

  // 为每个计划增加stripeLink，分别用于 Monthly 和 Yearly
  const pricingOptions: PricingOption[] = [ // 显式声明类型为 PricingOption[]
    { 
      name: 'Starter',
      desc: 'Limited use with essential features.',
      price: toolStore.priceModel === 'Monthly' ? 0 : 0,
      percent: toolStore.priceModel,
      funcList: [
        'Access to basic features',
        'Limited transformations per month',
        'Join our community on Discord',
        'Buy me a cup of coffee to get a little surprise'
      ],
      isPopular: false,
      btnName: 'Get Started',
    },
    { 
      name: 'Professional',
      desc: 'Unlimited use and access to advanced features.',
      price: toolStore.priceModel === 'Monthly' ? 19.99 : 179.99,
      percent: toolStore.priceModel,
      funcList: [
        'Unlimited transformations',
        'Access to all AI tools',
        'Priority support',
        'Exclusive community access'
      ],
      isPopular: true,
      btnName: 'Choose Plan',
      stripeLinkMonthly: 'https://stripe.com/monthly-plan-professional',  // Monthly Plan Stripe Link
      stripeLinkYearly: 'https://stripe.com/yearly-plan-professional',  // Yearly Plan Stripe Link
    },
    { 
      name: 'Studio',
      desc: 'Unlimited use with a discounted annual rate.',
      price: toolStore.priceModel === 'Monthly' ? 39.99 : 289.99,
      percent: toolStore.priceModel,
      funcList: [
        'Unlimited transformations',
        'Access to all AI tools',
        'Priority support',
        'Exclusive community access'
      ],
      isPopular: false,
      btnName: 'Choose Plan',
      stripeLinkMonthly: 'https://stripe.com/monthly-plan-studio', // Monthly Plan Stripe Link
      stripeLinkYearly: 'https://stripe.com/yearly-plan-studio', // Yearly Plan Stripe Link
    }
  ];

  return (
    <Modal open={toolStore.pricingModalVisible} footer={null} closable={false} wrapClassName="fullscreen">
      <div className="pricing-modal">
        <CloseOutlined className="icon-close" onClick={() => toolStore.pricingModalVisible = false} />
        <Flex justify="space-between" align="center">
          <span className="title">Sorry, your audio exceeds the limit. Upgrade to unlock more features:</span>
          <div className="btn-group">
            {
              toolStore.priceModelList.map(e => 
                <div 
                  className={`item ${e === toolStore.priceModel && 'active'}`} 
                  onClick={() => { toolStore.priceModel = e; }}
                >
                  {e}
                </div>
              )
            }
          </div>
        </Flex>
        <div className="options">
          {
            pricingOptions.map((option, i) => (
              <div key={i} className={`option-item ${option.isPopular && 'popular'}`}>
                <div className="name">{option.name}</div>
                <div className="desc">{option.desc}</div>
                <div className="price-value">
                  <span className="unit">$</span>
                  <span className="value">{option.price}</span>
                  <span className="percent">/{toolStore.priceModel}</span>
                </div>
                <ul className="funcs">
                  {
                    option.funcList.map(func => 
                      <li key={func}><CheckOutlined /><span className="comments">{func}</span></li>
                    )
                  }
                </ul>
                <div 
                  className="btn" 
                  onClick={() => onPlanClick(option)} // 按钮点击时调用 onPlanClick
                >
                  {option.btnName}
                </div>
              </div>
            ))
          }
        </div>
        <Flex justify="end" align="center">
          <Button 
            className="btn-details" 
            type="primary" 
            shape="round" 
            icon={<RightCircleOutlined />} 
            onClick={onDetails}
          >
            Details
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

export default observer(PricingModal);
