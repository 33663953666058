import { useStore } from "@/store"
import { Modal, Button } from "antd"
import './upgrade-modal.scss'
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import { SmileOutlined } from '@ant-design/icons'  // Importing an icon

const UpgradeModal = () => {
  const { appStore } = useStore()
  const navigate = useNavigate()

  const handleButtonClick = () => {
    // Redirect to homepage
    navigate('/')
  }

  return (
    <Modal 
      open={appStore.upgradeModalVisible} 
      footer={null} 
      onCancel={() => appStore.upgradeModalVisible = false} 
      wrapClassName="upgrade-modal"
    >
      <div className="header">Congratulations!</div>
      <div className="icon-container">
        <SmileOutlined 
        style={{ 
          fontSize: '80px', 
          color: '#4caf50',
          margin: '20px',

          }} /> {/* Large success icon */}
      </div>
      <div className="message">
        <h2>Upgrade Successful!</h2> {/* Big text for the message */}
      </div>
        <Button 
          type="primary" 
          size="large" 
          onClick={handleButtonClick}
          className="start-button"
        >
          Start Music Creation Now
        </Button>
    </Modal>
  )
}

export default observer(UpgradeModal)
