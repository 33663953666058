import { makeAutoObservable } from 'mobx'

interface UserInfo {
  name: string;
  id: string;
}

class AppStore {
  userInfo: UserInfo | null = null
  newsModalVisible = false
  loginModalVisible = false
  signUpModalVisible = false
  upgradeModalVisible = false
  constructor() {
    makeAutoObservable(this)
  }
  queryUserInfo() {
    return new Promise(() => {
      setTimeout(() => {
        this.userInfo = {
          name: 'test',
          id: '111'
        }
      }, 2000)
    })
  }
  isLoggedIn = false
}

const app = new AppStore()
export default app