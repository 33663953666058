import { useStore } from "@/store"
import { Avatar, Button, Checkbox, Form, Input, Modal } from "antd"
import './sign-modal.scss'
import { observer } from "mobx-react-lite"
import cookieImg from '@/assets/images/cookie.svg'
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CredentialResponse, GoogleLogin } from "@react-oauth/google"
import { googleLogin } from "@/api/login"

import { FormInstance } from "antd/es/form"; 


interface SignFormData {
  mail: string;
  code: string;
  password: string;
  confirmPassword: string;
}

const SignModal = () => {
  const { appStore, loginStore } = useStore()
  const [form] = Form.useForm<SignFormData>();
  const [verificationDisabled, setVerificationDisabled] = useState(true)
  const [signDisabled, setSignDisabled] = useState(true)
  const [showVerificationBtn, setShowVerificationBtn] = useState(true)
  const [hasCheckVerification, setHasCheckVerification] = useState(false)
  const [success, setSuccess] = useState(false)
  const [showCookies, setShowCookies] = useState(true)
  const navigate = useNavigate()

  Form.useWatch((values) => {
    form.validateFields(['mail'], { validateOnly: true }).catch((errorInfo: { errorFields: Array<{ errors: string[]; name: string[]; warnings: string[] }> }) => {
      if (errorInfo.errorFields.some(e => e.name.includes('mail'))) {
        setVerificationDisabled(true)
      } else {
        setVerificationDisabled(false)
      }
    })

  //   form.validateFields(['mail', 'code', 'password', 'confirmPassword'], { validateOnly: true }).catch((errorInfo: { errorFields: Array<{ errors: string[]; name: string[]; warnings: string[] }> }) => {
  //     if (errorInfo.errorFields.every(e => e.name.length === 0)) {
  //       setSignDisabled(false)
  //     } else {
  //       setSignDisabled(true)
  //     }
  //   })
  // }, form);
        form.validateFields(['mail', 'code', 'password', 'confirmPassword'], { validateOnly: true }).catch((errorInfo: { errorFields: Array<{ errors: string[]; name: string[]; warnings: string[] }> }) => {
          // 检查是否只有 'mail' 字段被填写，其他字段为空
          const isOnlyMailFilled = values.mail && !values.code && !values.password && !values.confirmPassword;
          
          // 如果只有 mail 字段被填写，禁用 sign up 按钮
          if (isOnlyMailFilled || errorInfo.errorFields.some(e => e.name.length > 0) || !hasCheckVerification) {
            setSignDisabled(true);  // 禁用注册按钮
          } else {
            setSignDisabled(false);  // 启用注册按钮
          }
        });
      }, form);



  const handleVerfication = () => {
    setShowVerificationBtn(false)
    loginStore.vertification(form.getFieldValue('mail')).catch(() => {
      setHasCheckVerification(true)
    });
  };

  const handleSignUp = () => {
    loginStore.register({
      email: form.getFieldValue('mail'),
      password: form.getFieldValue('password'),
      verificationCode: form.getFieldValue('code')
    }).then(() => {
      // setSuccess(true)
      // setTimeout(() => {
      //   appStore.signUpModalVisible = false;
      //   appStore.loginModalVisible = false;
      // }, 500)
      setSuccess(true);
    console.log('Before closing modal: ', appStore.signUpModalVisible, appStore.loginModalVisible);

    appStore.signUpModalVisible = false;  // 关闭注册弹窗
    appStore.loginModalVisible = false;  // 关闭登录弹窗

    console.log('After closing modal: ', appStore.signUpModalVisible, appStore.loginModalVisible);
  }).catch(err => {
    console.error('Registration failed:', err);
    })
  }

  const handleGoogleLogin = (credentialResponse: CredentialResponse) => {
    if (credentialResponse.credential) {
      googleLogin(credentialResponse.credential).then(res => {
        console.log(res)
        if (res.data.access_token) {
          // 存储 Token
          localStorage.setItem('access_token', res.data.access_token);
          // 如果后端返回了 refresh_token，可以存储它以备将来使用
          if (res.data.refresh_token) {
            localStorage.setItem('refresh_token', res.data.refresh_token);
          }
          console.log('Google Login Successful');
          appStore.loginModalVisible = false
          appStore.signUpModalVisible = false;  // 关闭注册弹窗
          appStore.isLoggedIn = true
          // navigate('/')
          // window.location.reload()
        } else {
          console.log('Google Login Failed');
        }
      }).catch(res => {
      })

    }
  }

  return (


    <Modal open={appStore.signUpModalVisible} footer={null} onCancel={() => appStore.signUpModalVisible = false} wrapClassName={`sign-up ${ success && 'success' }`}>
      <div className={`login-wrapper`}>
        <div className="left">
          <span className="top">
            {/* <span className="title">Thank you</span>
            <span className="msg">You’re all set to start creating<br />with Remixly…</span> */}
          </span>
          <span className="net">www.remixly.ai</span>
        </div>
        <div className="right">
          <div className="title"><span className="underline">Sign up</span> to <span className="remix">Remixly</span></div>
          <span className="login-with">Sign up with:</span>
          <GoogleLogin
            onSuccess={credentialResponse => {
              handleGoogleLogin(credentialResponse)
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
          {/* <div className="avatar"><Avatar>U</Avatar></div> */}
          <span className="or">or</span>
          <Form
            layout="vertical"
            form={form}
          >
            <Form.Item label="Email address" name="mail" rules={[
              { required: true, message: 'Please input your mail!' },
              { type: 'email', message: 'Invalid mailbox!' }
            ]}>
              <Input />
            </Form.Item>
            <Form.Item>
              {
                  showVerificationBtn
                ?
                  <>
                    <Button disabled={verificationDisabled} type="primary" onClick={handleVerfication}>Send verification email</Button>
                  </>
                :
                 <>
                  <Form.Item label="Verification code" name="code" rules={[
                    { required: true, message: 'Please input verification code!' }
                  ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input.Password />
                  </Form.Item>
                  <Form.Item label="Confirm Password" name="confirmPassword" rules={[
                    { required: true, message: 'Please confirm your password!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The new password that you entered do not match!'));
                      },
                    })
                  ]}>
                    <Input.Password />
                  </Form.Item>
                 </>
              }
              <Button disabled={signDisabled} type="primary" onClick={handleSignUp}>Sign up</Button>
            </Form.Item>
          </Form>
          <div className="footer">
            <span>Do you already have an account?</span>
            <Button type="link" onClick={()=>{appStore.signUpModalVisible=false; appStore.loginModalVisible=true}}>Go log in</Button>
          </div>
        </div>
      </div>
      {
          success 
        ?
          <></>
        :
          <div className="cookies-consent" style={{display: showCookies?'':'none'}}>
            <div className="left">
              <img src={cookieImg} />
            </div>
            <div className="content">
              <span className="title">Cookie Consent</span><br />
              <span className="desc">This website uses cookies to enhance the user experience and analyze site traffic. By clicking "Accept" you consent to our use of </span>
              <a href="/cookies" target="_blank">cookies.</a>
            </div>
            <div className="right">
              <Button className="btn-accept" onClick={()=>{setShowCookies(false)}}>Accept</Button>
            </div>
          </div>
      }
    </Modal>
  );
}

export default observer(SignModal)