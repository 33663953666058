import './index.scss'
import { Layout } from "antd"
import { observer } from 'mobx-react-lite'
import { Outlet, useLocation } from "react-router-dom"
// import Header from "./Header"
import Header from '@/components/header/Header';
import LoginHeader from '@/components/header/Header'
import { useMemo } from "react"
import styles from './index.module.scss';
import { motion } from 'framer-motion';
import { useState } from 'react';
import NavigationRail from "../../components/sidebar/Sidebar";

const Toolkit = () => {
  const location = useLocation();
  console.log(location)
  const showLoginHeader = useMemo(() => {
    return ['/toolkit/templates', '/toolkit/my-creations', '/toolkit/my-workflows'].includes(location.pathname)
  }, [location.pathname])

const [isNavExpanded, setIsNavExpanded] = useState(true);

  return (
<div className={styles.container}>
      <NavigationRail onExpandChange={setIsNavExpanded} />
    
      <motion.div 
        className={styles.content}
        animate={{
          marginLeft: isNavExpanded ? '15%' : '0',
        }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >

    <Layout className="toolkit-wrapper">
      
      <Layout className="view-layout" style={{ minHeight: '100vh', background: '#fff' }}>
      
        {/* {
          showLoginHeader ? <LoginHeader /> : <Header />
        } */}
        <div className="view-wrapper">
        <Header showBreadCrumb={true} />
          <Outlet />
        </div>
      </Layout>
    </Layout>

    </motion.div>
    </div>
  )
}

export default observer(Toolkit)