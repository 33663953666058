export const nodeNameList = ['Conversion', 'Separate', 'Deecho', 'Dereverb', 'PitchShifter', 'SpeedChanger', 'Upload', 'Mixing', 'Output', 'TTS']
export const nodeDescription = {
    'Conversion': 'Converse voice',
    'Separate': 'Separate vocals and accompaniment.',
    'Deecho': 'Remove echo from the song.',
    'Dereverb': 'Remove reverb from the song.',
    'PitchShifter': 'Change the pitch of the song.',
    'SpeedChanger': 'Change the speed of the song.',
    'Upload': 'Upload file from local.',
    'Link': 'Upload file from link.',
    'Mixing': 'Mix two audio tracks.',
    'Output': 'Output the results of the entire workflow.',
    'TTS': 'Change text to audio'
}