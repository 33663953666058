import { Button, Col, Flex, Input, Row, Upload, UploadProps, Modal, Spin, Popover } from "antd"
import { observer } from "mobx-react-lite"
import UserPanel from "./UserPanel"
import logoImg from '@/assets/images/logo.png'
import { CaretRightOutlined, DownOutlined, PauseOutlined, PlusOutlined, RightCircleFilled, RightOutlined, ToTopOutlined, UpSquareOutlined, ExclamationCircleOutlined, RightCircleOutlined, PlayCircleOutlined, DeleteOutlined, SettingOutlined, CloseOutlined, ArrowLeftOutlined, CheckCircleOutlined, PauseCircleOutlined } from "@ant-design/icons"
import { useEffect, useRef, useState } from "react";
import { message } from "antd";
import { useStore } from "@/store";
import { cacheLink, inference2 } from "@/api/infer"
// import { ExclamationCircleOutlined, PlusOutlined, RightCircleOutlined, ToTopOutlined, UpSquareOutlined } from "@ant-design/icons";

import originalTrackImg from '@/assets/images/original-track.png'
import separatedImg from '@/assets/images/separated.png'
import arrowDownImg from '@/assets/images/arrow-down.svg'
import wcu1Img from '@/assets/images/WCU1.svg'
import wcu2Img from '@/assets/images/WCU2.svg'
import wcu3Img from '@/assets/images/WCU3.svg'
import wcu4Img from '@/assets/images/WCU4.svg'
import wcu5Img from '@/assets/images/WCU5.svg'
import wcu6Img from '@/assets/images/WCU6.svg'
import wcu1HoverImg from '@/assets/images/WCU1-hover.svg'
import wcu2HoverImg from '@/assets/images/WCU2-hover.svg'
import wcu3HoverImg from '@/assets/images/WCU3-hover.svg'
import wcu4HoverImg from '@/assets/images/WCU4-hover.svg'
import wcu5HoverImg from '@/assets/images/WCU5-hover.svg'
import wcu6HoverImg from '@/assets/images/WCU6-hover.svg'
import app1Img from '@/assets/images/app-1.webp'
import app2Img from '@/assets/images/app-2.webp'
import app3Img from '@/assets/images/app-3.webp'
import app4Img from '@/assets/images/app-4.webp'
import app5Img from '@/assets/images/app-5.webp'
import app6Img from '@/assets/images/app-6.webp'
import app7Img from '@/assets/images/app-7.webp'
import app8Img from '@/assets/images/app-8.webp'
import app9Img from '@/assets/images/app-9.webp'
import app10Img from '@/assets/images/app-10.webp'
import app11Img from '@/assets/images/app-11.webp'
import app12Img from '@/assets/images/app-12.webp'
import converImg from '@/assets/images/cover.jpg'
import deechoImg from '@/assets/images/deecho.jpg'
import dereverbImg from '@/assets/images/dereverb.jpg'
import separateImg from '@/assets/images/separate.jpg'
import QuestionWrapper from "@/components/questions/Question"
import Footer from "@/components/footer/Footer"
import processImg from '@/assets/images/process-logo.png'
import shareImg from '@/assets/images/share.svg'
import linkImg from '@/assets/images/link.svg'
import downloadImg from '@/assets/images/download.svg'
import uploadPanel from '@/assets/images/uploadPanel.png'
import deechoPanel from '@/assets/images/deechoPanel.png'
import resultPanel from '@/assets/images/resultPanel.png'
import CrownGray from '@/assets/images/crown-gray.svg'
import CrownWhite from '@/assets/images/crown-white.svg'

import { useNavigate } from "react-router-dom"
import { validateLink } from "@/utils/validateLink"

const TitleWrapper = () => {
  return (
    <div className="title-wrapper">
      <div className="title">Deecho</div>
      <div className="sub-title">
      Remove unwanted echo and background noise from your audio tracks with our AI-powered DeEcho tool,
      </div>
      <div className="sub-title">
      perfect for enhancing clarity in recordings, podcasts, and music.
      </div>
    </div>
  )
}

const DescWrapper = () => {
  return (
    <div className="desc-wrapper">
      <div className="left">
        <div className="line-1">Utilizes cutting-edge AI technology</div>
        <div className="line-2">
          <span>to separate vocals from any track in </span>
          <span className="under-a-minute">under a minute</span>
          <span>, delivering </span>
          <span className="high-quality">high-quality instrumentals.</span>
        </div>
        <div className="text">
          <span>
            Simply upload your song online — no need to download or install any software — and the process is completed instantly.
          </span>
          <span>
          DeEcho uses cutting-edge AI technology to eliminate echoes and background noise from your audio while preserving the natural quality of the recording. Whether you're working with audio recorded in acoustically challenging environments or simply want to improve the clarity of voiceovers and music, our tool provides a clean, echo-free output in under a minute. Simply upload your audio file—no downloads or installations required—and the process is completed instantly. Supporting multiple formats such as MP3 and WAV, DeEcho is ideal for podcasters, musicians, and audio professionals looking to enhance the clarity and quality of their tracks.
          </span>
        </div>
      </div>
      <div className="right">
        <div className="card">
          <div className="top">
            <div className="top-item">
              <img src={logoImg} />
            </div>
            <div className="top-item">
              <span>Deecho</span>
            </div>
          </div>
          <div className="content">

          </div>
        </div>
      </div>
    </div>
  )
}

const Feature = () => {
  return (
    <Flex className="feature" justify="space-between" align="center">
      <div className="feature-item">
        <div className="title">Original Track</div>
        <div className="icon">
          <CaretRightOutlined style={{ color: '#c1c1c1' }} />
          <img src={originalTrackImg} />
        </div>
        <div className="text">
        Listen to the original version of the track, where echo and background noise may interfere with the clarity of the audio.
        </div>
      </div>
      <div className="feature-item">
        <div className="title">Separated Instrumental</div>
        <div className="icon">
          <PauseOutlined style={{ color: '#c1c1c1' }} />
          <img src={separatedImg} />
        </div>
        <div className="text">
        After applying the DeEcho tool, hear how the track sounds without the distracting echoes and background noise, resulting in a cleaner, more professional audio output.
        </div>
      </div>
    </Flex>
  )
}

const StepWrapper = () => {
  const [step, setStep] = useState(0)
  const texts = [
    'Upload your audio from a local device or paste a YouTube link. Our tool supports both methods for easy access to your files.',
    "Use the slider to control the level of echo and noise reduction. For advanced users, we offer fine-tuned control over how much echo and background noise is removed, while beginners can rely on default settings that work well with most recordings.",
    "The de-echo process takes less than a minute. You can preview the echo-reduced version online, download it, or share it instantly."
  ]
  const imgs = [
    uploadPanel,
    deechoPanel,
    resultPanel
  ]
  return (
    <>
      <div className="step-desc">
        <span>How to Remove Vocals from a Song in </span>
        <span>3 Easy Steps?</span>
        <img src={arrowDownImg} />
      </div>
      <div className="step-card">
        <div className="left">
          <span>
          {texts[step]}
          </span>
        </div>
        <div className="right">
          <img src={imgs[step]} width={'100%'}/>
        </div>
      </div>
      <div className="step-line">
        <div className={`step-item ${step===0?'active':''}`} onClick={()=>{setStep(0)}}>
          <span className="num">1</span>
          <span className="name">Choose A Song</span>
        </div>
        <div className={`step-item ${step===1?'active':''}`}  onClick={()=>{setStep(1)}}>
          <span className="num">2</span>
          <span className="name">Set Your Parameters</span>
        </div>
        <div className={`step-item ${step===2?'active':''}`}  onClick={()=>{setStep(2)}}>
          <span className="num">3</span>
          <span className="name">Output Your Track</span>
        </div>
      </div>
    </>
  )
}

const ReasonWrapper = () => {
  return (
    <div className="reason-list">
      <div className="reason-item">
        <div className="icon">
          <img className="default" src={wcu1Img} />
          <img className="hover" src={wcu1HoverImg} />
        </div>
        <div className="text">
          Fast Processing
        </div>
        <div className="detail">Remove echoes and noise from any track in under a minute, with instant feedback.</div>
      </div>
      <div className="reason-item">
        <div className="icon">
          <img className="default" src={wcu2Img} />
          <img className="hover" src={wcu2HoverImg} />
        </div>
        <div className="text">
          Easy to Use
        </div>
        <div className="detail">An intuitive interface that allows for easy echo reduction, suitable for beginners and professionals alike.</div>
      </div>
      <div className="reason-item">
        <div className="icon">
          <img className="default" src={wcu3Img} />
          <img className="hover" src={wcu3HoverImg} />
        </div>
        <div className="text">
          Customizable Options
        </div>
        <div className="detail">Adjust the level of echo and noise reduction for precise control, or use preset options for quick processing.</div>
      </div>
      <div className="reason-item">
        <div className="icon">
          <img className="default" src={wcu4Img} />
          <img className="hover" src={wcu4HoverImg} />
        </div>
        <div className="text">
          Versatile Song Sources
        </div>
        <div className="detail">Upload your audio directly from your device or provide a YouTube link for maximum flexibility.</div>
      </div>
      <div className="reason-item">
        <div className="icon">
          <img className="default" src={wcu5Img} />
          <img className="hover" src={wcu5HoverImg} />
        </div>
        <div className="text">
          High-Quality Output
        </div>
        <div className="detail">Advanced AI ensures minimal audio distortion, delivering clean, echo-free results.</div>
      </div>
      <div className="reason-item">
        <div className="icon">
          <img className="default" src={wcu6Img} />
          <img className="hover" src={wcu6HoverImg} />
        </div>
        <div className="text">
          Instant Preview and Sharing
        </div>
        <div className="detail">Listen to the improved track before downloading and easily share it with others.</div>
      </div>
    </div>
  )
}

const DescOverview = () => {
  return (
    <div className="desc-overview">
      <div className="card">
        <img className="logo" src={logoImg} />
        <div className="desc one">AI Audio Tools</div>
        <div className="desc two">AI Workflow</div>
      </div>
      <div className="remark">
        <span>Ready to transform your music in under a minute ? </span>
        <span>Experience effortless vocal removal with our cutting-edge AI tool! </span>
      </div>
    </div>
  )
}

const ToolsWrapper = () => {
  const toolList = [
    'Song Cover',
    'Deecho',
    'Dereverb',
    'Speed Change',
    'Pitch Shift',
    'Separator',
  ]
  interface routerType {
    [key: string]: string
  }
  const toolRouters: routerType = {
    'Song Cover': '/toolkit/song-cover',
    'Deecho': '/toolkit/deecho',
    'Dereverb': '/toolkit/dereverb',
    'Speed Change': '/toolkit/speedChange',
    'Pitch Shift': '/toolkit/pitchShift',
    'Separator': '/toolkit/vocalRemoval',
  }

  const toolImage: routerType = {
    'Song Cover': converImg,
    'Deecho': deechoImg,
    'Dereverb': dereverbImg,
    'Speed Change': app5Img,
    'Pitch Shift': app11Img,
    'Separator': separateImg,
  }
  const randomSample = (arr: any[], m: number) => {
    if (m > arr.length) {
      throw new Error("Cannot select more elements than are in the array.");
    }

    // 创建一个副本，以避免改变原数组
    const shuffled = arr.slice();

    // 使用 Fisher-Yates 洗牌算法来随机打乱数组
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }

    // 返回打乱后的前 m 个元素
    return shuffled.slice(0, m);
  }
  const navigate = useNavigate()
  return (
    <div className="tools">
      <div className="card">
        <div className="top">
          <span>Looking for more</span>
          <span className="tools">TOOLs?</span>
          <span>We recommend</span>
          <img src={arrowDownImg} />
        </div>
        <div className="app-list">
          {
            randomSample(toolList, 3).map(e => {
              return (
                <div className="app-list-item" onClick={() => navigate(toolRouters[e])}>
                  <div className="top">
                    <span className="title">{e}</span>
                  </div>
                  <div className="poster-wrapper">
                    <div className="poster" style={{ backgroundImage: `url(${toolImage[e]})` }}></div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="footer">
          <div className="btn-tools" onClick={()=>{window.open('/', '_blank')}}>More tools</div>
        </div>
      </div>
    </div>
  )
}

const { Dragger } = Upload;

// const ActionPanel = () => {
//   const navigate = useNavigate();

//   return (
//     <div className="action-panel">
//       <div className="upload-audio">
//         <p className="title">Upload Audio</p>
//         <p className="desc">
//           Or Drag And Drop Mp3/Wav/Flac File Here. 20MB Limit, Within 10MIN
//         </p>
//         <Flex justify="space-between" align="center">
//           <div className="btn-plus" onClick={() => navigate('/toolkit/tool_1')}>
//             <PlusOutlined />
//           </div>
//             <Button className="btn-upload" type="primary" shape="round" icon={<ToTopOutlined />}>
//               Upload
//             </Button>
//         </Flex>
//       </div>
//       <span className="or">Or</span>
//       <div className="extract-audio">
//         <p className="title">Extract Audio From URL</p>
//         <p className="desc">We Now Support Links From YouTube and Suno. Within 10MIN</p>
//         <Flex justify="space-between" align="center">
//           <Input className="input-link" placeholder="Paste link here" />
//           <Button className="btn-fetch" type="primary" shape="round" icon={<UpSquareOutlined />} onClick={() => navigate('/toolkit/tool_2')}>
//             Fetch
//           </Button>
//         </Flex>
//       </div>
//     </div>
//   )
// }


const ActionPanel = observer(() => {
  const [showFetch, setShowFetch] = useState(false)
  const [showCheckValid, setShowCheckValid] = useState(false)
  const [showUploading, setShowUploading] = useState(false)
  const { toolStore } = useStore();

  const onInputLink = (link: string) => {
    // validate

    // save
    toolStore.fileUrlList[0] = link
  }

  const onFetch = () => {
    if (toolStore.fileUrlList.length == 0) { message.error('Please enter your link'); return }
    if (!validateLink(toolStore.fileUrlList[0])) { message.error('Please check your link from YouTube or Suno'); return }
    setShowFetch(true)
    cacheLink(toolStore.fileUrlList[0]).then((res) => {
      setShowFetch(false)
      console.log(res)
      if (res.data.success) {
        toolStore.curState = 2
      }
      else {
        console.log(res.msg)
        message.error(res.msg);
      }
    }).catch(res=>{
      message.error(res);
    }).finally(()=>{
      setShowFetch(false)
    })
  }

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    // action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    // customRequest(file) {
    //   // file.file
    //   console.log(file)
    //   toolStore.fileList.push(file)

    // },
    beforeUpload(file) {
      // 检查文件类型
      const isAudio = ['audio/mpeg', 'audio/wav', 'audio/flac', 'audio/x-wav'].includes(file.type);
  
      // 如果文件的MIME类型不是音频类型，显示错误信息并阻止上传
      if (!isAudio) {
        message.error('You can only upload MP3/WAV/FLAC file!');
        return false;
      }
  
      // 如果是WAV文件，检查Safari中的MIME类型问题
      if (file.type === 'audio/wav' || file.type === 'audio/x-wav') {
        // 手动处理Safari中的MIME类型问题
        const reader = new FileReader();
        reader.onload = () => {
          const arrayBuffer = reader.result as ArrayBuffer;
          // 可以在此对音频文件做更深层的处理，或者直接上传
          console.log('WAV file successfully read:', arrayBuffer);
        };
        reader.onerror = () => {
          message.error('Failed to read the WAV file');
        };
        reader.readAsArrayBuffer(file);
      }

      if (toolStore.fileList.length === 0) {
        toolStore.fileList.push(file)
      }
      else {
        toolStore.fileList[0] = file
      }
      toolStore.curSongName = file.name
      console.log(file)
      // return isAudio;
      return false;
    },
    fileList: toolStore.fileList
    // onChange(info: any) {
    //   console.log(info);
    //   const { status } = info.file;
    //   if (status !== 'uploading') {
    //     console.log(info.file, info.fileList);
    //     const size = info.size / 1024 / 1024;
    //     toolStore.fileList.push(info)
    //     setShowUploading(false)
    //   }
    // },
  };


  return (
    <>
      <div className="action-panel">
        <div className="upload-audio no-padding">
          <Dragger {...props}>
            <p className="title">Upload Audio</p>
            <p className="desc">
              Or Drag And Drop Mp3/Wav/Flac File Here. 20MB Limit, Within 10MIN
            </p>
            <Flex justify="space-between" align="center">
              {
                toolStore.fileList.length > 0
                  ?
                  <div>{toolStore.fileList[0].name}</div>
                  :
                  <div className="btn-plus">
                    <PlusOutlined />
                  </div>
              }
              <Button className="btn-upload" type="primary" shape="round" icon={<ToTopOutlined />} onClick={(e) => { e.stopPropagation(); console.log('upload'); if (toolStore.fileList.length == 0) { message.error('Please upload your file'); return }; toolStore.curState = 2 }} >
                Upload
              </Button>
            </Flex>
          </Dragger>
        </div>
        {/* <span className="or">Or</span> */}
        {/* <div className="extract-audio">
          <p className="title">Extract Audio From URL</p>
          <p className="desc">We Now Support Links From YouTube and Suno. Within 10MIN</p>
          <Flex justify="space-between" align="center">
            <Input className="input-link" placeholder="Paste link here" onChange={(e) => { onInputLink(e.currentTarget.value) }} />
            <Button className="btn-fetch" type="primary" shape="round" icon={<UpSquareOutlined />} onClick={onFetch}>
              Fetch
            </Button>
          </Flex>
        </div> */}
      </div>
      <Spin spinning={showFetch} tip="Fetching..." fullscreen />
      <Spin spinning={showUploading} tip="Loading..." fullscreen />
      <Modal open={showCheckValid} footer={null} closable={false} wrapClassName="fullscreen">
        <div className="check-valid">
          <div className="icon"><ExclamationCircleOutlined /></div>
          <div className="title">
            Please upload an audio file that meets the size and duration requirements.
          </div>
          <div className="footer">
            <div className="btn-upgrade">
              <span>Upgrade to remove the limitations.</span>
              <RightCircleOutlined />
            </div>
            <div className="btn-try-another">
              Try another File.
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
})


const ParamInputPanel = observer(() => {
  const [showProcessingModal, setShowProcessingModal] = useState(false)
  const [showUploading, setShowUploading] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const { toolStore } = useStore();
  const audioRef = useRef<HTMLAudioElement>(null)
  const [playing, setPlaying] = useState(false)
  const togglePlay = () => {
    console.log(new Blob([toolStore.fileList[0]]))
    if (audioRef.current) {
      if (playing) {
        audioRef.current.pause()
      } else {
        audioRef.current.play()
      }
      setPlaying(!playing)
    }
  }

  const onMessage = (data: any) => {
    console.log(data)
    // toolStore.outputUrl = data.result
    toolStore.outputUrl = [data.result.origin, data.result.watermark]
    setShowProcessingModal(false)
    toolStore.curState = 3
  }
  const onProcess = () => {
    setShowProcessingModal(true)
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      console.log(base64)
      const inferData = {
        task: 'Deecho',
        data: {
          file_b64: base64,
          return_format: toolStore.deechoParams.outputFormat,

          // file_b64: '',
        }
      }
      inference2(inferData, onMessage)
    };
    reader.readAsDataURL(toolStore.fileList[0]);
    // setTimeout(() => {
    //   setShowProcessingModal(false)
    //   toolStore.curState = 3
    // }, 500)
  }

  const onExitProcessing = () => {
    setShowProcessingModal(false)
    setShowConfirmModal(true)
  }

  const onBack = () => {
    setShowConfirmModal(false)
    setShowProcessingModal(true)
  }
  const navigate = useNavigate()

  return (
    <>
      <div className="action-panel">
        <div className="upload-audio">
          <div>
            <p className="title">{toolStore.curSongName}</p>
            <p className="desc"></p>
            <audio style={{ display: 'none' }} ref={audioRef}>
              <source src={toolStore.fileList[0]?URL.createObjectURL(new Blob([toolStore.fileList[0]])):''} type="audio/mpeg"/>
              Your browser does not support the audio tag.
            </audio>
            <Flex justify="space-between" align="center">
              {playing ?
                <PauseCircleOutlined className="icon-play" onClick={togglePlay} style={{marginTop: '15px'}} /> :
                <PlayCircleOutlined className="icon-play" onClick={togglePlay} style={{marginTop: '15px'}} />
              }
              <DeleteOutlined className="icon-del" onClick={() => { toolStore.reset() }} style={{marginTop: '15px'}} />
            </Flex>
          </div>
        </div>
        <div className="extract-audio">
          <p className="title">Adjust Settings</p>
          <p className="desc">Default values have been automatically selected for you, and you can customize them as needed.</p>
          {/* <div className="custom-form" style={{ marginTop: '56px' }}>
            <span className="form-item">Needed tracks :</span>
            <div className="tag-list">
              {
                toolStore.separatorParams.trackList.map(e => <div className={`item ${e === toolStore.separatorParams.track && 'active'}`} onClick={() => toolStore.separatorParams.track = e}>{e}</div>)
              }
            </div>
          </div> */}
          <div className="custom-form" style={{ marginTop: '35px' }}>
            <span className="form-item">Output format:</span>
            <div className="tag-list">
              {
                toolStore.deechoParams.outputFormatList.map(e => <div className={`item ${e === toolStore.deechoParams.outputFormat && 'active'}`} onClick={() => toolStore.deechoParams.outputFormat = e}>{e}</div>)
              }
            </div>
          </div>
          <Flex justify="end" align="center">
            <Button className="btn-fetch" type="primary" shape="round" icon={<SettingOutlined />} onClick={onProcess}>
              Process
            </Button>
          </Flex>
        </div>
      </div>
      <Spin spinning={showUploading} tip="Loading..." fullscreen />
      <Modal open={showProcessingModal} footer={null} closable={false} wrapClassName="fullscreen">
        <div className="processing-modal">
          <CloseOutlined className="icon-close" onClick={onExitProcessing} />
          <div className="icon">
            <Spin size="large" />
          </div>
          <div className="title">Processing</div>
          <div className="sub-title">
            <span>Remixly AI now works, it may take a minute.</span><br />
            <span>Please keep this page open.</span>
          </div>
          <div className="footer">
            <div className="btn-explore" onClick={()=>{window.open('/', '_blank')}}>
              <span>Explore other apps while waiting</span>
              <RightCircleOutlined />
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={showConfirmModal} footer={null} closable={false} wrapClassName="fullscreen">
        <div className="check-valid">
          <ArrowLeftOutlined className="icon-left" onClick={onBack} />
          <div className="icon"><ExclamationCircleOutlined /></div>
          <div className="title">
            Closing this page will interrupt the ongoing task, and progress will not be saved.<br />
            Are you sure you want to exit?
          </div>
          <div className="footer">
            <div className="btn-upgrade" onClick={()=>{window.open('/', '_blank')}}>
              <span>Explore other apps while waiting</span>
              <RightCircleOutlined />
            </div>
            <div className="btn-try-another" onClick={() => {setShowConfirmModal(false);navigate('/')}}>
              Confirm exit
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
})



const ResultPlayer = ({ name, url, downloadContent, setShowShareModal }: { name: string, url: string, downloadContent: JSX.Element, setShowShareModal: (b: boolean) => void }) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [progress, setProgress] = useState(0)

  const progressRef = useRef<HTMLDivElement>(null)

  const togglePlay = () => {
    if (audioRef.current) {
      if (playing) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setPlaying(!playing);
    }
  };

  const clickChangeProgress = (e: React.MouseEvent<HTMLDivElement>) => {
    console.log(e)
    if (progressRef.current) {
      console.log(progressRef.current.getBoundingClientRect())
      const progress = (e.clientX - progressRef.current.getBoundingClientRect().left) / (progressRef.current.clientWidth)
      const currentTime = duration * progress
      setCurrentTime(currentTime)
      setProgress(progress)
      if (audioRef.current) {
        audioRef.current.currentTime = currentTime
      }
    }
  }

  const { toolStore } = useStore();
  const downloadFile = (blobUrl:string) => {
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = `${toolStore.fileList[0].name}-remixlyAI.${toolStore.speedChangeParams.outputFormat}`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };

  return (
    <div className="output-item" style={{ marginTop: '40px' }}>
      <audio
        ref={audioRef}
        src={url}
        style={{ display: 'none' }}
        onTimeUpdate={(e) => {
          const currentTime = audioRef.current?.currentTime || 0
          const duration = audioRef.current?.duration || 0
          setCurrentTime(currentTime)
          setDuration(duration)
          setProgress(currentTime / duration)
        }}
      >
        Your browser does not support the audio element.
      </audio>
      <span className="lbl">{name}</span>
      <div className="content">
        <div className="player">
          {playing ? <PauseCircleOutlined onClick={togglePlay} /> : <PlayCircleOutlined onClick={togglePlay} />}
          <div className="progress" style={{ '--progress-length': `${progress * 100}%` } as React.CSSProperties} onClick={clickChangeProgress} ref={progressRef}></div>
        </div>
        {/* <Button onClick={()=>{downloadFile(url)}} style={{background:'#5dacff', color: 'white'}}>Download</Button> */}
        <Popover placement="bottom" content={downloadContent} trigger="click">
          <Button style={{ background: '#5dacff', color: 'white' }}>Download</Button>
        </Popover>

        <div className="share" onClick={() => setShowShareModal(true)}>
          <img src={shareImg} />
        </div>
      </div>
    </div>
  )
}


const DisplayResultPanel = observer(() => {
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [showUploading, setShowUploading] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const { toolStore } = useStore();



  const audioRef = useRef<HTMLAudioElement>(null)
  const [playing, setPlaying] = useState(false)
  const togglePlay = () => {
    console.log(new Blob([toolStore.fileList[0]]))
    if (audioRef.current) {
      if (playing) {
        audioRef.current.pause()
      } else {
        audioRef.current.play()
      }
      setPlaying(!playing)
    }
  }

  interface AudioFormats {
    mp3: string;
    wav: string;
    flac: string;
    [key: string]: string; // 添加字符串索引签名
  }

  const mimeMap:AudioFormats = {
    'mp3': 'audio/mp3',
    'wav': 'audio/wav',
    'flac': 'audio/x-flac',
  }
  const base64ToBlob = (base64:string) => {
    console.log(base64)
    const byteCharacters = atob(base64);
    const byteArrays = [];
    const mimeType = mimeMap[toolStore.separatorParams.outputFormat]
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: mimeType });
  }

  const downloadFile = (blobUrl:string) => {
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = `${toolStore.fileList[0].name}-remixlyAI.${toolStore.speedChangeParams.outputFormat}`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };

  // const downloadContent = (
  //   <ul className="user-info">
  //     <li onClick={() => { setShowDownloadModal(true); downloadFile(URL.createObjectURL(base64ToBlob(toolStore.outputUrl as string))) }}>Watermark Free Version <span><img src={CrownGray} height={'13px'}/></span></li>
  //     <li onClick={() => { setShowDownloadModal(true); downloadFile(URL.createObjectURL(base64ToBlob(toolStore.outputUrl as string))) }}>Preview Version</li>
  //   </ul>
  // );
  const downloadContent = (
    <ul className="user-info">
      {/* <li onClick={() => { setShowDownloadModal(true); downloadFile(URL.createObjectURL(base64ToBlob(toolStore.outputUrl as string))) }}>Watermark Free Version <span><img src={CrownGray} height={'13px'}/></span></li>
      <li onClick={() => { setShowDownloadModal(true); downloadFile(URL.createObjectURL(base64ToBlob(toolStore.outputUrl as string))) }}>Preview Version</li> */}
      <li onClick={() => { setShowDownloadModal(true); downloadFile(toolStore.outputUrl[0] as string) }}>Watermark Free Version <span><img src={CrownGray} height={'13px'}/></span></li>
      <li onClick={() => { setShowDownloadModal(true); downloadFile(toolStore.outputUrl[1] as string) }}>Preview Version</li>
    </ul>
  );


  return (
    <>
      <div className="action-panel tool-3">
        <div className="upload-audio">
          <div>
            <p className="title">{toolStore.curSongName}</p>
            <p className="desc"></p>
            <audio style={{ display: 'none' }} ref={audioRef}>
              <source src={toolStore.fileList[0]?URL.createObjectURL(new Blob([toolStore.fileList[0]])):''} type="audio/mpeg"/>
              Your browser does not support the audio tag.
            </audio>
            <Flex justify="space-between" align="center">
              {playing ?
                <PauseCircleOutlined className="icon-play" onClick={togglePlay} /> :
                <PlayCircleOutlined className="icon-play" onClick={togglePlay} />
              }
              <DeleteOutlined className="icon-del" onClick={() => { toolStore.reset() }} />
            </Flex>
          </div>
        </div>
        <div className="extract-audio">
          <p className="title">Output Audio</p>
          <p className="desc"></p>
          {/* {/* <ResultPlayer name="Vocal track" url={toolStore.outputUrl[0]} downloadContent={downloadContent} setShowShareModal={setShowShareModal} /> */}
          <ResultPlayer name="" url={toolStore.outputUrl[0] as string} downloadContent={downloadContent} setShowShareModal={setShowShareModal} />
          {/* <ResultPlayer name="" url={URL.createObjectURL(base64ToBlob(toolStore.outputUrl as string))} downloadContent={downloadContent} setShowShareModal={setShowShareModal} /> */}
          {/* <ResultPlayer name="Accompaniment track" url={toolStore.outputUrl[1]} downloadContent={downloadContent} setShowShareModal={setShowShareModal} /> */}
          {/* <div className="output-item" style={{ marginTop: '40px' }}>
            <span className="lbl">Vocal track</span>
            <div className="content">
              <div className="player">
                <PlayCircleOutlined />
                <div className="progress"></div>
              </div>
              <Popover placement="bottom" content={downloadContent} trigger="click">
                <Button>Download</Button>
              </Popover>
              <div className="share" onClick={() => setShowShareModal(true)}>
                <img src={shareImg} />
              </div>
            </div>
          </div>
          <div className="output-item" style={{ marginTop: '31px' }}>
            <span className="lbl">Accompaniment track</span>
            <div className="content">
              <div className="player">
                <PlayCircleOutlined />
                <div className="progress"></div>
              </div>
              <Popover placement="bottom" content={downloadContent} trigger="click">
                <Button className="btn-download">Download</Button>
              </Popover>
              <div className="share" onClick={() => setShowShareModal(true)}>
                <img src={shareImg} />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Spin spinning={showUploading} tip="Loading..." fullscreen />
      <Modal open={showShareModal} footer={null} closable={false} wrapClassName="fullscreen">
        <div className="check-valid">
          <div className="icon"><img width={100} height={100} src={linkImg} /></div>
          <div className="title" style={{ paddingTop: 0, paddingBottom: '80px' }}>
            Song link has been copied to the clipboard.
          </div>
          <div className="footer" onClick={() => setShowShareModal(false)}>
            <div className="btn-upgrade" style={{ width: '400px', boxSizing: 'border-box', textAlign: 'center' }}>
              <span>OK</span>
              <CheckCircleOutlined />
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={showDownloadModal} footer={null} closable={false} wrapClassName="fullscreen">
        <div className="check-valid">
          <div className="icon"><img width={100} height={100} src={downloadImg} /></div>
          <div className="title" style={{ paddingTop: 0, paddingBottom: '80px' }}>
            The song is downloading now.
          </div>
          <div className="footer" onClick={() => setShowDownloadModal(false)}>
            <div className="btn-upgrade" style={{ width: '674px', boxSizing: 'border-box', textAlign: 'center' }}>
              <span>Check in the browser's Downloads</span>
              <CheckCircleOutlined />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
})

const generateRandomId = () => {
  // 生成一个随机数，转换为36进制（包含数字和字母），并截取前10位
  return Math.random().toString(36).substr(2, 10);
};

const Deecho = () => {
  // const [curState, setCurState] = useState(1)
  const { toolStore } = useStore();
  const showingPanels = [<ActionPanel />, <ParamInputPanel />, <DisplayResultPanel />]
  const stateClass = ['tool-1', 'tool-2', 'tool-3']
  const [curPage, setCurPage] = useState(generateRandomId())
  useEffect(() => {
    if (toolStore.pageId !== curPage) {
      toolStore.reset()
      toolStore.pageId = curPage
    }
  }, [curPage])
  useEffect(()=>{
    toolStore.reset()
  }, [])
  return (
    <>
      <div className={`content vocal-removal ${stateClass[toolStore.curState - 1]}`}>
        <Row>
          <Col span={16}>{showingPanels[toolStore.curState - 1]}</Col>
          <Col span={8}><UserPanel step={1} /></Col>
        </Row>
        <TitleWrapper />
        <DescWrapper />
        {/* <span className="feature-example-text">Feature Example</span>
        <Feature /> */}
        <StepWrapper />
        <span className="why-choose-us">Why Choose Us ?</span>
        <ReasonWrapper />
        <QuestionWrapper />
        <DescOverview />
        <div className="btn-start">
          Get started for FREE
        </div>
        <ToolsWrapper />
      </div>
      <Footer />
    </>
  )
}

export default observer(Deecho)