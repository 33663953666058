import Footer from "@/components/footer/Footer"
import { Article } from "@/models"
import { useState } from "react"
import { motion } from 'framer-motion';
import NavigationRail from "../../components/sidebar/Sidebar";
import styles from './cookies.module.scss';

const Cookies = () => {
  const [contents, setContents] = useState<Article[]>([
    { 
      title: 'What Are Cookies?',
      content: (
        <div>
          <span>Cookies are small text files stored on your device (computer, tablet, or mobile) when you visit a website. They help the website remember your preferences, login information, and browsing activities, enabling a smoother user experience.</span>
          <span>Cookies serve various purposes, such as:</span><br />
          <ul>
            <li>Ensuring the proper functioning of our platform.</li>
            <li>Collecting analytical data to understand how users interact with our services.</li>
            <li>Remembering your preferences, such as language settings or login status.</li>
          </ul>
        </div>
      )
    },
    {
      title: 'Types of Cookies We Use',
      content: 'Remixly.ai uses different types of cookies for various purposes. The categories of cookies we employ include:',
      list: [
        {
          title: 'Essential Cookies',
          content: 'These cookies are necessary for the website to function properly. They enable core features like user authentication, session management, and navigation. Without these cookies, the platform may not work as intended, and certain services may be unavailable.',
        },
        {
          title: 'Performance and Analytics Cookies',
          content: 'These cookies collect information about how users interact with our website. We use this data to improve the performance and usability of Remixly.ai. For example, they help us identify the most frequently visited pages and detect potential issues with the platform.',
        },
        {
          title: 'Functional Cookies',
          content: 'Functional cookies remember your preferences and custom settings, such as your language selection or login status. They enhance your browsing experience by providing personalized features and options.',
        },
        {
          title: 'Targeting and Advertising Cookies',
          content: 'These cookies track your browsing behavior and activity across different websites. They allow us and third-party partners to deliver relevant ads and measure the effectiveness of our advertising campaigns. These cookies may also limit the number of times you see a particular ad.',
        }
      ]
    },
    {
      title: 'How We Use Cookies',
      content: (
        <div>
          <span>We use cookies to enhance and personalize your experience on Remixly.ai. The main purposes for which we use cookies include:</span>
          <ul>
            <li>Authentication: To recognize you when you log in and maintain your session across different pages of the platform.</li>
            <li>Analytics: To track how visitors use the platform and which features are most popular, helping us improve functionality and user experience.</li>
            <li>Preferences: To store your settings and preferences (e.g., language, audio preferences), so you don’t have to reconfigure them each time you visit the website.</li>
            <li>Marketing: To deliver targeted content and advertising based on your interests and browsing habits.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Third-Party Cookies',
      content: 'In addition to our own cookies, we may allow third-party service providers to place cookies on your device when you use Remixly.ai. These third-party cookies are primarily used for analytics and advertising purposes.',
      list: [
        {
          title: 'Analytics Cookies',
          content: 'We use third-party analytics services, such as Google Analytics, to track user interactions and gather insights into how our platform is being used. These cookies help us improve our services by providing data on website traffic, user behavior, and other key metrics.',
        },
        {
          title: 'Advertising Cookies',
          content: 'We may partner with third-party advertisers who use cookies to track your online behavior and display relevant ads based on your interests. These cookies allow advertisers to deliver personalized content and measure the effectiveness of their campaigns.',
        }
      ]
    },
    {
      title: 'How to Manage Cookies',
      content: (
        <div>
          <span>You can control and manage cookies through your browser settings. Most browsers allow you to block or delete cookies, as well as notify you before cookies are placed on your device.</span>
          <span>Here’s how you can manage cookies in different browsers:</span><br />
          <ul>
            <li>Google Chrome: Manage Cookies in Chrome</li>
            <li>Mozilla Firefox: Manage Cookies in Firefox</li>
            <li>Safari: Manage Cookies in Safari</li>
            <li>Microsoft Edge: Manage Cookies in Edge</li>
          </ul>
          <span>Please note that disabling cookies may affect the functionality of Remixly.ai and limit your ability to use certain features.</span>
        </div>
      ) as JSX.Element
    },
    {
      title: 'Cookie Duration',
      content: 'The duration that a cookie remains on your device depends on its type. Some cookies, known as "session cookies," are temporary and only last until you close your browser. Others, called "persistent cookies," remain on your device until they expire or you manually delete them. Persistent cookies are used to remember your login information or preferences across sessions.'
    },
    {
      title: 'Updates to this Cookies Policy',
      content: 'We may update this Cookies Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page with the latest revision date. We encourage you to review this policy periodically to stay informed about how we use cookies.'
    },
    {
      title: 'Contact Us',
      content: (
        <div>
          <span>If you have any questions about our use of cookies or how we protect your privacy, please contact us at:</span>
          <span>Email: <a>support@remixly.ai</a></span>
        </div>
      )
    }
  ])

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div className={styles.container}>
      <NavigationRail onExpandChange={setIsNavExpanded} />
    
      <motion.div 
        className={styles.content}
        animate={{
          marginLeft: isNavExpanded ? '15%' : '0',
        }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >

    <div className="privacy-wrapper">
      <div className="title">Cookies</div>
      <div className="last-updated">Last Updated: 9/10/2024</div>
      <div className="msg">At Remixly.ai, we use cookies and similar tracking technologies to provide a better user experience, enhance our services, and offer personalized content. This Cookies Policy explains what cookies are, how we use them, and your options regarding their management.</div>
      <div className="contents">
        {
          contents.map((e, i) => {
            return (
              <div className="part">
                <h3>{ i + 1 }.{ e.title }</h3>
                <span className="part-desc">{ e.content }</span>
                {
                    e.list && e.list.length > 0 
                  ?
                    <ul className="part-list">
                      {
                        e.list.map((item, index) => {
                          return (
                            <li className="part-item">
                              <span className="no">{ i + 1 }.{ index + 1 } { item.title }</span>
                              <span className="msg">{ item.content }</span>
                            </li>
                          )
                        })
                      }
                    </ul>
                  : 
                    <></>
                }
              </div>
            )
          })
        }
      </div>
      <Footer />
    </div>
    </motion.div>
    </div>
  )
}

export default Cookies