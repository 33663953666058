import { checkout, CheckoutReq } from '@/api/login'
import { register, RegisterReq, verificationMail } from '@/api/register'
import { makeAutoObservable } from 'mobx'

class LoginStore {
  constructor() {
    makeAutoObservable(this)
  }
  async vertification(email: string) {
    const res = await verificationMail(email)
    console.log('***', res)
  }

  async register(data: RegisterReq) {
    const res = await register(data)
  }

  async login(data: CheckoutReq) {
    const res = await checkout(data)
  }
}

const app = new LoginStore()
export default app