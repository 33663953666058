import Footer from "@/components/footer/Footer"
import { observer } from "mobx-react-lite"
import './templates.scss'
import { useState } from "react"
import templateImg from '@/assets/images/Template.svg'
import conversionImg from '@/assets/images/converion-template.png'
import arrowRightBottomImg from '@/assets/images/arrow-right-bottom.svg'
import { useNavigate } from "react-router-dom"
import axios, { AxiosError } from 'axios';
import { useStore } from '@/store';
import React, { useEffect } from 'react';

const Templates = () => {
  const { appStore } = useStore();
  const navigate = useNavigate()
  const [list, setList] = useState([
    {
      name: 'conversion',
      desc: 'Change a song\'s singer to our ai singer'
    },
    // {
    //   name: 'name',
    //   desc: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
    // },
    // {
    //   name: 'name',
    //   desc: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
    // },
    // {
    //   name: 'name',
    //   desc: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
    // }
  ])

  useEffect(() => {
    // 检查用户是否登录
    if (!appStore.isLoggedIn) {
      // 如果未登录，设置模态框可见
      appStore.signUpModalVisible = true;
    }
  }, [appStore.isLoggedIn]); // 依赖数组，当isLoggedIn变化时重新运行

  return (
    <div className="templates-wrapper">
      <div className="content">
        <div className="title">Remixly Workflows Templates</div>
        <ul className="template-list">
          {
            list.map(e => {
              return (
                <li className="template-item">
                  <div className="left"><img src={ conversionImg } width={'100%'}/></div>
                  <div className="right">
                    <div className="top">
                      <div className="logo"><img src={ templateImg } /></div>
                      <span className="name">{ e.name }</span>
                    </div>
                    <div className="desc">{ e.desc }</div>
                    <div className="footer">
                      <div className="btn-run" onClick={()=>{navigate(`/workflow?template=${e.name}`)}}>Run</div>
                    </div>
                    <div className="arrow"><img src={ arrowRightBottomImg }  onClick={()=>{navigate(`/workflow?template=${e.name}`)}}/></div>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
      <Footer />
    </div>
  )
}

export default observer(Templates)