import { useStore } from "@/store"
import { Avatar, Button, Checkbox, Form, Input, Modal } from "antd"
import './login-modal.scss'
import { observer } from "mobx-react-lite"
import cookieImg from '@/assets/images/cookie.svg'
import { useNavigate } from "react-router-dom"
import { CredentialResponse, GoogleLogin } from "@react-oauth/google"
import { googleLogin } from "@/api/login"
import { useState } from "react"

const LoginModal = () => {
  const { appStore, loginStore } = useStore()
  const [form] = Form.useForm<{ mail: string; password: string }>();
  const navigate = useNavigate();
  const [showCookies, setShowCookies] = useState(true)


  const handleLogin = async () => {
    try {
      const values = await form.validateFields();
      loginStore.login({
        email: form.getFieldValue('mail'),
        password: form.getFieldValue('password')
      }).then(() => {
        // navigate('/')
        appStore.loginModalVisible = false
        appStore.isLoggedIn = true
        
        window.location.reload()
      })
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }

  const handleGoogleLogin = (credentialResponse: CredentialResponse) => {
    if (credentialResponse.credential) {
      googleLogin(credentialResponse.credential).then(res => {
        console.log(res)
        if (res.data.access_token) {
          // 存储 Token
          localStorage.setItem('access_token', res.data.access_token);
          // 如果后端返回了 refresh_token，可以存储它以备将来使用
          if (res.data.refresh_token) {
            localStorage.setItem('refresh_token', res.data.refresh_token);
          }
          console.log('Google Login Successful');
          appStore.loginModalVisible = false
          appStore.isLoggedIn = true
          // navigate('/')
          // window.location.reload()
        } else {
          console.log('Google Login Failed');
        }
      }).catch(res => {
      })

    }
  }

  return (
    <Modal open={appStore.loginModalVisible} footer={null} onCancel={() => appStore.loginModalVisible = false} wrapClassName="login">
      <div className="login-wrapper">
        <div className="left">
          <span className="net">www.remixly.ai</span>
        </div>
        <div className="right">
          <div className="title"><span className="underline">Log in</span> to <span className="remix">Remixly</span></div>
          <span className="login-with">log in with:</span>
          <div className="avatar">
          <GoogleLogin
            onSuccess={credentialResponse => {
              handleGoogleLogin(credentialResponse)
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
          </div>
          <span className="or">or</span>
          <Form
            layout="vertical"
            form={form}
          >
            <Form.Item label="Email address" name="mail" rules={[{ required: true, message: 'Please input your mail!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
              <Input.Password />
            </Form.Item>
            <Checkbox className="keep-me-logged-in">Keep me logged in</Checkbox>
            <Form.Item>
              <Button type="primary" onClick={handleLogin}>Log in</Button>
            </Form.Item>
          </Form>
          <div className="footer">
            <span>Don’t have a Remixly ID?</span>
            <Button type="link" onClick={()=>{appStore.loginModalVisible=false; appStore.signUpModalVisible=true}}>Create account</Button><br />
            {/* <Button type="link">Forgot your password?</Button> */}
          </div>
        </div>
      </div>
      <div className="cookies-consent">
        <div className="left">
          <img src={cookieImg} />
        </div>
        <div className="content" style={{display: showCookies?'':'none'}}>
          <span className="title">Cookie Consent</span><br />
          <span className="desc">This website uses cookies to enhance the user experience and analyze site traffic. By clicking "Accept" you consent to our use of </span>
          <a href="/cookies" target="_blank">cookies.</a>
        </div>
            <div className="right">
              <Button className="btn-accept" onClick={()=>{setShowCookies(false)}}>Accept</Button>
            </div>
      </div>
    </Modal>
  )
}

export default observer(LoginModal)