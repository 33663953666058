import { getAxios, postAxios, putAxios, deleteAxios } from '@/utils/axios'

export const submitTask = (params, task, token) => {
  const submitRoute = '/infer/submit-task'
  // const token = getCookie('next-auth.session-token')
  // console.log('token')
  // console.log(token)
  console.log('process.env.NODE_ENV')
  console.log(process.env.NODE_ENV)
  const result = postAxios({ url: submitRoute, data: { task: task, data: params }, headers: { Authorization: token } }).then((res) => {
    console.log(res)
    return 'success'
  }).catch((err) => {
    console.log('error')
    console.log(err)
    return Promise.reject(err)
  }).finally((res) => {
    console.log('finally')
    console.log(res)
  })
  return result
}

export const getUserCreations = (token) => {
  const userCreationRoute = '/user-data/creations'
  const result = getAxios({ url: userCreationRoute, headers: { Authorization: token } }).then((res) => {
    console.log(res)
    return res.data
  }).catch((err) => {
    console.log('error')
    console.log(err)
    return Promise.reject(err)
  }).finally((res) => {
    console.log('finally')
    console.log(res)
  })
  return result
}

export const getUserCollections = (token) => {
  const userCollectionRoute = '/user-data/collections'
  const result = getAxios({ url: userCollectionRoute, headers: { Authorization: token } }).then((res) => {
    console.log(res)
    return res.data
  }).catch((err) => {
    console.log('error')
    console.log(err)
    return Promise.reject(err)
  }).finally((res) => {
    console.log('finally')
    console.log(res)
  })
  return result
}

export const getUserWorkflows = () => {
  const userWorkflowRoute = '/user-data/workflows'
  const result = getAxios({ url: userWorkflowRoute }).then((res) => {
    console.log(res)
    return res.workflows_ids
  }).catch((err) => {
    console.log('error')
    console.log(err)
    return Promise.reject(err)
  }).finally((res) => {
    console.log('finally')
    console.log(res)
  })
  return result
}

export const getComfyUserId = () => {
  const userWorkflowRoute = '/user-data/comfy-id'
  const result = getAxios({ url: userWorkflowRoute }).then((res) => {
    console.log(res)
    return res.id
  }).catch((err) => {
    console.log('error')
    console.log(err)
    return Promise.reject(err)
  }).finally((res) => {
    console.log('finally')
    console.log(res)
  })
  return result
}

export const createWorkflow = (flowData, flowName, token) => {
  const createWorkflowRoute = '/flow/create'
  console.log('flowData', flowData)
  console.log('flowName', flowName)
  console.log('token', token)
  const result = postAxios({ url: createWorkflowRoute, data: { flow: flowData, flow_name: flowName }, headers: { Authorization: token } }).then((res) => {
    // console.log('res', res)
    return res.flow_id
  }).catch((err) => {
    console.log('error')
    console.log(err)
    return Promise.reject(err)
  }).finally((res) => {
  })
  return result
}

export const getWorkflow = (flowId)=>{
  const getWorkflowRoute = `/flow/${flowId}`
  const result = getAxios({ url: getWorkflowRoute }).then((res)=>{
    console.log(res)
    return res
  }).catch((err) => {
    console.log('error')
    console.log(err)
    return Promise.reject(err)
  }).finally((res) => {
  })
  return result
}

export const updateWorkflow = (flowId, flowData, flowName='')=>{
  const updateWorkflowRoute = `/flow/${flowId}`
  const result = putAxios({ url: updateWorkflowRoute, data: { flow: flowData, flow_name:flowName } }).then((res)=>{
    console.log(res)
    return res.message
  }).catch((err) => {
    console.log('error')
    console.log(err)
    return Promise.reject(err)
  })
  return result
}

export const deleteWorkflow = (flowId)=>{
  const getWorkflowRoute = `/flow/${flowId}`
  const result = deleteAxios({ url: getWorkflowRoute }).then((res)=>{
    console.log(res)
    return res
  }).catch((err) => {
    console.log('error')
    console.log(err)
    return Promise.reject(err)
  }).finally((res) => {
  })
  return result
}

export const runWorkflow = (setProcessing, appStore, flow, stopAt='', flowId='', onMessage=()=>{}, onError=()=>{}) => {
  const runWorkflowRoute = "/flow/submit-flow"
  const connectionId = postAxios({ url: runWorkflowRoute, data: { flow, stopAt } }).then((res) => {
    console.log('connectionId post')
    console.log('res', res)
    console.log('res.connection_id', res.connection_id)

    // sse
    const eventSource = new EventSource(`/back/flow/events/${res.connection_id}`);
    eventSource.onmessage = (e)=>{
      if (e.data === 'complete') {
        console.log(e.data)
        onMessage(e.data)
        eventSource.close()
        return
      }
      if (e.data === 'started') {
        console.log('start sse')
        return
      }
      onMessage(JSON.parse(e.data))
    }
    eventSource.onerror = onError

    return res.connection_id
  }).catch((err) => {
    console.log('connectionId post error')
    console.log('error')
    console.log(err)
    if (err.status === 422)
    {
      appStore.signUpModalVisible = true
    }
    setProcessing(false)
    // return Promise.reject(err)
    return err
  }).finally((res) => {})

  
}