import * as React from "react";
import { Button, GetProps, Input } from 'antd';
import './index.scss'
import { useState } from 'react';
import { ArrowUpOutlined, AudioOutlined } from '@ant-design/icons';
import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';
import { useStore } from "@/store";
import { observer } from "mobx-react-lite";
import app1Img from '@/assets/images/app-1.webp'
import app2Img from '@/assets/images/app-2.webp'
import app3Img from '@/assets/images/app-3.webp'
import app4Img from '@/assets/images/app-4.webp'
import app5Img from '@/assets/images/app-5.webp'
import app6Img from '@/assets/images/app-6.webp'
import app7Img from '@/assets/images/app-7.webp'
import app8Img from '@/assets/images/app-8.webp'
import app9Img from '@/assets/images/app-9.webp'
import app10Img from '@/assets/images/app-10.webp'
import app11Img from '@/assets/images/app-11.webp'
import app12Img from '@/assets/images/app-12.webp'
import converImg from '@/assets/images/cover.jpg'
import deechoImg from '@/assets/images/deecho.jpg'
import dereverbImg from '@/assets/images/dereverb.jpg'
import separateImg from '@/assets/images/separate.jpg'
import manyToolsImg from '@/assets/images/home-tools.svg'
import { useNavigate } from "react-router-dom";
import NavigationRail from "../../components/sidebar/Sidebar";
import { Layout } from "antd";
import { motion } from 'framer-motion';
import styles from './index.module.scss';

type SearchProps = GetProps<typeof Input.Search>;

const { Search } = Input;

const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

const Home = () => {
  const { appStore } = useStore()
  const navigate = useNavigate()

  const [list, setList] = useState([
    app1Img,
    app2Img,
    app3Img,
    app4Img,
    app5Img,
    app6Img,
    app7Img,
    app8Img,
    app9Img,
    app10Img,
    app11Img,
    app12Img
  ])

  const toolList = [
    'Song Cover',
    'Deecho',
    'Dereverb',
    'Speed Change',
    'Pitch Shift',
    'Separator',
  ]
  interface routerType {
    [key: string]: string
  }
  const toolRouters:routerType = {
    'Song Cover': '/toolkit/song-cover',
    'Deecho': '/toolkit/deecho',
    'Dereverb': '/toolkit/dereverb',
    'Speed Change': '/toolkit/speedChange',
    'Pitch Shift': '/toolkit/pitchShift',
    'Separator': '/toolkit/vocalRemoval',
  }

  const toolImage:routerType = {
    'Song Cover': converImg,
    'Deecho': deechoImg,
    'Dereverb': dereverbImg,
    'Speed Change': app5Img,
    'Pitch Shift': app11Img,
    'Separator': separateImg,
  }

  const Banner = () => {
    return (
      <div className="banner">
        <div className="statistics">
          <div className="icon">
            <img src={manyToolsImg} alt="" />
          </div>
          <div className="content">
            <div className="value">MANY</div>
            <div className="name">TOOLS</div>
          </div>
        </div>
        <div className="desc">
          <span className="desc-1">Why buy one</span>
          <span className="desc-2">AI music tool </span>
        </div>
        <div className="sub-desc">
          <span className="sub-desc-1">when you can do it yourself</span>
          <span className="sub-desc-2">?</span>
        </div>
        <div className="title">
          <span className="remix">Remixly</span>
          <span>Online</span>
          <span className="toolkit">Toolkit.</span>
        </div>
      </div>
    )
  }

  const AppList = () => {
    return (
      <div className="app-list">
        {
          toolList.map(e => {
            return (
              <div className="app-list-item" onClick={() => navigate(toolRouters[e])}>
                <div className="top">
                  <span className="title">{e}</span>
                  <div className="link">
                    <ArrowUpOutlined />
                  </div>
                </div>
                <div className="poster-wrapper">
                  <div className="poster" style={{backgroundImage: `url(${toolImage[e]})`}}></div>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div className={styles.container}>
      <NavigationRail onExpandChange={setIsNavExpanded} />
    
      <motion.div 
        className={styles.content}
        animate={{
          marginLeft: isNavExpanded ? '15%' : '0',
        }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >

    <Layout className="home-wrapper">
      <Layout className="view-layout" style={{ minHeight: '100vh', background: '#fff' }}>
          <div className="layout-wrapper">
            <Header showBreadCrumb={false} />
            <div className="home">
              <Banner />
              <div className="content">
                {/* <div className="search-wrapper">
                  <Search onSearch={onSearch} />
                </div> */}
                <AppList />
              </div>
            </div>
            <Footer />
          </div>
      </Layout>
    </Layout>

    </motion.div>
    </div>
  )
}

export default observer(Home)